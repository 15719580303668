import styled, { css } from "styled-components"

export const Container = styled.div`
  background-color: #fffcfb;
  border-radius: 8px;
  margin-top: 1.4rem;
`

export const EllipsisText = styled.div`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  line-height: 1.5;
  max-height: 3em;
  width: 200px; /* Adjust width as needed */
  word-wrap: break-word;
`

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: hidden;

  th,
  td {
    padding: 0.3rem 0.8rem;
    text-align: center;
    font-size: 14px;
  }

  td {
    padding: 1rem 0.7rem;
    font-size: 13px;
    border: none;
  }

  th:first-child,
  td:first-child {
    text-align: left;
  }

  th:last-child,
  td:last-child {
    text-align: right;
  }

  th {
    background-color: #dc2626;
    color: #fff;
    font-weight: normal;
    cursor: pointer;
    position: relative;
    padding: 1rem 0.8rem;
    font-weight: bold;
    font-size: 14px;
  }

  th:after {
    content: "";
    position: absolute;
    right: 10px;
    border: 6px solid transparent;
    border-top-color: #fff;
    display: ${(props) => (props.direction ? "inline-block" : "none")};
    transform: ${(props) =>
      props.direction === "asc" ? "rotate(180deg)" : "none"};
  }

  tbody tr:nth-child(odd) {
    background-color: #fff;
  }

  tbody tr:nth-child(even) {
    background-color: #fff6f5;
  }

  td {
    border-bottom: none;
  }

  td:last-child {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  @media (max-width: 768px) {
    th,
    td {
      padding: 0.5rem 1rem;
    }
  }

  @media (max-width: 480px) {
    th,
    td {
      padding: 0.25rem 0.5rem;
    }
    th,
    td {
      display: block;
      text-align: right;
    }
    th {
      text-align: left;
    }
    td:last-child {
      justify-content: center;
    }
  }
`

export const CardRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 16px 0;
`

export const CardRowFilter = styled(CardRow)`
  display: flex;
  justify-content: space-between;
  align-items: center; /* Align items vertically centered */
  margin: 0;
`

export const Card = styled.div`
  flex: 1;
  margin: 0px 10px;
  padding: 20px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1), 0 6px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
  transition: all 0.3s ease-in-out;
  // background-color: #dc2626;
  font-weight: bold;
  color: #000;
  font-size: 16px;
  &:hover {
    transform: translateY(-10px);
    box-shadow: 0 6px 30px rgba(0, 0, 0, 0.2), 0 10px 10px rgba(0, 0, 0, 0.2);
  }

  h2 {
    font-size: 1.5rem;
    color: #000;
  }

  p {
    font-size: 1rem;
    color: #000;
    line-height: 1.5;
    font-weight: 400;
  }

  button {
    padding: 10px 20px;
    font-size: 1rem;
    color: #fff;
    background-color: #007bff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;

    &:hover {
      background-color: #0056b3;
    }
  }
`

export const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 20px 0;
`

export const PaginationButton = styled.button`
  background-color: #bbb;
  color: #fff;
  border: none;
  padding: 8px 16px;
  margin: 0 5px;
  cursor: pointer;
  border-radius: 5px;

  &:hover {
    background-color: #bb1f1f;
  }

  &:disabled {
    background-color: #f0f0f0;
    color: #ccc;
    cursor: not-allowed;
  }

  ${(props) =>
    props.active &&
    css`
      background-color: #dc2626;
      font-weight: bold;
    `}
`

export const ActiveStatusText = styled.button`
  padding: 0.25em 0.5em;
  border-radius: 4px;
  background-color: ${(props) => (props.isActive ? "#d4edda" : "#f8d7da")};
  color: ${(props) => (props.isActive ? "#155724" : "#721c24")};
  font-weight: bold;
`

export const FilterButton = styled.button`
  background-color: #dc2626;
  color: #fff;
  border: none;
  padding: 10px 20px;
  margin: 0 5px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease-in-out;

  &:hover {
    background-color: #bb1f1f;
  }

  ${(props) =>
    props.active &&
    css`
      background-color: #900c3f;
      font-weight: bold;
    `}
`

export const SearchBar = styled.input`
  width: 60%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 16px;
  box-sizing: border-box;

  &:focus {
    outline: none;
    border-color: #dc2626;
  }

  @media (max-width: 768px) {
    width: 100%; /* Full width on smaller screens */
  }
`
