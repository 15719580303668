import React from "react";
import Modal from "react-modal";
import { FaTimes } from "react-icons/fa";

const DeleteAdModal = ({
  isOpen,
  onClose,
  ad,
  onDelete,
}) => {
  const handleDelete = () => {
    onDelete();
    onClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      style={ModalStyle}
      contentLabel="Delete Ad"
    >
      <div className="flex justify-between items-center">
        <h2 className="m-0 text-[20px] text-[#dc2626]">Delete Ad</h2>
        <button
          className="bg-none border-none text-[24px] cursor-pointer text-[#dc2626] transition-colors duration-300 hover:text-[#f33823]"
          onClick={onClose}
        >
          <FaTimes />
        </button>
      </div>
      <p>Are you sure you want to delete this ad?</p>
      <div className="flex justify-end gap-[10px] mt-5">
        <button
          className="py-2 px-4 bg-[#dc2626] text-white rounded-[6px] transition-colors duration-300 hover:bg-[#dc262688]"
          onClick={handleDelete}
        >
          Delete
        </button>
        <button
          className="py-2 px-4 bg-[#ddd] text-[#333] rounded-[6px] transition-colors duration-300 hover:bg-[#ccc]"
          onClick={onClose}
        >
          Cancel
        </button>
      </div>
    </Modal>
  );
};

export default DeleteAdModal;

const ModalStyle = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "30%",
    padding: "2rem",
    borderRadius: "12px",
    boxShadow: "0 0 20px rgba(0, 0, 0, 0.2)",
    backgroundColor: "#fff",
  },
};

Modal.setAppElement("#root");
