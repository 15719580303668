import axios from "axios"
import React, { useState } from "react"
import { toast } from "react-hot-toast"
import { FaTimes } from "react-icons/fa"
import Modal from "react-modal"
import styled from "styled-components"

const BASE_URL = "https://kalerpotro.pythonanywhere.com"

const SignupModal = ({ show, onClose, setUsers, users }) => {
  const [username, setUsername] = useState("")
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")
  const [role, setRole] = useState("EDITOR")
  const [profilePicture, setProfilePicture] = useState(null)
  const [passwordError, setPasswordError] = useState("")

  const validatePassword = (password) => {
    const minLength = 6
    const hasNumber = /\d/
    const hasUpperCase = /[A-Z]/
    const hasLowerCase = /[a-z]/
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/

    if (password.length < minLength) {
      return "Password must be at least 6 characters long."
    }
    if (!hasNumber.test(password)) {
      return "Password must contain at least one number."
    }
    if (!hasUpperCase.test(password)) {
      return "Password must contain at least one uppercase letter."
    }
    if (!hasLowerCase.test(password)) {
      return "Password must contain at least one lowercase letter."
    }
    if (!hasSpecialChar.test(password)) {
      return "Password must contain at least one special character."
    }
    return null
  }

  const handleAddEditor = async (event) => {
    event.preventDefault()
    const passwordError = validatePassword(password)
    if (passwordError) {
      toast.error(passwordError)
      return
    }
    if (password !== confirmPassword) {
      toast.error("Passwords do not match")
      return
    }
  
    const token = localStorage.getItem("accessToken")
    const formData = new FormData()
    formData.append("username", username)
    formData.append("first_name", firstName)
    formData.append("last_name", lastName)
    formData.append("email", email)
    formData.append("password", password)
    formData.append("confirm_password", confirmPassword)
    formData.append("role", role)
    if (profilePicture) {
      formData.append("profile_picture", profilePicture)
    }
    try {
      const response = await axios.post(
        `${BASE_URL}/api/auth_user/register/`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      )
  
      if (response.data?.email) {
        toast.error(response.data?.email[0])
      } else if (response.data) {
        toast.success("User added successfully. Check Email For Activation Link")
        onClose()
        setUsername("")
        setFirstName("")
        setLastName("")
        setEmail("")
        setPassword("")
        setConfirmPassword("")
        setRole("EDITOR")
        setProfilePicture(null)
  
        try {
          const usersResponse = await axios.get(
            `${BASE_URL}/api/auth_user/admin/alluser/`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          setUsers(usersResponse.data)
        } catch (error) {
          toast.error("Failed to fetch updated user list")
        }
      }
    } catch (error) {
      toast.error("Failed to add editor")
    }
  }
  
  

  const handlePasswordChange = (e) => {
    const value = e.target.value
    setPassword(value)
    setPasswordError(validatePassword(value))
  }

  return (
    <Modal
      isOpen={show}
      onRequestClose={onClose}
      style={ModalStyle}
      contentLabel="Signup Modal"
    >
      <ModalHeader>
        <Title>Add User</Title>
        <CloseButton onClick={onClose}>
          <FaTimes />
        </CloseButton>
      </ModalHeader>
      <form onSubmit={handleAddEditor}>
        <FormRow>
          <Label>
            Username
            <Input
              type="text"
              placeholder="Username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
            />
          </Label>
          <Label>
            First Name
            <Input
              type="text"
              placeholder="First Name"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              required
            />
          </Label>
        </FormRow>
        <FormRow>
          <Label>
            Last Name
            <Input
              type="text"
              placeholder="Last Name"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              required
            />
          </Label>
          <Label>
            Email
            <Input
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </Label>
        </FormRow>
        <FormRow>
          <Label>
            Role
            <Select
              value={role}
              onChange={(e) => setRole(e.target.value)}
              required
            >
              <option value="ADMIN">Admin</option>
              <option value="EDITOR">Editor</option>
            </Select>
          </Label>
          <Label>
            Profile Picture
            <Input
              type="file"
              onChange={(e) => setProfilePicture(e.target.files[0])}
            />
          </Label>
        </FormRow>
        <FormRow>
          <Label>
            Password
            <Input
              type="password"
              placeholder="Password"
              value={password}
              onChange={handlePasswordChange}
              required
            />
            {passwordError && <Error>{passwordError}</Error>}
          </Label>
          <Label>
            Confirm Password
            <Input
              type="password"
              placeholder="Confirm Password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
            />
          </Label>
        </FormRow>
        <SaveButton type="submit">Add User</SaveButton>
      </form>
    </Modal>
  )
}

export default SignupModal

const ModalStyle = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "60%",
    padding: "2.5rem 3rem",
    borderRadius: "12px",
    boxShadow: "0 0 20px rgba(0, 0, 0, 0.2)",
    backgroundColor: "#fff",
  },
}

Modal.setAppElement("#root")

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const Title = styled.h2`
  margin: 0;
  font-size: 20px;
  color: #dc2626;
`

const CloseButton = styled.button`
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #dc2626;
  transition: color 0.3s ease;

  &:hover {
    color: #d00000;
  }
`

const FormRow = styled.div`
  display: flex;
  gap: 15px;
  margin-bottom: 1.25rem;
`

const Label = styled.label`
  flex: 1;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  color: #333;
`

const Input = styled.input`
  margin-top: 5px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 6px;
  font-size: 16px;
  transition: border-color 0.3s;
  outline: none;

  &:focus {
    border-color: #f33823;
  }
`

const Select = styled.select`
  margin-top: 5px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 6px;
  font-size: 16px;
  transition: border-color 0.3s;
  outline: none;

  &:focus {
    border-color: #f33823;
  }
`

const Error = styled.p`
  color: red;
  font-size: 12px;
`

const SaveButton = styled.button`
  display: block;
  width: 100%;
  padding: 12px;
  background-color: #dc2626;
  color: #fff;
  border: none;
  border-radius: 6px;
  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #dc262685;
  }
`
