import axios from "axios"
import React, { useState } from "react"
import { toast } from "react-hot-toast"
import { FaEye, FaEyeSlash } from "react-icons/fa"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import styled from "styled-components"
import { setUser } from "../../store/authSlice"
import { setUserDetails } from "../../store/userSlice"
import getUserDetailsFromToken from "./Utils/getUserDetailsFromToken"

const Login = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [backendMessage, setBackendMessage] = useState("")
  const [showPassword, setShowPassword] = useState(false)

  const handleEmailLogin = async (event) => {
    event.preventDefault()

    try {
      const response = await axios.post(
        "https://kalerpotro.pythonanywhere.com/api/auth_user/login/",
        {
          email,
          password,
        }
      )

      if (response.data) {
        const { token, msg } = response.data

        localStorage.setItem("refreshToken", token.refresh)
        localStorage.setItem("accessToken", token.access)

        const userDetails = getUserDetailsFromToken(token.access)

        dispatch(setUser(userDetails))

        localStorage.setItem("role", userDetails.role)

        const userResponse = await axios.get(
          "https://kalerpotro.pythonanywhere.com/api/auth_user/me/",
          {
            headers: {
              Authorization: `Bearer ${token.access}`,
            },
          }
        )

        const userData = userResponse.data
        const {
          id,
          username,
          first_name,
          last_name,
          email,
          profile_picture,
          phone,
          address,
          bio,
        } = userData
        dispatch(
          setUserDetails({
            id,
            username,
            first_name,
            last_name,
            email,
            profile_picture,
            phone,
            address,
            bio,
          })
        )

        toast.success(msg)
        setBackendMessage(msg)
        navigate("/overview")
      }
    } catch (error) {
      const errorMsg = error.response?.data?.msg || "Login failed"
      toast.error(errorMsg)
      setBackendMessage(errorMsg)
    }
  }

  return (
    <Wrapper>
      <LoginContainer>
        <Header>
          <Title>Login</Title>
        </Header>
        <Form onSubmit={handleEmailLogin}>
          <Input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <PasswordContainer>
            <Input
              type={showPassword ? "text" : "password"}
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <ShowPasswordButton
              type="button"
              onClick={() => setShowPassword(!showPassword)}
            >
              {showPassword ? <FaEye /> : <FaEyeSlash />}
            </ShowPasswordButton>
          </PasswordContainer>

          {backendMessage && <BackendMessage>{backendMessage}</BackendMessage>}
          <Button type="submit">Login</Button>
          <ForgotPasswordLink
            style={{
              color: "#dc2626",
              textDecoration: "none",
              marginTop: "25px",
              fontWeight: "bold",
            }}
            onClick={() => navigate("/reset")}
          >
            Forgot Password?
          </ForgotPasswordLink>
        </Form>
      </LoginContainer>
    </Wrapper>
  )
}

export default Login

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background-color: #f0f0f0; /* Optional: Background color to make the login form stand out */
`

const LoginContainer = styled.div`
  border-radius: 12px;
  width: 100%;
  max-width: 400px; /* Max width of the login container */
  padding: 20px;
  background: #fff; /* Optional: White background for the login container */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Optional: Shadow for a more prominent appearance */
  font-family: "Roboto", sans-serif;
`

const Header = styled.div`
  text-align: center;
  margin-bottom: 20px;
`

const Title = styled.h1`
  color: #333;
  font-size: 28px;
  margin: 0;
`

const Form = styled.form`
  display: flex;
  flex-direction: column;
`

const Input = styled.input`
  width: 100%;
  padding: 12px 20px;
  margin-bottom: 20px;
  border: 1px solid #ddd;
  border-radius: 6px;
  font-size: 16px;
  transition: all 0.3s;

  &:focus {
    border-color: #007bff;
    box-shadow: 0 0 8px rgba(0, 123, 255, 0.25);
    outline: none;
  }
`

const Button = styled.button`
  width: 100%;
  padding: 14px 20px;
  background: #dc2626;
  color: #fff;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 18px;
  transition: background 0.3s;

  &:hover {
    background: #d32f2f78;
  }
`

const BackendMessage = styled.p`
  color: red;
  font-size: 14px;
  margin-bottom: 10px;
  text-align: center;
`

const ForgotPasswordLink = styled.a`
  display: block;
  margin-top: 10px;
  text-align: center;
  color: #007bff;
  cursor: pointer;
  text-decoration: none;
  font-size: 14px;

  &:hover {
    text-decoration: underline;
  }
`

const PasswordContainer = styled.div`
  position: relative;
  width: 100%;
`

const ShowPasswordButton = styled.button`
  position: absolute;
  right: 20px;
  top: 35%;
  transform: translateY(-50%);
  background: none;
  border: none;
  color: #000;
  cursor: pointer;
  font-size: 18px;
`
