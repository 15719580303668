import React, { useEffect, useState } from "react"
import { FaTimes } from "react-icons/fa"
import Modal from "react-modal"

const EditCategoryModal = ({ show, onClose, onSubmit, data, setData }) => {
  const [availableOrders, setAvailableOrders] = useState([])

  useEffect(() => {
    if (show) {
      fetchCategoryData()
    }
  }, [show])

  const fetchCategoryData = async () => {
    try {
      const response = await fetch(
        "https://kalerpotro.pythonanywhere.com/api/news/category/list/",
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      )

      if (response.ok) {
        const categoryData = await response.json()
        const usedOrders = categoryData
          .map((category) => category.order)
          .filter((order) => order > 0)
        const allOrders = Array.from({ length: 14 }, (_, i) => i + 1)
        const available = allOrders.filter(
          (order) => !usedOrders.includes(order)
        )
        setAvailableOrders(available)
      } else {
        console.error("Failed to fetch category data")
      }
    } catch (error) {
      console.error("Error fetching category data:", error)
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    await onSubmit()
    handleClose()
  }

  const handleClose = () => {
    onClose()
  }

  return (
    <>
      <Modal
        isOpen={show}
        onRequestClose={handleClose}
        style={modalStyle}
        contentLabel="Edit Category"
      >
        <div className="flex justify-between items-center">
          <h2 className="m-0 text-[20px] text-[#dc2626]">Edit Category</h2>
          <button
            className="bg-none border-none text-[24px] cursor-pointer text-[#e63946] transition-colors duration-300 hover:text-[#d00000]"
            onClick={handleClose}
          >
            <FaTimes />
          </button>
        </div>
        <form onSubmit={handleSubmit} className="mt-[3.2rem]">
          <div className="flex mb-5 gap-[15px]">
            <label className="flex-1 flex flex-col text-[14px] text-[#333]">
              Category Name
              <input
                type="text"
                placeholder="Category Name"
                value={data.name}
                onChange={(e) => setData({ ...data, name: e.target.value })}
                required
                className="mt-[5px] p-3 border border-[#ddd] rounded-[6px] text-[16px] transition-colors duration-300 focus:border-[#f33823] outline-none"
              />
            </label>
          </div>
          <div className="flex mb-5 gap-[15px]">
            <label className="flex-1 flex flex-col text-[14px] text-[#333]">
              Description
              <input
                type="text"
                placeholder="Description"
                value={data.description}
                onChange={(e) =>
                  setData({ ...data, description: e.target.value })
                }
                className="mt-[5px] p-3 border border-[#ddd] rounded-[6px] text-[16px] transition-colors duration-300 focus:border-[#f33823] outline-none"
              />
            </label>
          </div>
          <div className="flex mb-5 gap-[15px]">
            <label className="flex-1 flex flex-col text-[14px] text-[#333]">
              Order
              <select
                defaultValue={data.order}
                onChange={(e) => setData({ ...data, order: e.target.value })}
                className="mt-[5px] p-3 border border-[#ddd] rounded-[6px] text-[16px] transition-colors duration-300 focus:border-[#f33823] outline-none"
              >
                <option defaultValue={data.order}>{data.order}</option>
                {availableOrders && availableOrders.map((order) => (
                  <option key={order} value={order}>
                    {order}
                  </option>
                ))}
              </select>
            </label>
          </div>
          <button
            type="submit"
            className="py-4 bg-[#dc2626] text-white rounded-[6px] w-full text-center mt-7 text-[18px] cursor-pointer transition-colors duration-300 hover:bg-[#e22e3180]"
          >
            Save
          </button>
        </form>
      </Modal>
    </>
  )
}

export default EditCategoryModal

const modalStyle = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "60%",
    padding: "2.5rem 3rem",
    borderRadius: "12px",
    boxShadow: "0 0 20px rgba(0, 0, 0, 0.2)",
    backgroundColor: "#fff",
  },
}

Modal.setAppElement("#root")
