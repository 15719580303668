import React, { useState, useEffect } from "react"
import Modal from "react-modal"
import { FaTimes } from "react-icons/fa"
import styled from "styled-components"
import { toast } from "react-hot-toast"
import { openUploadWidget } from "../CloudinaryService"

const EditBannerModal = ({
  isEditModalOpen,
  closeEditModal,
  selectedBanner,
  handleEditChange,
  handleSave,
  handleBannerUpdate,
}) => {
  const [availableOrders, setAvailableOrders] = useState([])

  useEffect(() => {
    if (selectedBanner) {
      fetchAvailableOrders(selectedBanner.page)
    }
  }, [selectedBanner])

  const fetchAvailableOrders = async (page) => {
    const response = await fetch(
      "https://kalerpotro.pythonanywhere.com/api/ad/banner/list/"
    )
    const banners = await response.json()

    const usedOrders = banners
      .filter((banner) => banner.page === page)
      .map((banner) => banner.order)

    const maxOrder = page === "HOME" ? 3 : 1
    const availableOrders = [0]
    for (let i = 1; i <= maxOrder; i++) {
      if (!usedOrders.includes(i)) {
        availableOrders.push(i)
      }
    }
    setAvailableOrders(availableOrders)
  }

  const handleSaveWithToast = (e) => {
    e.preventDefault()
    handleSave(e)
      .then((updatedBanner) => {
        toast.success("Banner updated successfully!")
        handleBannerUpdate(updatedBanner)
      })
      .catch(() => toast.error("Failed to update banner."))
  }

  const handleUpload = () => {
    openUploadWidget(
      {
        cloudName: process.env.REACT_APP_CLOUD_NAME,
        uploadPreset: process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET,
        sources: ["local", "url", "camera"],
      },
      (error, result) => {
        if (!error && result.event === "success") {
          handleEditChange({
            target: { name: "image", value: result.info.secure_url },
          })
          toast.success("Image uploaded successfully!")
        } else if (error) {
          toast.error("Image upload failed.")
        }
      }
    )
  }

  return (
    <Modal
      isOpen={isEditModalOpen}
      onRequestClose={closeEditModal}
      style={ModalStyle}
      contentLabel="Edit Banner Details"
    >
      <ModalHeader>
        <Title>Edit Banner Details</Title>
        <CloseButton onClick={closeEditModal}>
          <FaTimes />
        </CloseButton>
      </ModalHeader>
      {selectedBanner && (
        <Form onSubmit={handleSaveWithToast}>
          <FormRow>
            <Label>
              Page
              <Select
                name="page"
                value={selectedBanner.page}
                onChange={handleEditChange}
              >
                <option value="HOME">Home</option>
                <option value="SEARCH_RESULT">Search Result</option>
                <option value="NEWS_POST">Newspost</option>
                <option value="CATEGORY">Category</option>
                <option value="TRENDINGTAGS">Trendingtags</option>
              </Select>
            </Label>

            <Label>
              Link
              <Input
                type="text"
                name="link"
                placeholder="Link"
                value={selectedBanner.link}
                onChange={handleEditChange}
              />
            </Label>
          </FormRow>
          <FormRow>
            <Label>
              Width
              <Input
                type="number"
                name="width"
                placeholder="Width"
                value={selectedBanner.width}
                onChange={handleEditChange}
              />
            </Label>
            <Label>
              Height
              <Input
                type="number"
                name="height"
                placeholder="Height"
                value={selectedBanner.height}
                onChange={handleEditChange}
              />
            </Label>
          </FormRow>
          <FormRow>
            <Label>
              Image URL
              <Input
                type="text"
                name="image"
                placeholder="Image URL"
                value={selectedBanner.image}
                onChange={handleEditChange}
              />
              <ActionButton type="button" onClick={handleUpload}>
                Upload Image
              </ActionButton>
              {selectedBanner.image && (
                <img
                  src={selectedBanner.image}
                  alt="Uploaded"
                  style={{ marginTop: "1rem", maxWidth: "100%" }}
                />
              )}
            </Label>
          </FormRow>
          <FormRow>
            <Label>
              Order
              <Select
                name="order"
                value={selectedBanner.order}
                onChange={handleEditChange}
              >
                <option value="0">Select Order</option>
                {availableOrders.map((orderValue) => (
                  <option key={orderValue} value={orderValue}>
                    {orderValue === 0 ? "Not Assigned" : orderValue}
                  </option>
                ))}
              </Select>
            </Label>
          </FormRow>
          <SaveButton type="submit">Save</SaveButton>
        </Form>
      )}
    </Modal>
  )
}

export default EditBannerModal

const ModalStyle = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "60%",
    height: "80%",
    padding: "2.5rem 3rem",
    borderRadius: "12px",
    boxShadow: "0 0 20px rgba(0, 0, 0, 0.2)",
    backgroundColor: "#fff",
    overflow: "auto",
  },
}

Modal.setAppElement("#root")

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const Title = styled.h2`
  margin: 0;
  font-size: 20px;
  color: #dc2626;
`

const CloseButton = styled.button`
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #dc2626;
  transition: color 0.3s ease;

  &:hover {
    color: #d00000;
  }
`

const Form = styled.form`
  overflow-y: auto;
  max-height: calc(100% - 50px);
`

const FormRow = styled.div`
  display: flex;
  gap: 15px;
  margin-bottom: 1.25rem;
`

const Label = styled.label`
  flex: 1;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  color: #333;
`

const Input = styled.input`
  margin-top: 5px;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 6px;
  font-size: 12px;
  transition: border-color 0.3s;
  outline: none;

  &:focus {
    border-color: #f33823;
  }
`

const Select = styled.select`
  margin-top: 5px;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 6px;
  font-size: 12px;
  transition: border-color 0.3s;
  outline: none;

  &:focus {
    border-color: #f33823;
  }
`

const SaveButton = styled.button`
  display: block;
  width: 100%;
  padding: 12px;
  background-color: #dc2626;
  color: #fff;
  border: none;
  border-radius: 6px;
  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #dc262685;
  }
`

const ActionButton = styled.button`
  padding: 0.5rem 1rem;
  background-color: #dc2626;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 0.5rem;
  &:hover {
    background-color: #dc262696;
  }
`
