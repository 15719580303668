import React from "react"
import { Toaster } from "react-hot-toast"
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom"
import Ads from "./components/Ads/Ads"
import Banners from "./components/Banners/Banners"
import Categories from "./components/Categories/Categories"
import CompanyInfo from "./components/CompanyInfo/CompanyInfo"
import EmailVerify from "./components/EmailVerify/EmailVerify"
import FooterLInks from "./components/FooterLInks/FooterLInks"
import Login from "./components/Login/Login"
import NavbarLinks from "./components/NavbarLinks/NavbarLinks"
import NewsLetter from "./components/NewsLetter/NewsLetter"
import NewsPostUpdated from "./components/NewsPostUpdated/NewsPostUpdated"
import Overview from "./components/Overview/Overview"
import ChangePasswordComponent from "./components/Password/ChangeUserPassword"
import NewPassword from "./components/Password/NewPassword"
import ResetPassword from "./components/Password/ResetPassword"
import RoleManagement from "./components/RoleManagement/RoleManagement"
import Tags from "./components/Tags/Tags"
import VideoGallary from "./components/VideoGallary/VideoGallary"
import Layout from "./Layout"; // Import the new Layout component

const App = () => {
  return (
    <Router>
      <Routes>
        <Route element={<Layout />}>
          <Route path="/" element={<Navigate to="/overview" />} />
          <Route path="/overview" element={<Overview />} />
          <Route path="/role-management" element={<RoleManagement />} />
          <Route path="/categories" element={<Categories />} />
          <Route path="/news-posts" element={<NewsPostUpdated />} />
          <Route path="/banners" element={<Banners />} />
          <Route path="/ads" element={<Ads />} />
          <Route path="/nav" element={<NavbarLinks />} />
          <Route path="/footer" element={<FooterLInks />} />
          <Route path="/company-info" element={<CompanyInfo />} />
          <Route path="/news-letter" element={<NewsLetter />} />
          <Route path="/changepass" element={<ChangePasswordComponent />} />
          <Route path="/reset" element={<ResetPassword />} />
          <Route path="/reset-password" element={<NewPassword />} />
          <Route path="/tags" element={<Tags />} />
          <Route path="/video-gallary" element={<VideoGallary />} />
          <Route path="/email_verify" element={<EmailVerify />} />
          <Route path="/login" element={<Login />} />
        </Route>
      </Routes>
      <Toaster position="top-right" reverseOrder={true} />
    </Router>
  )
}

export default App
