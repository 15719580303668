import React, { useEffect, useState } from "react"
import { AiOutlineDelete, AiOutlineEdit, AiOutlineEye } from "react-icons/ai"
import ReactPaginate from "react-paginate"
import {
  ActionButton,
  ActionContainer,
  Container,
  Header,
  IconWrapper,
  PaginationContainer,
  Table,
  Tooltip,
  TruncatedLink,
} from "./Banners.styled"
import AddBannerModal from "./Modals/AddBannerModal"
import ImageModal from "./Modals/BannerImageHoverModal"
import DeleteBannerModal from "./Modals/DeleteBannerModal"
import EditBannerModal from "./Modals/EditBannerModal"
import ViewBannerModal from "./Modals/ViewBannerModal"

const ITEMS_PER_PAGE = 5

const Banners = () => {
  const [banners, setBanners] = useState([])
  const [currentPage, setCurrentPage] = useState(0)
  const [isViewModalOpen, setIsViewModalOpen] = useState(false)
  const [isEditModalOpen, setIsEditModalOpen] = useState(false)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const [isImageModalOpen, setIsImageModalOpen] = useState(false)
  const [isAddModalOpen, setIsAddModalOpen] = useState(false)
  const [selectedBanner, setSelectedBanner] = useState(null)
  const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 })
  const [isTooltipVisible, setIsTooltipVisible] = useState(false)
  const [tooltipText, setTooltipText] = useState("")
  const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" })

  useEffect(() => {
    const fetchBanners = async () => {
      try {
        const response = await fetch(
          "https://kalerpotro.pythonanywhere.com/api/ad/banner/list/"
        )
        if (!response.ok) {
          throw new Error("Network response was not ok")
        }
        const data = await response.json()
        setBanners(data)
      } catch (error) {
        console.error("Fetch error:", error)
      }
    }

    fetchBanners()
  }, [])

  const handleEdit = (banner) => {
    setSelectedBanner(banner)
    setIsEditModalOpen(true)
  }

  const handleBannerUpdate = (updatedBanner) => {
    setBanners((prev) =>
      prev.map((banner) =>
        banner.id === updatedBanner.id ? updatedBanner : banner
      )
    )
  }

  const handleAddBanner = async (newBanner) => {
    const token = localStorage.getItem("accessToken")
    if (!token) {
      console.error("No auth token found")
      return
    }

    try {
      const response = await fetch(
        "https://kalerpotro.pythonanywhere.com/api/ad/banner/",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(newBanner),
        }
      )
      if (!response.ok) {
        throw new Error("Network response was not ok")
      }
      const addedBanner = await response.json()
      setBanners([...banners, addedBanner])
    } catch (error) {
      console.error("Add banner error:", error)
    }
  }

  const handleViewBanner = (banner) => {
    setSelectedBanner(banner)
    setIsViewModalOpen(true)
  }

  const closeViewModal = () => {
    setIsViewModalOpen(false)
    setSelectedBanner(null)
  }

  const closeEditModal = () => {
    setIsEditModalOpen(false)
    setSelectedBanner(null)
  }

  const closeDeleteModal = () => {
    setIsDeleteModalOpen(false)
    setSelectedBanner(null)
  }

  const closeImageModal = () => {
    setIsImageModalOpen(false)
    setSelectedBanner(null)
  }

  const handleEditChange = (e) => {
    const { name, value } = e.target
    setSelectedBanner((prev) => ({
      ...prev,
      [name]: name === "order" && value === "0" ? 0 : value,
    }))
    console.log("edit func", selectedBanner)
  }

  const handleSave = async (e) => {
    e.preventDefault()

    const token = localStorage.getItem("accessToken")
    if (!token) {
      console.error("No auth token found")
      return
    }

    try {
      console.log(selectedBanner)
      const response = await fetch(
        `https://kalerpotro.pythonanywhere.com/api/ad/banner/${selectedBanner.id}/`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(selectedBanner),
        }
      )
      if (!response.ok) {
        throw new Error("Network response was not ok")
      }
      const updatedBanner = await response.json()

      setBanners((prev) =>
        prev.map((banner) =>
          banner.id === updatedBanner.id ? updatedBanner : banner
        )
      )
      closeEditModal()
      return Promise.resolve(updatedBanner)
    } catch (error) {
      console.error("Update error:", error)
      return Promise.reject()
    }
  }

  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected)
  }

  const handleImageHover = (event, banner) => {
    const { top, left, height } = event.target.getBoundingClientRect()
    setTooltipPosition({
      top: top + height + window.scrollY,
      left: left + window.scrollX,
    })
    setSelectedBanner(banner)
    setIsImageModalOpen(true)
  }

  const handleLinkHover = (event, link) => {
    const { top, left, height } = event.target.getBoundingClientRect()
    setTooltipPosition({
      top: top + height + window.scrollY,
      left: left + window.scrollX,
    })
    setTooltipText(link)
    setIsTooltipVisible(true)
  }

  const handleLinkMouseLeave = () => {
    setIsTooltipVisible(false)
  }

  const handleDeleteBanner = async () => {
    const token = localStorage.getItem("accessToken")
    if (!token) {
      console.error("No auth token found")
      return
    }

    try {
      const response = await fetch(
        `https://kalerpotro.pythonanywhere.com/api/ad/banner/${selectedBanner.id}/`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      if (!response.ok) {
        throw new Error("Network response was not ok")
      }
      setBanners(banners.filter((banner) => banner.id !== selectedBanner.id))
      closeDeleteModal()
    } catch (error) {
      console.error("Delete error:", error)
    }
  }

  const openDeleteModal = (banner) => {
    setSelectedBanner(banner)
    setIsDeleteModalOpen(true)
  }

  const openAddModal = () => {
    setIsAddModalOpen(true)
  }

  const closeAddModal = () => {
    setIsAddModalOpen(false)
  }

  const sortedBanners = React.useMemo(() => {
    let sortableBanners = [...banners]
    if (sortConfig !== null) {
      sortableBanners.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === "asc" ? -1 : 1
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === "asc" ? 1 : -1
        }
        return 0
      })
    }
    return sortableBanners
  }, [banners, sortConfig])

  const requestSort = (key) => {
    let direction = "asc"
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === "asc"
    ) {
      direction = "desc"
    }
    setSortConfig({ key, direction })
  }

  const offset = currentPage * ITEMS_PER_PAGE
  const currentBanners = sortedBanners.slice(offset, offset + ITEMS_PER_PAGE)
  const pageCount = Math.ceil(banners.length / ITEMS_PER_PAGE)

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "2-digit", day: "2-digit" }
    return new Date(dateString).toLocaleDateString(undefined, options)
  }

  return (
    <Container>
      <Header>
        <ActionButton onClick={openAddModal}>Add Banner</ActionButton>
      </Header>
      <Table>
        <thead>
          <tr>
            <th onClick={() => requestSort("page")}>
              Page{" "}
              {sortConfig.key === "page"
                ? sortConfig.direction === "asc"
                  ? "↑"
                  : "↓"
                : ""}
            </th>
            <th onClick={() => requestSort("order")}>
              Order{" "}
              {sortConfig.key === "order"
                ? sortConfig.direction === "asc"
                  ? "↑"
                  : "↓"
                : ""}
            </th>
            <th>Image</th>
            <th onClick={() => requestSort("created_at")}>
              Date{" "}
              {sortConfig.key === "created_at"
                ? sortConfig.direction === "asc"
                  ? "↑"
                  : "↓"
                : ""}
            </th>
            <th>Link</th>
            <th>Width</th>
            <th>Height</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {currentBanners.map((banner) => (
            <tr key={banner.id}>
              <td>{banner.page}</td>
              <td>{banner.order === 0 ? "Not Assigned" : banner.order}</td>

              <td>
                <img
                  src={banner.image}
                  alt={`Banner for ${banner.page}`}
                  onMouseEnter={(e) => handleImageHover(e, banner)}
                  style={{ width: "100px", height: "auto" }}
                />
              </td>
              <td>{formatDate(banner?.created_at)}</td>

              <td>
                <TruncatedLink
                  href={banner.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  onMouseEnter={(e) => handleLinkHover(e, banner.link)}
                  onMouseLeave={handleLinkMouseLeave}
                >
                  {banner.link}
                </TruncatedLink>
              </td>
              <td>{banner.width}</td>
              <td>{banner.height}</td>
              <td>
                <ActionContainer>
                  <IconWrapper onClick={() => handleViewBanner(banner)}>
                    <AiOutlineEye />
                  </IconWrapper>
                  <IconWrapper onClick={() => handleEdit(banner)}>
                    <AiOutlineEdit />
                  </IconWrapper>
                  <IconWrapper onClick={() => openDeleteModal(banner)}>
                    <AiOutlineDelete />
                  </IconWrapper>
                </ActionContainer>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <PaginationContainer>
        <ReactPaginate
          previousLabel={"Previous"}
          nextLabel={"Next"}
          breakLabel={"..."}
          breakClassName={"break-me"}
          pageCount={pageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={handlePageClick}
          containerClassName={"pagination"}
          activeClassName={"active"}
          previousLinkClassName={"pagination__link"}
          nextLinkClassName={"pagination__link"}
          disabledClassName={"pagination__link--disabled"}
          activeLinkClassName={"pagination__link--active"}
        />
      </PaginationContainer>
      {selectedBanner && (
        <>
          <ViewBannerModal
            isOpen={isViewModalOpen}
            onRequestClose={closeViewModal}
            banner={selectedBanner}
          />
          <EditBannerModal
            isEditModalOpen={isEditModalOpen}
            closeEditModal={closeEditModal}
            selectedBanner={selectedBanner}
            handleEditChange={handleEditChange}
            handleSave={handleSave}
            handleBannerUpdate={handleBannerUpdate}
          />
          <DeleteBannerModal
            isOpen={isDeleteModalOpen}
            onRequestClose={closeDeleteModal}
            banner={selectedBanner}
            handleDelete={handleDeleteBanner}
          />
          <ImageModal
            isOpen={isImageModalOpen}
            onClose={closeImageModal}
            imageSrc={selectedBanner.image}
            position={tooltipPosition}
          />
          {isTooltipVisible && (
            <Tooltip
              style={{ top: tooltipPosition.top, left: tooltipPosition.left }}
            >
              {tooltipText}
            </Tooltip>
          )}
        </>
      )}
      <AddBannerModal
        isOpen={isAddModalOpen}
        onRequestClose={closeAddModal}
        handleAddBanner={handleAddBanner}
      />
    </Container>
  )
}

export default Banners
