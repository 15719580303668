export const divisions = [
  "বরিশাল",
  "চট্টগ্রাম",
  "ঢাকা",
  "খুলনা",
  "ময়মনসিংহ",
  "রাজশাহী",
  "রংপুর",
  "সিলেট",
]

export const districts = [
  "বরগুনা",
  "বরিশাল",
  "ভোলা",
  "ঝালকাঠি",
  "পটুয়াখালী",
  "পিরোজপুর",
  "বান্দরবান",
  "চাঁদপুর",
  "চট্টগ্রাম",
  "কুমিল্লা",
  "কক্সবাজার",
  "ফেনী",
  "খাগড়াছড়ি",
  "নোয়াখালী",
  "রাঙ্গামাটি",
  "লক্ষ্মীপুর",
  "ঢাকা",
  "ফরিদপুর",
  "গাজীপুর",
  "গোপালগঞ্জ",
  "কিশোরগঞ্জ",
  "মাদারীপুর",
  "মানিকগঞ্জ",
  "মুন্সীগঞ্জ",
  "নারায়ণগঞ্জ",
  "নরসিংদী",
  "রাজবাড়ী",
  "শরীয়তপুর",
  "টাঙ্গাইল",
  "বাগেরহাট",
  "চুয়াডাঙ্গা",
  "যশোর",
  "ঝিনাইদহ",
  "খুলনা",
  "কুষ্টিয়া",
  "মাগুরা",
  "মেহেরপুর",
  "নড়াইল",
  "সাতক্ষীরা",
  "জামালপুর",
  "ময়মনসিংহ",
  "নেত্রকোনা",
  "শেরপুর",
  "বগুড়া",
  "চাঁপাইনবাবগঞ্জ",
  "জয়পুরহাট",
  "নওগাঁ",
  "নাটোর",
  "পাবনা",
  "রাজশাহী",
  "সিরাজগঞ্জ",
  "দিনাজপুর",
  "গাইবান্ধা",
  "লালমনিরহাট",
  "নীলফামারী",
  "পঞ্চগড়",
  "রংপুর",
  "ঠাকুরগাঁও",
  "হবিগঞ্জ",
  "মৌলভীবাজার",
  "সুনামগঞ্জ",
  "সিলেট",
]

export const upazilas = [
  "ধানমন্ডি",
  "গুলশান",
  "মিরপুর",
  "উত্তরা",
  "মোহাম্মদপুর",
  "তেজগাঁও",
  "বাড্ডা",
  "ফরিদপুর সদর",
  "আলফাডাঙ্গা",
  "ভাঙ্গা",
  "বোয়ালমারী",
  "চরভদ্রাসন",
  "মধুখালি",
  "নগরকান্দা",
  "সদরপুর",
  "গাজীপুর সদর",
  "কালীগঞ্জ",
  "কালীগঞ্জ",
  "কালীগঞ্জ",
  "কাপাসিয়া",
  "শ্রীপুর",
  "গোপালগঞ্জ সদর",
  "কাশিয়ানি",
  "কোটালিপাড়া",
  "মুকসুদপুর",
  "টুঙ্গিপাড়া",
  "অষ্টগ্রাম",
  "বাজিতপুর",
  "ভৈরব",
  "হোসেনপুর",
  "ইটনা",
  "করিমগঞ্জ",
  "কটিয়াদী",
  "কিশোরগঞ্জ সদর",
  "কুলিয়ারচর",
  "মিঠামাইন",
  "নিকলি",
  "পাকুন্দিয়া",
  "তাড়াইল",
  "মাদারীপুর সদর",
  "কালকিনি",
  "রাজৈর",
  "শিবচর",
  "মানিকগঞ্জ সদর",
  "ঘিওর",
  "হরিরামপুর",
  "সাটুরিয়া",
  "শিবালয়",
  "সিঙ্গাইর",
  "মুন্সীগঞ্জ সদর",
  "গজারিয়া",
  "লোহাজং",
  "সিরাজদিখান",
  "শ্রীনগর",
  "টঙ্গীবাড়ি",
  "নারায়ণগঞ্জ সদর",
  "আড়াইহাজার",
  "বন্দর",
  "রূপগঞ্জ",
  "সোনারগাঁও",
  "নরসিংদী সদর",
  "বেলাবো",
  "মনোহরদী",
  "পলাশ",
  "রায়পুরা",
  "শিবপুর",
  "রাজবাড়ী সদর",
  "বালিয়াকান্দি",
  "গোয়ালন্দঘাট",
  "পাংশা",
  "শরীয়তপুর সদর",
  "ডামুড্যা",
  "গোসাইরহাট",
  "নড়িয়া",
  "ভেদরগঞ্জ",
  "জাজিরা",
  "টাঙ্গাইল সদর",
  "বাসাইল",
  "ভূঞাপুর",
  "দেলদুয়ার",
  "ধনবাড়ী",
  "গোপালপুর",
  "ঘাটাইল",
  "কালিহাতি",
  "মধুপুর",
  "মির্জাপুর",
  "নাগরপুর",
  "সখীপুর",
  "আদমদীঘি",
  "বগুড়া সদর",
  "ধুনট",
  "দুপচাচিঁয়া",
  "গাবতলী",
  "কাহালু",
  "নন্দিগ্রাম",
  "সারিয়াকান্দি",
  "শাহজাহানপুর",
  "শেরপুর",
  "শিবগঞ্জ",
  "সোনাতলা",
  "ভোলাহাট",
  "গোমস্তাপুর",
  "নাচোল",
  "নবাবগঞ্জ সদর",
  "শিবগঞ্জ",
  "আক্কেলপুর",
  "জয়পুরহাট সদর",
  "কালাই",
  "ক্ষেতলাল",
  "পাঁচবিবি",
  "আত্রাই",
  "বদলগাছি",
  "ধামইরহাট",
  "মান্দা",
  "মহাদেবপুর",
  "নওগাঁ সদর",
  "নিয়ামতপুর",
  "পত্নীতলা",
  "পোরশা",
  "রাণীনগর",
  "সাপাহার",
  "বাগাতিপাড়া",
  "বড়াইগ্রাম",
  "গুরুদাসপুর",
  "লালপুর",
  "নাটোর সদর",
  "সিংড়া",
  "আটঘরিয়া",
  "বেড়া",
  "ভাঙ্গুড়া",
  "চাটমোহর",
  "ফরিদপুর",
  "ঈশ্বরদী",
  "পাবনা সদর",
  "সাঁথিয়া",
  "সুজানগর",
  "বাঘা",
  "বাগমারা",
  "চারঘাট",
  "দুর্গাপুর",
  "গোদাগাড়ি",
  "মোহনপুর",
  "পবা",
  "পুঠিয়া",
  "তানোর",
  "বেলকুচি",
  "চৌহালী",
  "কামারখন্দ",
  "কাজীপুর",
  "রায়গঞ্জ",
  "শাহজাদপুর",
  "সিরাজগঞ্জ সদর",
  "তাড়াশ",
  "উল্লাপাড়া",
  "বান্দরবান সদর",
  "রুমা",
  "থানচি",
  "লামা",
  "রোয়াংছড়ি",
  "নাইক্ষ্যংছড়ি",
  "আলীকদম",
  "ব্রাহ্মণবাড়িয়া সদর",
  "আশুগঞ্জ",
  "নবীনগর",
  "বাঞ্ছারামপুর",
  "কসবা",
  "নাসিরনগর",
  "আখাউড়া",
  "সরাইল",
  "বিজয়নগর",
  "চাঁদপুর সদর",
  "ফরিদগঞ্জ",
  "হাইমচর",
  "হাজীগঞ্জ",
  "কচুয়া",
  "মতলব দক্ষিণ",
  "মতলব উত্তর",
  "শাহরাস্তি",
  "চট্টগ্রাম সদর",
  "মীরসরাই",
  "সীতাকুণ্ড",
  "সন্দ্বীপ",
  "রাঙ্গুনিয়া",
  "রাউজান",
  "ফটিকছড়ি",
  "হাটহাজারী",
  "বোয়ালখালী",
  "আনোয়ারা",
  "চন্দনাইশ",
  "পটিয়া",
  "সাতকানিয়া",
  "লোহাগাড়া",
  "বাঁশখালী",
  "চকোরিয়া",
  "কক্সবাজার",
  "কুতুবদিয়া",
  "মহেশখালী",
  "রামু",
  "টেকনাফ",
  "উখিয়া",
  "পেকুয়া",
  "কুমিল্লা সদর",
  "লাকসাম",
  "চৌদ্দগ্রাম",
  "দেবিদ্বার",
  "মুরাদনগর",
  "দাউদকান্দি",
  "তিতাস",
  "হোমনা",
  "মেঘনা",
  "বরুড়া",
  "ব্রাহ্মণপাড়া",
  "নাঙ্গলকোট",
  "কক্সবাজার সদর",
  "চকরিয়া",
  "কুতুবদিয়া",
  "মহেশখালী",
  "রামু",
  "টেকনাফ",
  "উখিয়া",
  "পেকুয়া",
  "ফেনী সদর",
  "ছাগলনাইয়া",
  "দাগনভূঁঞা",
  "পরশুরাম",
  "ফুলগাজী",
  "সোনাগাজী",
  "খাগড়াছড়ি সদর",
  "দীঘিনালা",
  "লক্ষ্মীছড়ি",
  "মহালছড়ি",
  "মানিকছড়ি",
  "মাটিরাঙ্গা",
  "পানছড়ি",
  "রামগড়",
  "লক্ষ্মীপুর সদর",
  "রায়পুর",
  "রামগঞ্জ",
  "রামগতি",
  "কমলনগর",
  "নোয়াখালী সদর",
  "বেগমগঞ্জ",
  "হাতিয়া",
  "সুবর্ণচর",
  "কোম্পানীগঞ্জ",
  "চাটখিল",
  "সেনবাগ",
  "কবিরহাট",
  "সোনাইমুড়ি",
  "রাঙ্গামাটি সদর",
  "কাপ্তাই",
  "কাউখালি",
  "বাঘাইছড়ি",
  "বরকল",
  "বিলাইছড়ি",
  "জুরাছড়ি",
  "লংগদু",
  "রাজস্থলী",
  "কক্সবাজার সদর",
  "চকরিয়া",
  "কুতুবদিয়া",
  "মহেশখালী",
  "রামু",
  "টেকনাফ",
  "উখিয়া",
  "পেকুয়া",
  "বাগেরহাট সদর",
  "চিতলমারী",
  "ফকিরহাট",
  "কচুয়া",
  "মোল্লাহাট",
  "মোংলা",
  "মোরেলগঞ্জ",
  "রামপাল",
  "শরণখোলা",
  "চুয়াডাঙ্গা সদর",
  "আলমডাঙ্গা",
  "দামুড়হুদা",
  "জীবননগর",
  "যশোর সদর",
  "অভয়নগর",
  "বাঘারপাড়া",
  "চৌগাছা",
  "ঝিকরগাছা",
  "কেশবপুর",
  "মনিরামপুর",
  "শার্শা",
  "ঝিনাইদহ সদর",
  "হরিণাকুন্ডু",
  "কোটচাঁদপুর",
  "মহেশপুর",
  "শৈলকুপা",
  "কালীগঞ্জ",
  "খুলনা সদর",
  "বটিয়াঘাটা",
  "ডুমুরিয়া",
  "দাকোপ",
  "পাইকগাছা",
  "রূপসা",
  "তেরখাদা",
  "দিঘলিয়া",
  "কয়রা",
  "ফুলতলা",
  "কুষ্টিয়া সদর",
  "কুমারখালী",
  "খোকসা",
  "মিরপুর",
  "ভেড়ামারা",
  "দৌলতপুর",
  "মাগুরা সদর",
  "মহম্মদপুর",
  "শালিখা",
  "শ্রীপুর",
  "মেহেরপুর সদর",
  "গাংনী",
  "মুজিবনগর",
  "নড়াইল সদর",
  "লোহাগড়া",
  "কালিয়া",
  "সাতক্ষীরা সদর",
  "আশাশুনি",
  "কলারোয়া",
  "দেবহাটা",
  "তালা",
  "শ্যামনগর",
  "পাটকেলঘাটা",
  "জামালপুর সদর",
  "মেলান্দহ",
  "ইসলামপুর",
  "মাদারগঞ্জ",
  "দেওয়ানগঞ্জ",
  "সরিষাবাড়ি",
  "বকশীগঞ্জ",
  "ময়মনসিংহ সদর",
  "ফুলবাড়িয়া",
  "ত্রিশাল",
  "ভালুকা",
  "গৌরীপুর",
  "হালুয়াঘাট",
  "ধোবাউড়া",
  "ফুলপুর",
  "গফরগাঁও",
  "নান্দাইল",
  "ঈশ্বরগঞ্জ",
  "তারাকান্দা",
  "নেত্রকোনা সদর",
  "বারহাট্টা",
  "কলমাকান্দা",
  "দুর্গাপুর",
  "মদন",
  "মোহনগঞ্জ",
  "খালিয়াজুড়ি",
  "পূর্বধলা",
  "আটপাড়া",
  "শেরপুর সদর",
  "নালিতাবাড়ি",
  "নকলা",
  "নালিতাবাড়ি",
  "শ্রীবরদী",
  "বগুড়া সদর",
  "শেরপুর",
  "ধুনট",
  "শাজাহানপুর",
  "গাবতলী",
  "আদমদীঘি",
  "নন্দিগ্রাম",
  "কাহালু",
  "দুপচাচিয়া",
  "সোনাতলা",
  "সারিয়াকান্দি",
  "চাঁপাইনবাবগঞ্জ সদর",
  "শিবগঞ্জ",
  "ভোলাহাট",
  "গোমস্তাপুর",
  "নাচোল",
  "জয়পুরহাট সদর",
  "পাঁচবিবি",
  "কালাই",
  "ক্ষেতলাল",
  "আক্কেলপুর",
  "নওগাঁ সদর",
  "পত্নীতলা",
  "আত্রাই",
  "রাণীনগর",
  "বদলগাছি",
  "মান্দা",
  "মহাদেবপুর",
  "পোরশা",
  "নিয়ামতপুর",
  "ধামইরহাট",
  "নাটোর সদর",
  "বড়াইগ্রাম",
  "বাগাতিপাড়া",
  "লালপুর",
  "গুরুদাসপুর",
  "সিংড়া",
  "পাবনা সদর",
  "ঈশ্বরদী",
  "আটঘরিয়া",
  "ফরিদপুর",
  "ভাঙ্গুড়া",
  "সুজানগর",
  "চাটমোহর",
  "বেড়া",
  "সাঁথিয়া",
  "রাজশাহী সদর",
  "বাঘা",
  "বাগমারা",
  "পবা",
  "চারঘাট",
  "পুঠিয়া",
  "গোদাগাড়ি",
  "তানোর",
  "দুর্গাপুর",
  "মোহনপুর",
  "সিরাজগঞ্জ সদর",
  "রায়গঞ্জ",
  "শাহজাদপুর",
  "কাজীপুর",
  "তাড়াশ",
  "উল্লাপাড়া",
  "চৌহালী",
  "বেলকুচি",
  "কামারখন্দ",
  "দিনাজপুর সদর",
  "চিরিরবন্দর",
  "বিরামপুর",
  "ঘোড়াঘাট",
  "কাহারোল",
  "খানসামা",
  "নবাবগঞ্জ",
  "পার্বতীপুর",
  "ফুলবাড়ী",
  "বীরগঞ্জ",
  "বিরল",
  "হাকিমপুর",
  "গাইবান্ধা সদর",
  "সাদুল্লাপুর",
  "ফুলছড়ি",
  "সুন্দরগঞ্জ",
  "সাঘাটা",
  "গোবিন্দগঞ্জ",
  "পলাশবাড়ী",
  "লালমনিরহাট সদর",
  "আদিতমারী",
  "কালীগঞ্জ",
  "হাতিবান্ধা",
  "পাটগ্রাম",
  "নীলফামারী সদর",
  "ডোমার",
  "ডিমলা",
  "জলঢাকা",
  "কিশোরগঞ্জ",
  "সৈয়দপুর",
  "পঞ্চগড় সদর",
  "আটোয়ারী",
  "বোদা",
  "তেতুলিয়া",
  "দেবীগঞ্জ",
  "রংপুর সদর",
  "গঙ্গাচড়া",
  "তারাগঞ্জ",
  "বদরগঞ্জ",
  "মিঠাপুকুর",
  "কাউনিয়া",
  "পীরগাছা",
  "পীরগঞ্জ",
  "ঠাকুরগাঁও সদর",
  "পীরগঞ্জ",
  "রাণীশংকৈল",
  "হরিপুর",
  "বালিয়াডাঙ্গী",
  "হবিগঞ্জ সদর",
  "বাহুবল",
  "নবীগঞ্জ",
  "মাধবপুর",
  "চুনারুঘাট",
  "লাখাই",
  "শায়েস্তাগঞ্জ",
  "মৌলভীবাজার সদর",
  "বড়লেখা",
  "জুড়ী",
  "কুলাউড়া",
  "কমলগঞ্জ",
  "রাজনগর",
  "শ্রীমঙ্গল",
  "সুনামগঞ্জ সদর",
  "বিশ্বম্ভরপুর",
  "দোয়ারাবাজার",
  "ছাতক",
  "ছাতক",
  "তাহিরপুর",
  "ধর্মপাশা",
  "জগন্নাথপুর",
  "দিরাই",
  "শাল্লা",
  "দক্ষিণ সুনামগঞ্জ",
  "সিলেট সদর",
  "জকিগঞ্জ",
  "কানাইঘাট",
  "গোলাপগঞ্জ",
  "বিয়ানীবাজার",
  "ফেঞ্চুগঞ্জ",
  "বিশ্বনাথ",
  "বালাগঞ্জ",
  "কোম্পানীগঞ্জ",
  "ওসমানীনগর",
  "নারায়ণগঞ্জ সদর",
  "আড়াইহাজার",
  "বন্দর",
  "রূপগঞ্জ",
  "সোনারগাঁও",
]
