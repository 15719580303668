import React, { useEffect, useState } from "react"
import { toast } from "react-hot-toast"
import { FaTimes } from "react-icons/fa"
import Modal from "react-modal"
import styled from "styled-components"
import { openUploadWidget } from "../CloudinaryService"

const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`

const FormField = styled.div`
  display: flex;
  flex-direction: column;
`

const FormLabel = styled.label`
  font-weight: bold;
  font-size: 14px;
`

const FormInput = styled.input`
  padding: 0.4rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
`

const FormSelect = styled.select`
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
`

const ActionButton = styled.button`
  padding: 0.5rem 1rem;
  background-color: #dc2626;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    background-color: #dc262696;
  }
`

const ModalHeader = styled.h2`
  margin-bottom: 1rem;
  color: #dc2626;
`

const CloseButton = styled.button`
  background: none;
  border: none;
  font-size: 24px;
  color: #dc2626;
  cursor: pointer;
  transition: color 0.3s;

  &:hover {
    color: #d00000;
  }
`

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-height: 100%; /* Make sure modal content doesn't exceed the modal's height */
  overflow-y: auto; /* Enable vertical scrolling if content overflows */
`

const modalStyle = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "60%",
    height: "80%" /* Set the height to a fixed value */,
    padding: "2.5rem 3rem",
    borderRadius: "12px",
    boxShadow: "0 0 20px rgba(0, 0, 0, 0.2)",
    backgroundColor: "#fff",
    overflow: "hidden" /* Hide overflow on modal */,
  },
}

Modal.setAppElement("#root")

const AddBannerModal = ({ isOpen, onRequestClose, handleAddBanner }) => {
  const [page, setPage] = useState("HOME")
  const [image, setImage] = useState("")
  const [link, setLink] = useState("")
  const [height, setHeight] = useState("")
  const [width, setWidth] = useState("")
  const [order, setOrder] = useState(0)

  const [availableOrders, setAvailableOrders] = useState([])

  useEffect(() => {
    fetchAvailableOrders()
  }, [page])

  const fetchAvailableOrders = async () => {
    const response = await fetch(
      "https://kalerpotro.pythonanywhere.com/api/ad/banner/list/"
    )
    const banners = await response.json()

    const usedOrders = banners
      .filter((banner) => banner.page === page)
      .map((banner) => banner.order)

    const maxOrder = page === "HOME" ? 3 : 1
    const availableOrders = []
    for (let i = 1; i <= maxOrder; i++) {
      if (!usedOrders.includes(i)) {
        availableOrders.push(i)
      }
    }
    setAvailableOrders(availableOrders)
    setOrder(availableOrders[0] || 0)
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    const newBanner = {
      page,
      image,
      link,
      height,
      width,
      order: parseInt(order, 10),
    }

    const accessToken = localStorage.getItem("accessToken")
    console.log(newBanner)
    const response = await fetch(
      "https://kalerpotro.pythonanywhere.com/api/ad/banner/",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(newBanner),
      }
    )

    if (response.ok) {
      handleAddBanner(await response.json())
      onRequestClose()
    } else {
      console.error("Failed to add banner")
    }
  }

  const handleUpload = () => {
    openUploadWidget(
      {
        cloudName: process.env.REACT_APP_CLOUD_NAME,
        uploadPreset: process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET,
        sources: ["local", "url", "camera"],
      },
      (error, result) => {
        if (!error && result.event === "success") {
          setImage(result.info.secure_url)
          toast.success("Image uploaded successfully!")
        } else if (error) {
          toast.error("Image upload failed.")
        }
      }
    )
  }

  return (
    <>
      <Modal
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        style={modalStyle}
        contentLabel="Add Banner Modal"
      >
        <ModalContent>
          <div className="flex justify-between items-center">
            <ModalHeader>Add New Banner</ModalHeader>
            <CloseButton onClick={onRequestClose}>
              <FaTimes />
            </CloseButton>
          </div>
          <FormContainer onSubmit={handleSubmit}>
            <FormField>
              <FormLabel>Page</FormLabel>
              <FormSelect
                value={page}
                onChange={(e) => setPage(e.target.value)}
                required
              >
                <option value="HOME">Home</option>
                <option value="SEARCH_RESULT">Search Result</option>
                <option value="NEWS_POST">Newspost</option>
                <option value="CATEGORY">Category</option>
                <option value="TRENDINGTAGS">Trendingtags</option>
              </FormSelect>
            </FormField>
            <FormField>
              <FormLabel>Image</FormLabel>
              <ActionButton type="button" onClick={handleUpload}>
                Upload Image
              </ActionButton>
              {image && (
                <img
                  src={image}
                  alt="Uploaded"
                  style={{ marginTop: "1rem", maxWidth: "100%" }}
                />
              )}
            </FormField>
            <FormField>
              <FormLabel>Link (Optional)</FormLabel>
              <FormInput
                type="url"
                value={link}
                onChange={(e) => setLink(e.target.value)}
              />
            </FormField>
            <FormField>
              <FormLabel>Height</FormLabel>
              <FormInput
                type="text"
                value={height}
                onChange={(e) => setHeight(e.target.value)}
                required
              />
            </FormField>
            <FormField>
              <FormLabel>Width</FormLabel>
              <FormInput
                type="text"
                value={width}
                onChange={(e) => setWidth(e.target.value)}
                required
              />
            </FormField>
            <FormField>
              <FormLabel>Order</FormLabel>
              <FormSelect
                value={order}
                onChange={(e) => setOrder(e.target.value)}
                required
              >
                <option value={0}>Select Order</option>
                {availableOrders.map((orderValue) => (
                  <option key={orderValue} value={orderValue}>
                    {orderValue}
                  </option>
                ))}
              </FormSelect>
            </FormField>

            <ActionButton type="submit">Add Banner</ActionButton>
          </FormContainer>
        </ModalContent>
      </Modal>
    </>
  )
}

export default AddBannerModal
