import React, { useEffect, useMemo, useState } from "react";
import { toast } from "react-hot-toast";
import { AiOutlineDelete, AiOutlineEdit, AiOutlineEye } from "react-icons/ai";
import ReactPaginate from "react-paginate";
import DeleteUserModal from "./Modals/DeleteUserModal";
import EditUserModal from "./Modals/EditUserModal";
import SignupModal from "./Modals/SignUpEditorModal";
import ViewUserModal from "./Modals/ViewUserModal";
import {
  ActionButton,
  ActionButtonUser,
  ActionContainer,
  Container,
  Header,
  IconWrapper,
  PaginationContainer,
  Table,
} from "./RoleManagement.styled";

const ITEMS_PER_PAGE = 5;
const BASE_URL = "https://kalerpotro.pythonanywhere.com";

const RoleManagement = () => {
  const [showModal, setShowModal] = useState(false);
  const [users, setUsers] = useState([]); // Ensure it's an array
  const [news, setNews] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [isViewModalOpen, setIsViewModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: "ascending",
  });
  const [roleFilter, setRoleFilter] = useState(null);

  // Fetch users from API
  useEffect(() => {
    const fetchUsers = async () => {
      const token = localStorage.getItem("accessToken");
      try {
        const response = await fetch(
          `${BASE_URL}/api/auth_user/admin/alluser/`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (!response.ok) {
          throw new Error("Failed to fetch users");
        }
        const data = await response.json();
        setUsers(data);
      } catch (error) {
        toast.error("Failed to fetch users");
        console.error("Fetch error:", error);
        setUsers([]);
      }
    };

    // Fetch news from API
    const fetchNews = async () => {
      try {
        const response = await fetch(`${BASE_URL}/api/news/list/`);
        if (!response.ok) {
          throw new Error("Failed to fetch news");
        }
        const data = await response.json();
        setNews(data);
      } catch (error) {
        toast.error("Failed to fetch news");
        console.error("Fetch error:", error);
        setNews([]);
      }
    };

    fetchUsers();
    fetchNews();
  }, []);

  // Sort and filter users
  const sortedUsers = useMemo(() => {
    let sortableUsers = [...users];
    if (sortConfig.key !== null) {
      sortableUsers.sort((a, b) => {
        const aValue = a[sortConfig.key];
        const bValue = b[sortConfig.key];

        if (aValue === null || aValue === undefined) return 1;
        if (bValue === null || bValue === undefined) return -1;

        if (typeof aValue === "string") {
          return sortConfig.direction === "ascending"
            ? aValue.localeCompare(bValue, undefined, { numeric: true })
            : bValue.localeCompare(aValue, undefined, { numeric: true });
        }
        if (aValue < bValue) {
          return sortConfig.direction === "ascending" ? -1 : 1;
        }
        if (aValue > bValue) {
          return sortConfig.direction === "ascending" ? 1 : -1;
        }
        return 0;
      });
    }
    if (roleFilter) {
      sortableUsers = sortableUsers.filter((user) => user.role === roleFilter);
    }
    return sortableUsers;
  }, [users, sortConfig, roleFilter]);

  const handleSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  const getSortIcon = (key) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === "ascending" ? "↑" : "↓";
    }
    return null;
  };

  const handleView = (user) => {
    setSelectedUser(user);
    setIsViewModalOpen(true);
  };

  const handleEdit = (user) => {
    console.log(user)
    setSelectedUser(user);
    setIsEditModalOpen(true);
  };

  const handleDelete = (user) => {
    setSelectedUser(user);
    setIsDeleteModalOpen(true);
  };

  const handleDeleteConfirm = async (userId) => {
    const token = localStorage.getItem("accessToken");
    try {
      const response = await fetch(
        `${BASE_URL}/api/auth_user/admin/user/${userId}/`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.ok) {
        setUsers(users.filter((user) => user.id !== userId));
        toast.success("User deleted successfully");
      } else {
        toast.error("Failed to delete user");
      }
    } catch (error) {
      toast.error("Failed to delete user");
    }
    setIsDeleteModalOpen(false);
  };

  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected);
  };

  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setSelectedUser((prevUser) => ({
      ...prevUser,
      [name]: value,
    }));
  };

  const handleSave = async (e) => {
    e.preventDefault();
    if (!selectedUser || !selectedUser.id) {
      toast.error("No user selected");
      return;
    }
    // return console.log(selectedUser)
//     const username = e.target.username.value;
//     const firstName = e.target.firstName.value;
//     const lastName = e.target.lastName.value;
// const email = e.target.email.value;
// const phone = e.target.phone.value;
// const role = e.target.role.value;
// const bio = e.target.bio.value;
// const address = e.target.address.value
// const user = {username,firstName,lastName,email,phone,role,bio,address}
// return console.log(selectedUser)
    const { profile_picture, ...userWithoutProfilePicture } = selectedUser;
    const token = localStorage.getItem("accessToken");
    try {
      const response = await fetch(
        `${BASE_URL}/api/auth_user/admin/user/${selectedUser.id}/`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(userWithoutProfilePicture),
        }
      );
      if (response.ok) {
        const data = await response.json();
        setUsers((prevUsers) =>
          prevUsers.map((user) => (user.id === data.id ? data : user))
        );
        setIsEditModalOpen(false);
        toast.success("User updated successfully!");
      } else {
        toast.error("Failed to update user");
      }
    } catch (error) {
      toast.error("Failed to update user");
    }
  };

  const handleUserUpdate = (updatedUser) => {
    setUsers((prevUsers) =>
      prevUsers.map((user) => (user.id === updatedUser.id ? updatedUser : user))
    );
  };

  const offset = currentPage * ITEMS_PER_PAGE;
  const currentUsers = sortedUsers.slice(offset, offset + ITEMS_PER_PAGE);
  const pageCount = Math.ceil(users.length / ITEMS_PER_PAGE);

  const toggleUserRole = async (user) => {
    const newRole = user.role === "ADMIN" ? "EDITOR" : "ADMIN";
    const token = localStorage.getItem("accessToken");
    try {
      const response = await fetch(
        `${BASE_URL}/api/auth_user/admin/user/${user.id}/`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ role: newRole }),
        }
      );
      if (response.ok) {
        const updatedUser = await response.json();
        setUsers((prevUsers) =>
          prevUsers.map((u) => (u.id === updatedUser.id ? updatedUser : u))
        );
        toast.success(`User role updated to ${newRole}`);
      } else {
        toast.error("Failed to update user role");
      }
    } catch (error) {
      toast.error("Failed to update user role");
    }
  };

  const userNewsCounts = useMemo(() => {
    const counts = users.map((user) => {
      const userNews = news.filter((item) => item.editor === user.id);
      const totalNews = userNews.length;
      const pendingNews = userNews.filter((item) => !item.is_active).length;
      return { userId: user.id, totalNews, pendingNews };
    });
    return counts;
  }, [users, news]);

  const filterByRole = (role) => {
    setRoleFilter(role);
    setCurrentPage(0);
  };

  return (
    <Container>
      <Header>
        <ActionButtonUser onClick={() => setShowModal(true)}>
          Add User
        </ActionButtonUser>
        <ActionButton onClick={() => filterByRole("EDITOR")}>
          Filter Editors
        </ActionButton>
        <ActionButton onClick={() => filterByRole("ADMIN")}>
          Filter Admins
        </ActionButton>
        <ActionButton onClick={() => filterByRole(null)}>
          Clear Filter
        </ActionButton>
      </Header>
      <Table>
        <thead>
          <tr>
            <th onClick={() => handleSort("profile_picture")}>
              {getSortIcon("profile_picture")}Photo
            </th>
            <th onClick={() => handleSort("first_name")}>
              {getSortIcon("first_name")}Name
            </th>
            <th onClick={() => handleSort("email")}>
              {getSortIcon("email")}Email
            </th>
            <th onClick={() => handleSort("phone")}>
              {getSortIcon("phone")}Phone
            </th>
            <th onClick={() => handleSort("address")}>
              {getSortIcon("address")}Address
            </th>
            <th onClick={() => handleSort("role")}>
              {getSortIcon("role")}Role
            </th>
            <th onClick={() => handleSort("totalNews")}>
              {getSortIcon("totalNews")}News
            </th>
            <th onClick={() => handleSort("pendingNews")}>
              {getSortIcon("pendingNews")}Pending
            </th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {currentUsers.map((user) => {
            const { totalNews, pendingNews } =
              userNewsCounts.find((count) => count.userId === user.id) || {
                totalNews: 0,
                pendingNews: 0,
              };
            return (
              <tr key={user.id}>
                <td>
                  <img
                    src={`https://kalerpotro.pythonanywhere.com${user.profile_picture}`}
                    alt="Profile"
                    style={{
                      width: "50px",
                      height: "50px",
                      borderRadius: "50%",
                    }}
                  />
                </td>
                <td>{`${user.first_name} ${user.last_name}`}</td>
                <td>{user.email}</td>
                <td>{user.phone}</td>
                <td>{user.address}</td>
                <td>
                  <button onClick={() => toggleUserRole(user)}>
                    {user.role}
                  </button>
                </td>
                <td>{totalNews}</td>
                <td>{pendingNews}</td>
                <td>
                  <ActionContainer>
                    <IconWrapper onClick={() => handleView(user)}>
                      <AiOutlineEye />
                    </IconWrapper>
                    <IconWrapper onClick={() => handleEdit(user)}>
                      <AiOutlineEdit />
                    </IconWrapper>
                    <IconWrapper onClick={() => handleDelete(user)}>
                      <AiOutlineDelete />
                    </IconWrapper>
                  </ActionContainer>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
      <PaginationContainer>
        <ReactPaginate
          previousLabel={"Previous"}
          nextLabel={"Next"}
          breakLabel={"..."}
          pageCount={pageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={handlePageClick}
          containerClassName={"pagination"}
          activeClassName={"active"}
        />
      </PaginationContainer>
      <SignupModal
        show={showModal}
        onClose={() => setShowModal(false)}
        setUsers={setUsers}
        users={users}
      />
      {selectedUser && (
        <>
          <ViewUserModal
            isOpen={isViewModalOpen}
            onRequestClose={() => setIsViewModalOpen(false)}
            user={selectedUser}
          />
          <EditUserModal
            isEditModalOpen={isEditModalOpen}
            closeEditModal={() => setIsEditModalOpen(false)}
            selectedUser={selectedUser}
            handleEditChange={handleEditChange}
            handleSave={handleSave}
            handleUserUpdate={handleUserUpdate}
          />
          <DeleteUserModal
            isOpen={isDeleteModalOpen}
            onRequestClose={() => setIsDeleteModalOpen(false)}
            user={selectedUser}
            handleDeleteConfirm={handleDeleteConfirm}
          />
        </>
      )}
    </Container>
  );
};

export default RoleManagement;
