import React from "react";
import Modal from "react-modal";
import styled from "styled-components";
import { FaTimes } from "react-icons/fa";

const ModalStyle = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "30%",
    padding: "2rem",
    borderRadius: "12px",
    boxShadow: "0 0 20px rgba(0, 0, 0, 0.2)",
    backgroundColor: "#fff",
  },
};

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ModalTitle = styled.h2`
  margin: 0;
  font-size: 20px;
  color: #dc2626;
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #dc2626;
  transition: color 0.3s;

  &:hover {
    color: #f33823;
  }
`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
`;

const ModalActions = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 20px;
`;

const ActionButton = styled.button`
  padding: 10px 20px;
  border-radius: 6px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
`;

const DeleteButton = styled(ActionButton)`
  background-color: #dc2626;
  color: white;

  &:hover {
    background-color: #dc262688;
  }
`;

const CancelButton = styled(ActionButton)`
  background-color: #ddd;
  color: #333;

  &:hover {
    background-color: #ccc;
  }
`;

const DeleteNewsPostModal = ({ isOpen, onClose, onDelete }) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      style={ModalStyle}
      contentLabel="Delete News Post"
      ariaHideApp={false}
    >
      <ModalHeader>
        <ModalTitle>Delete News Post</ModalTitle>
        <CloseButton onClick={onClose}>
          <FaTimes />
        </CloseButton>
      </ModalHeader>
      <p>Are you sure you want to delete this news post?</p>
      <ModalActions>
        <DeleteButton onClick={onDelete}>Delete</DeleteButton>
        <CancelButton onClick={onClose}>Cancel</CancelButton>
      </ModalActions>
    </Modal>
  );
};

export default DeleteNewsPostModal;

Modal.setAppElement("#root");
