import { Collapse } from "antd"
import React, { useEffect, useState } from "react"
import toast from "react-hot-toast"
import {
  LuHome,
  LuLogOut,
  LuSearch,
  LuSettings,
  LuTrendingUp,
} from "react-icons/lu"
import { useDispatch, useSelector } from "react-redux"
import { Link, useLocation, useNavigate } from "react-router-dom"
import styled from "styled-components"
import { clearUser } from "../store/authSlice"
import { persistor } from "../store/store"
import { clearUserDetails } from "../store/userSlice"

const { Panel } = Collapse

const SidePanelContainer = styled.div`
  width: 18rem;
  height: 100vh;
  background: #fffcfb;
  display: flex;
  flex-direction: column;
  align-items: start;
  padding: 1rem;
`

const Logo = styled.div`
  margin: 0 auto;
  font-size: 2rem;
  font-weight: bold;
  color: #dc2626;
  z-index: 999;
  overflow: hidden;
`

const LogoImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`

const MenuContainer = styled.div`
  margin-top: 2.8rem;
  flex-grow: 1;
  width: 100%;
  padding: 0 1rem;
  z-index: 999;
`

const MenuItem = styled.div`
  margin-bottom: 0.4rem;
  padding: 0.8rem;
  display: flex;
  align-items: center;
  justify-content: start;
  border-radius: 0.5rem;
  cursor: pointer;
  background: ${({ selected }) => (selected ? "#999" : "#fffcfb")};
  color: ${({ selected }) => (selected ? "white" : "black")};

  &:hover {
    background: #dc2626;
    color: white;
  }
`

const MenuIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.5rem;
  height: 1rem;
  width: 1rem;
`

const MenuLink = styled(Link)`
  text-decoration: none;
  font-size: 14px;
  color: inherit;
`

const SidePanel = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const user = useSelector((state) => state.auth.user)
  const [selected, setSelected] = useState(location.pathname)
  const [logo, setLogo] = useState(null)
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setSelected(location.pathname)
  }, [location.pathname])

  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken")
    if (
      !accessToken &&
      !["/reset", "/reset-password", "/changepass"].includes(location.pathname)
    ) {
      toast.error("You must login first")
      navigate("/login")
    }
    setLoading(false);
  }, [navigate, location.pathname])

  useEffect(() => {
    const fetchLogo = async () => {
      try {
        const response = await fetch(
          "https://kalerpotro.pythonanywhere.com/api/ad/logo/list/"
        )
        const data = await response.json()
        const adminLogo = data.find(
          (logo) => logo.portal_type === "ADMIN_DASHBOARD" && logo.is_active
        )
        setLogo(adminLogo)
      } catch (error) {
        console.error("Failed to fetch logo:", error)
      }
    }
    fetchLogo()
  }, [])

  const handleLogout = async () => {
    dispatch(clearUser())
    dispatch(clearUserDetails())
    localStorage.removeItem("accessToken")
    localStorage.removeItem("refreshToken")
    localStorage.removeItem("role")
    localStorage.removeItem("persist:auth")
    localStorage.removeItem("persist:user")
    await persistor.purge()
    navigate("/login")
    toast.success("Logged out successfully")
  }

  if (!localStorage.getItem("accessToken")) {
    return null
  }

  if (!user) {
    return null
  }

  if (loading) {
    return null
  }

  const renderMenuItem = (path, icon, label) => (
    <MenuItem
      selected={selected === path}
      onClick={() => {
        setSelected(path)
        navigate(path)
      }}
    >
      <MenuIcon>{icon}</MenuIcon>
      <MenuLink to={path}>{label}</MenuLink>
    </MenuItem>
  )

  return (
    <SidePanelContainer>
      {
        logo && <img src={logo.image} height={logo.height} width={logo.width} className="object-cover" alt="" />
      }
      <MenuContainer>
        {renderMenuItem("/overview", <LuHome />, "Overview")}
        {(user.role === "ADMIN" || user.role === "SUPER_ADMIN") && (
          <Collapse defaultActiveKey={["1", "2", "3"]} ghost>
            <Panel header="Content Management" key="1">
              {renderMenuItem("/categories", <LuSearch />, "Categories")}
              {renderMenuItem("/news-posts", <LuTrendingUp />, "News Posts")}
              {renderMenuItem(
                "/video-gallary",
                <LuTrendingUp />,
                "Video Gallary"
              )}
              {renderMenuItem("/tags", <LuTrendingUp />, "Tags")}
            </Panel>
            <Panel header="Ad Management" key="2">
              {renderMenuItem("/banners", <LuTrendingUp />, "Banners")}
              {renderMenuItem("/ads", <LuTrendingUp />, "Ads")}
            </Panel>
            <Panel header="Site Management" key="3">
              {renderMenuItem(
                "/role-management",
                <LuSettings />,
                "Role Management"
              )}
              {renderMenuItem(
                "/company-info",
                <LuTrendingUp />,
                "Company Info"
              )}
              {renderMenuItem("/news-letter", <LuTrendingUp />, "NewsLetter")}
              {renderMenuItem("/nav", <LuTrendingUp />, "Navbar Links")}
              {renderMenuItem("/footer", <LuTrendingUp />, "Footer Links")}
            </Panel>
          </Collapse>
        )}
        {user.role === "EDITOR" && (
          <Collapse defaultActiveKey={["1"]} ghost>
            <Panel header="Content Management" key="1">
              {renderMenuItem("/categories", <LuSearch />, "Categories")}
              {renderMenuItem("/news-posts", <LuTrendingUp />, "News Posts")}
              {renderMenuItem(
                "/video-gallary",
                <LuTrendingUp />,
                "Video Gallary"
              )}
              {renderMenuItem("/tags", <LuTrendingUp />, "Tags")}
            </Panel>
          </Collapse>
        )}
        <MenuItem selected={selected === "/logout"} onClick={handleLogout}>
          <MenuIcon>
            <LuLogOut />
          </MenuIcon>
          <MenuLink to="#">Logout</MenuLink>
        </MenuItem>
      </MenuContainer>
    </SidePanelContainer>
  )
}

export default SidePanel
