import { Button, Form, Input, message, Modal, Space, Switch, Table } from "antd"
import { useEffect, useState } from "react"
import { AiOutlineDelete, AiOutlineEdit, AiOutlineEye } from "react-icons/ai"
import ReactPaginate from "react-paginate"
import styled from "styled-components"
import { PaginationContainer } from "../NewsPostUpdated/NewsPostUpdated.styled"
import ViewTagsNewsModal from "./Modals/ViewTagsNewsModal"

const { Column } = Table
const { Search } = Input

const Container = styled.div`
  margin-top: 1.4rem;
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
`

const AddButton = styled.button`
  padding: 16px 35.5px;
  background-color: #dc2626;
  color: #fff;
  font-weight: bold;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 13px;
  margin: 0 0 0 auto;
  &:hover {
    box-shadow: 0px 4px 4px 0px #00000040;
  }

  @media (max-width: 768px) {
    padding: 10px 20px;
  }

  @media (max-width: 480px) {
    width: 100%;
    margin-right: 0;
    margin-bottom: 0.5rem;
  }
`

const ActionContainer = styled.div`
  display: flex;
  gap: 10px;
  margin: auto 0;
`

const IconWrapper = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  padding: 13px;
  box-sizing: content-box;
  background-color: transparent;
  transition: background-color 0.3s;
  border-radius: 55px;

  svg {
    color: #dc2626;
    cursor: pointer;
    transition: color 0.3s;
    width: 24px;
    height: 24px;
  }

  &:hover {
    background-color: #dc2626;
    svg {
      color: #fff;
      cursor: pointer;
      transition: color 0.3s;
    }
  }
`

const StyledTable = styled(Table)`
  .ant-table-thead > tr > th {
    background-color: #dc2626;
    color: #fff;
    text-align:center;
  }
  .ant-table-tbody > tr > td {
    padding: 0 0 0 8px;
    text-align:center;
  }
`

function Tags() {
  const [data, setData] = useState([])
  const [filteredData, setFilteredData] = useState([])
  const [loading, setLoading] = useState(true)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [isViewModalVisible, setIsViewModalVisible] = useState(false)
  const [editingTag, setEditingTag] = useState(null)
  const [viewingTag, setViewingTag] = useState(null)
  const [newsList, setNewsList] = useState([])
  const [form] = Form.useForm()
  const [currentPage, setCurrentPage] = useState(1);
  const [tagsPerPage] = useState(5);

  useEffect(() => {
    fetchData()
  }, [])

  const fetchData = async () => {
    setLoading(true)
    try {
      const [tagsResponse, newsResponse] = await Promise.all([
        fetch(
          "https://kalerpotro.pythonanywhere.com/api/ad/trendingtags/list/",
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            },
          }
        ),
        fetch("https://kalerpotro.pythonanywhere.com/api/news/list/"),
      ])

      const [tagsData, newsData] = await Promise.all([
        tagsResponse.json(),
        newsResponse.json(),
      ])

      const enrichedTagsData = tagsData.map((tag) => {
        const relatedNews = newsData.filter((news) =>
          news.trending_tags.includes(tag.id)
        )
        const totalShareCount = relatedNews.reduce(
          (sum, news) => sum + news.share_count,
          0
        )
        return {
          ...tag,
          newsCount: relatedNews.length,
          totalShareCount,
        }
      })

      setData(enrichedTagsData)
      setFilteredData(enrichedTagsData)
    } catch (error) {
      message.error("Failed to fetch data")
    }
    setLoading(false)
  }

  const handleDelete = async (id) => {
    try {
      await fetch(
        `https://kalerpotro.pythonanywhere.com/api/ad/trendingtags/${id}/`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      )
      message.success("Tag deleted successfully")
      fetchData()
    } catch (error) {
      message.error("Failed to delete tag")
    }
  }

  const showEditModal = (record) => {
    setEditingTag(record)
    form.setFieldsValue({
      tag: record.tag,
      is_latest: record.is_latest,
    })
    setIsModalVisible(true)
  }

  const showViewModal = async (record) => {
    setViewingTag(record)
    try {
      const newsResponse = await fetch(
        "https://kalerpotro.pythonanywhere.com/api/news/list/"
      )
      const newsData = await newsResponse.json()
      const relatedNews = newsData.filter((news) =>
        news.trending_tags.includes(record.id)
      )
      setNewsList(relatedNews)
    } catch (error) {
      message.error("Failed to fetch news")
    }
    setIsViewModalVisible(true)
  }

  const handleEdit = async (values) => {
    try {
      await fetch(
        `https://kalerpotro.pythonanywhere.com/api/ad/trendingtags/${editingTag.id}/`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
          body: JSON.stringify(values),
        }
      )
      message.success("Tag updated successfully")
      setIsModalVisible(false)
      fetchData()
    } catch (error) {
      message.error("Failed to update tag")
    }
  }

  const handleAdd = async (values) => {
    try {
      await fetch(
        "https://kalerpotro.pythonanywhere.com/api/ad/trendingtags/",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
          body: JSON.stringify(values),
        }
      )
      message.success("Tag added successfully")
      setIsModalVisible(false)
      form.resetFields()
      fetchData()
    } catch (error) {
      message.error("Failed to add tag")
    }
  }

  const handleCancel = () => {
    setIsModalVisible(false)
    setEditingTag(null)
    form.resetFields()
  }

  const handleViewModalCancel = () => {
    setIsViewModalVisible(false)
    setViewingTag(null)
  }

  const handleSearch = (value) => {
    const filtered = data.filter((item) =>
      item.tag.toLowerCase().includes(value.toLowerCase())
    );
    setCurrentPage(1); // Reset to page 1
    setFilteredData(filtered);
  }
  

  const handleReset = () => {
    setFilteredData(data)
  }

  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected + 1); 
  };

  const indexOfLastTags = currentPage * tagsPerPage;
  const indexOfFirstTags = indexOfLastTags - tagsPerPage;
  const curentTags = filteredData.slice(indexOfFirstTags, indexOfLastTags);
  

  return (
    <Container>
      <Header>
        <Space>
          <Search
            placeholder="Search tags"
            onSearch={handleSearch}
            style={{ width: 200 }}
          />
          <Button onClick={handleReset}>Reset Filters</Button>
        </Space>
        <AddButton onClick={() => setIsModalVisible(true)}>Add Tag</AddButton>
      </Header>
      <StyledTable
        dataSource={curentTags}
        loading={loading}
        rowKey="id"
        bordered
        pagination={false}
         className="shadow-lg"
      >
        <Column
          title="Tag"
          dataIndex="tag"
          key="tag"
          sorter={(a, b) => a.tag.localeCompare(b.tag)}
        />
        <Column
          title="Showed on Newsportal"
          dataIndex="is_latest"
          key="is_latest"
          render={(text) => (text ? "Yes" : "No")}
          sorter={(a, b) => a.is_latest - b.is_latest}
        />
        <Column
          title="News Count"
          dataIndex="newsCount"
          key="newsCount"
          sorter={(a, b) => a.newsCount - b.newsCount}
        />
        <Column
          title="Total Share Count"
          dataIndex="totalShareCount"
          key="totalShareCount"
          sorter={(a, b) => a.totalShareCount - b.totalShareCount}
        />
        <Column
          title="Created At"
          dataIndex="created_at"
          key="created_at"
          render={(text) => new Date(text).toLocaleDateString()}
          sorter={(a, b) => new Date(a.created_at) - new Date(b.created_at)}
        />
        <Column
          title="Last Updated"
          dataIndex="updated_at"
          key="updated_at"
          render={(text) => new Date(text).toLocaleDateString()}
          sorter={(a, b) => new Date(a.updated_at) - new Date(b.updated_at)}
        />
        <Column
          title="Actions"
          key="actions"
          render={(text, record) => (
            <ActionContainer>
              <IconWrapper onClick={() => showViewModal(record)}>
                <AiOutlineEye />
              </IconWrapper>
              <IconWrapper onClick={() => showEditModal(record)}>
                <AiOutlineEdit />
              </IconWrapper>
              <IconWrapper onClick={() => handleDelete(record.id)}>
                <AiOutlineDelete />
              </IconWrapper>
            </ActionContainer>
          )}
        />
      </StyledTable>
      <PaginationContainer>
        <ReactPaginate
          previousLabel={"Previous"}
          nextLabel={"Next"}
          pageCount={Math.ceil(filteredData.length / tagsPerPage)}
          onPageChange={handlePageChange}
          containerClassName={"pagination"}
          activeClassName={"active"}
          previousClassName={"previous"}
          nextClassName={"next"}
        />
      </PaginationContainer>
      <Modal
        title={editingTag ? "Edit Tag" : "Add Tag"}
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
      >
        <Form
          form={form}
          layout="vertical"
          initialValues={
            editingTag
              ? { tag: editingTag.tag, is_latest: editingTag.is_latest }
              : { is_latest: false }
          }
          onFinish={editingTag ? handleEdit : handleAdd}
        >
          <Form.Item
            name="tag"
            label="Tag"
            rules={[{ required: true, message: "Please enter a tag" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item name="is_latest" label="Is Latest" valuePropName="checked">
            <Switch />
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              style={{ backgroundColor: "#dc2626", borderColor: "#dc2626" }}
            >
              {editingTag ? "Update" : "Add"}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
      <ViewTagsNewsModal
        visible={isViewModalVisible}
        onCancel={handleViewModalCancel}
        newsList={newsList}
        viewingTag={viewingTag}
      />
    </Container>
  )
}

export default Tags
