import { createSlice } from '@reduxjs/toolkit';

const userSlice = createSlice({
  name: 'user',
  initialState: {
    id: null,
    username: '',
    first_name: '',
    last_name: '',
    email: '',
    profile_picture: '',
    phone: '',
    address: '',
    bio: ''
  },
  reducers: {
    setUserDetails: (state, action) => {
      const { id, username, first_name, last_name, email, profile_picture, phone, address, bio } = action.payload;
      state.id = id;
      state.username = username;
      state.first_name = first_name;
      state.last_name = last_name;
      state.email = email;
      state.profile_picture = profile_picture;
      state.phone = phone;
      state.address = address;
      state.bio = bio;
    },
    clearUserDetails: (state) => {
      state.id = null;
      state.username = '';
      state.first_name = '';
      state.last_name = '';
      state.email = '';
      state.profile_picture = '';
      state.phone = '';
      state.address = '';
      state.bio = '';
    }
  }
});

export const { setUserDetails, clearUserDetails } = userSlice.actions;

export default userSlice.reducer;
