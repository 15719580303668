import { SearchOutlined } from "@ant-design/icons"
import { Input, Table } from "antd"
import React, { useEffect, useState } from "react"
import toast from "react-hot-toast"
import { AiOutlineDelete, AiOutlineEdit } from "react-icons/ai"
import ReactPaginate from "react-paginate"
import { PaginationContainer } from "../NewsPostUpdated/NewsPostUpdated.styled"
import CreateCategoryModal from "./Modals/CreateCategoryModal"
import DeleteCategoryModal from "./Modals/DeleteCategoryModal"
import EditCategoryModal from "./Modals/EditCategoryModal"

const Categories = () => {
  const [categories, setCategories] = useState([])
  const [news, setNews] = useState([])
  const [search, setSearch] = useState("")
  const [currentPage, setCurrentPage] = useState(1)
  const [categoriesPerPage] = useState(9)

  const [showModal, setShowModal] = useState(false)
  const [modalType, setModalType] = useState("")
  const [modalData, setModalData] = useState({
    id: null,
    name: "",
    description: "",
    order: 0,
  })

  const fetchCategories = async () => {
    try {
      const response = await fetch(
        "https://kalerpotro.pythonanywhere.com/api/news/category/list/"
      )
      if (!response.ok) {
        throw new Error("Network response was not ok")
      }
      const data = await response.json()
      setCategories(data)
    } catch (error) {
      console.error("Fetch failed:", error)
      setCategories([])
    }
  }

  const fetchNews = async () => {
    try {
      const response = await fetch(
        "https://kalerpotro.pythonanywhere.com/api/news/list/"
      )
      if (!response.ok) {
        throw new Error("Network response was not ok")
      }
      const data = await response.json()
      setNews(data)
    } catch (error) {
      console.error("Fetch failed:", error)
      setNews([])
    }
  }

  useEffect(() => {
    fetchCategories()
    fetchNews()
  }, [])

  const handleCreateCategory = () => {
    setModalType("Create")
    setModalData({ id: null, name: "", description: "", order: 0 })
    setShowModal(true)
  }

  const handleEditCategory = (category) => {
    setModalType("Edit")
    setModalData(category)
    setShowModal(true)
  }

  const handleDeleteCategory = (category) => {
    setModalType("Delete")
    setModalData(category)
    setShowModal(true)
  }

  const handleModalSubmit = async () => {
    const token = localStorage.getItem("accessToken")
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    }

    try {
      let response
      if (modalType === "Create") {
        response = await fetch(
          "https://kalerpotro.pythonanywhere.com/api/news/category/",
          {
            method: "POST",
            headers,
            body: JSON.stringify({
              name: modalData.name,
              description: modalData.description,
              order: modalData.order,
            }),
          }
        )
      } else if (modalType === "Edit") {
        response = await fetch(
          `https://kalerpotro.pythonanywhere.com/api/news/category/${modalData.id}/`,
          {
            method: "PATCH",
            headers,
            body: JSON.stringify({
              name: modalData.name,
              description: modalData.description,
              order: modalData.order,
            }),
          }
        )
      } else if (modalType === "Delete") {
        response = await fetch(
          `https://kalerpotro.pythonanywhere.com/api/news/category/${modalData.id}/`,
          {
            method: "DELETE",
            headers,
          }
        )
      }

      if (!response.ok) {
        throw new Error(`Failed to ${modalType.toLowerCase()} category`)
      }

      toast.success(`Category ${modalType.toLowerCase()}d successfully`)
    } catch (error) {
      toast.error(`Error ${modalType.toLowerCase()}ing category`)
    }

    setShowModal(false)
    fetchCategories()
  }

  const handleSearch = (event) => {
    const value = event.target.value;
    setSearch(value);


    setCurrentPage(1);
  };


  const filteredCategories = categories.filter((category) =>
    category.name.toLowerCase().includes(search.toLowerCase())
  )

  const indexOfLastCategory = currentPage * categoriesPerPage
  const indexOfFirstCategory = indexOfLastCategory - categoriesPerPage
  const currentCategories = filteredCategories.slice(
    indexOfFirstCategory,
    indexOfLastCategory
  )

  const totalPages = Math.ceil(filteredCategories.length / categoriesPerPage)

  const paginate = (pageNumber) => setCurrentPage(pageNumber)


  const getTotalNewsCount = (categoryId) => {
    return news.filter((newsItem) => newsItem.category === categoryId).length
  }

  const getPendingNewsCount = (categoryId) => {
    return news.filter(
      (newsItem) => newsItem.category === categoryId && !newsItem.is_active
    ).length
  }

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
      onHeaderCell: () => ({
        style: {
          backgroundColor: "#DC2626",
          color: "#fff",
        },
      }),
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      sorter: (a, b) => a.description.localeCompare(b.description),
      onHeaderCell: () => ({
        style: {
          backgroundColor: "#DC2626",
          color: "#fff",
        },
      }),
    },
    {
      title: "Order",
      dataIndex: "order",
      key: "order",
      sorter: (a, b) => a.order - b.order,
      render: (text) => (text === 0 ? "Not Assigned" : text),
      onHeaderCell: () => ({
        style: {
          backgroundColor: "#DC2626",
          color: "#fff",
        },
      }),
    },
    {
      title: "Total News",
      key: "totalNews",
      render: (text, record) => getTotalNewsCount(record.id),
      onHeaderCell: () => ({
        style: {
          backgroundColor: "#DC2626",
          color: "#fff",
        },
      }),
    },
    {
      title: "Pending",
      key: "pendingNews",
      render: (text, record) => getPendingNewsCount(record.id),
      onHeaderCell: () => ({
        style: {
          backgroundColor: "#DC2626",
          color: "#fff",
        },
      }),
    },
    {
      title: "Actions",
      key: "actions",
      render: (text, record) => (
        <>
          <button
              onClick={() => handleEditCategory(record)}
              className="hover:bg-[#DC2626] hover:text-white p-3 rounded-full mr-2 text-[#DC2626] text-xl"
            >
              <AiOutlineEdit />
            </button>
            <button onClick={() => handleDeleteCategory(record)} className="hover:bg-[#DC2626] hover:text-white p-3 rounded-full text-[#DC2626] text-xl">
                <AiOutlineDelete />
              </button>
        </>
      ),
      onHeaderCell: () => ({
        style: {
          backgroundColor: "#DC2626",
          color: "#fff",
        },
      }),
    },
  ]

  return (
    <div>
      <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "20px",marginTop: "20px" }}>
        <Input
          placeholder="Search"
          value={search}
          onChange={handleSearch}
          style={{ width: 200 }}
          prefix={<SearchOutlined />}
        />
        <button className="bg-red-600 text-white px-4 py-2 rounded mr-2" onClick={handleCreateCategory}>
          Create Category
        </button>
      </div>
      <Table
        columns={columns}
        dataSource={currentCategories}
        rowKey="id"
        pagination={false}
        className="shadow-lg"
      />
      {/* <div className="w-full flex items-center justify-center mt-10">
      <Pagination
        current={currentPage}
        total={filteredCategories.length}
        pageSize={categoriesPerPage}
        onChange={paginate}
        showSizeChanger={false}
      />
      </div> */}
      <PaginationContainer>
        <ReactPaginate
          previousLabel={"Previous"}
          nextLabel={"Next"}
          pageCount={totalPages} 
          onPageChange={({ selected }) => paginate(selected + 1)}
          containerClassName={"pagination"}
          activeClassName={"active"}
          previousClassName={"previous"}
          nextClassName={"next"}
        />
      </PaginationContainer>
      <CreateCategoryModal
        show={showModal && modalType === "Create"}
        onClose={()=>setShowModal(false)}
        onSubmit={handleModalSubmit}
        data={modalData}
        setData={setModalData}
      />
      <EditCategoryModal
        show={showModal && modalType === "Edit"}
        onClose={() => setShowModal(false)}
        onSubmit={handleModalSubmit}
        data={modalData}
        setData={setModalData}
      />
      <DeleteCategoryModal
        show={showModal && modalType === "Delete"}
        onClose={() => setShowModal(false)}
        onSubmit={handleModalSubmit}
        data={modalData}
      />
    </div>
  )
}

export default Categories
