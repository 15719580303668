import styled from "styled-components"

export const Container = styled.div`
    margin-top: 1.4rem;

`

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
`

export const ActionButton = styled.button`
  padding: 16px 35.5px;
  background-color: #f8f9fb;
  color: #4e5159;
  border: 0.5px solid #647887;
  border-radius: 10px;
  cursor: pointer;
  font-size: 13px;
  margin: 0 0 0 auto;
  &:hover {
    box-shadow: 0px 4px 4px 0px #00000040;
  }

  @media (max-width: 768px) {
    padding: 10px 20px;
  }

  @media (max-width: 480px) {
    width: 100%;
    margin-right: 0;
    margin-bottom: 0.5rem;
  }
`

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: hidden;

  th,
  td {
    padding: 0.8rem 1rem;
    text-align: center;
    font-size: 14px;
  }

  td {
    padding: 0 1.2rem;
    font-size: 13px;
    border: none;
    margin: 8% 0;
  }

  th:first-child,
  td:first-child {
    text-align: left;
  }

  th:last-child,
  td:last-child {
    text-align: right;
  }

  td img {
    width: 100%;
    height: 100%;
    padding: 5px;
    object-fit: contain;
    cursor: pointer;
  }

  th {
    background-color: #dc2626;
    color: #fff;
    font-weight: normal;
  }

  tbody tr:nth-child(odd) {
    background-color: #fff;
  }

  tbody tr:nth-child(even) {
    background-color: #dc262608;
  }

  td {
    border-bottom: none;
  }

  td:last-child {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  @media (max-width: 768px) {
    th,
    td {
      padding: 0.5rem 1rem;
    }
  }

  @media (max-width: 480px) {
    th,
    td {
      padding: 0.25rem 0.5rem;
    }
    th,
    td {
      display: block;
      text-align: right;
    }
    th {
      text-align: left;
    }
    td:last-child {
      justify-content: center;
    }
  }
`

export const TruncatedLink = styled.a`
  display: inline-block;
  max-width: 200px; /* Adjust as needed */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: bottom;
  text-align: left;
  &:hover {
    text-decoration: underline;
  }
`

export const Tooltip = styled.div`
  position: absolute;
  background: rgba(0, 0, 0, 0.75);
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  white-space: nowrap;
  z-index: 1000;
  transform: translateY(100%);
  pointer-events: none;
`

export const ActionContainer = styled.div`
  display: flex;
  gap: 10px;
  margin: auto 0;
`

export const IconWrapper = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  padding: 13px;
  box-sizing: content-box;
  background-color: transparent;
  transition: background-color 0.3s;
  border-radius: 55px;

  svg {
    color: #dc2626;
    cursor: pointer;
    transition: color 0.3s;
    width: 24px;
    height: 24px;
  }

  &:hover {
    background-color: #dc2626;
    svg {
      color: #fff;
      cursor: pointer;
      transition: color 0.3s;
    }
  }
`

export const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
  .pagination {
    display: flex;
    list-style: none;
    padding: 0;
  }
  .pagination li {
    margin: 0 5px;
    cursor: pointer;
    user-select: none;
    padding: 8px 12px;
    background: #f8f9fb;
    border-radius: 5px;
    color: #4e5159;
    border: 1px solid transparent;
  }
  .pagination li:hover {
    background: #dc2626;
    color: #fff;
  }
  .pagination .active {
    background: #dc2626;
    color: #fff;
  }
`
