import React from "react"
import { Modal, Tag, Space, Typography } from "antd"
import { format } from "date-fns"
import styled from "styled-components"
import { Table } from "antd"

const StyledTable = styled(Table)`
  .ant-table-thead > tr > th {
    background-color: #dc2626;
    color: #fff;
    font-weight: bold;
  }

  .ant-pagination-item-active a {
    color: #dc2626;
  }

  .ant-pagination-item a {
    color: #dc2626;
  }

  .ant-pagination-prev,
  .ant-pagination-next {
    color: #dc2626;
  }

  .ant-table-cell {
    max-width: 300px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`


const { Column } = StyledTable
const { Text } = Typography

const ViewTagsNewsModal = ({ visible, onCancel, newsList, viewingTag }) => {
  return (
    <Modal
      title={`News for Tag: ${viewingTag?.tag}`}
      visible={visible}
      onCancel={onCancel}
      footer={null}
      width={1200}
    >
      <StyledTable
        dataSource={newsList}
        rowKey="id"
        bordered
        pagination={{ pageSize: 10 }}
        scroll={{ x: "max-content" }}
      >
        <Column
          title="Headline"
          dataIndex="headline"
          key="headline"
          render={(text) => <Text style={{ display: "block" }}>{text}</Text>}
        />
        <Column
          title="Category"
          dataIndex="category_name"
          key="category_name"
        />
        <Column title="Editor" dataIndex="editor_name" key="editor_name" />
        <Column
          title="Location"
          dataIndex="custom_location"
          key="custom_location"
        />
        <Column title="Division" dataIndex="division" key="division" />
        <Column title="District" dataIndex="district" key="district" />
        <Column title="Upazila" dataIndex="upazila" key="upazila" />
        <Column
          title="Created At"
          dataIndex="created_at"
          key="created_at"
          render={(text) => format(new Date(text), "dd MMM yyyy")}
        />
        <Column
          title="Actions"
          key="actions"
          render={(text, record) => (
            <Space size="middle">
              {record.image && (
                <a
                  href={record.image}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Tag color="blue">Image</Tag>
                </a>
              )}
              {record.video && (
                <a
                  href={record.video}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Tag color="purple">Video</Tag>
                </a>
              )}
            </Space>
          )}
        />
      </StyledTable>
    </Modal>
  )
}

export default ViewTagsNewsModal



