import React, { useState } from "react"
import { Modal, Form, Input, Button } from "antd"
import styled from "styled-components"

const EditModal = styled(Modal)`
  .ant-modal-footer {
    display: flex;
    justify-content: space-between;
  }
`

const EditVideoModal = ({ visible, onClose, onSave, record }) => {
  const [headline, setHeadline] = useState(record?.headline || "")
  const [video, setVideo] = useState(record?.video || "")

  const handleSave = () => {
    onSave({ ...record, headline, video })
  }

  return (
    <EditModal
      visible={visible}
      title="Edit Video"
      onCancel={onClose}
      footer={[
        <Button key="cancel" onClick={onClose}>
          Cancel
        </Button>,
        <Button key="save" type="primary" onClick={handleSave}>
          Save
        </Button>,
      ]}
    >
      <Form layout="vertical">
        <Form.Item label="Headline">
          <Input value={headline} onChange={(e) => setHeadline(e.target.value)} />
        </Form.Item>
        <Form.Item label="Video URL">
          <Input value={video} onChange={(e) => setVideo(e.target.value)} />
        </Form.Item>
      </Form>
    </EditModal>
  )
}

export default EditVideoModal
