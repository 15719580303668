import { CloudinaryContext, Image, Transformation } from "cloudinary-react";
import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { FaTimes } from "react-icons/fa";
import Modal from "react-modal";
import { openUploadWidget } from "../CloudinaryService";
import {
  CloseButton,
  FormContainer,
  FormRow,
  FormSelect,
  Input,
  Label,
  ModalHeader,
  SaveButton,
  Title,
  UploadButton
} from "./AddAdModal.styled";

const ModalStyle = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "60%",
    maxHeight: "80vh",
    overflow: "auto",
    padding: "2.5rem 3rem",
    borderRadius: "12px",
    boxShadow: "0 0 20px rgba(0, 0, 0, 0.2)",
    backgroundColor: "#fff",
  },
};

Modal.setAppElement("#root");

const sectionOrderOptions = {
  NEWS_POST: { sections: [1], orders: [0, 1] },
  SEARCH_RESULT: { sections: [1], orders: [0, 1] },
  CATEGORY: { sections: [1], orders: [0, 1] },
  TRENDINGTAGS: { sections: [1], orders: [0, 1] },
  HOME: { sections: [1, 2, 5, 7, 8, 10], orders: [] },
};

const AddAdModal = ({ isOpen, onClose, onAdd }) => {
  const [adData, setAdData] = useState({
    page: "HOME",
    section: 1,
    image: "",
    link: "",
    video: "",
    height: "",
    width: "",
    order: 0, 
    file_type: ""
  });

  const [sections, setSections] = useState(sectionOrderOptions.HOME.sections);
  const [orders, setOrders] = useState([0]); 

  useEffect(() => {
    const options = sectionOrderOptions[adData.page];
    setSections(options.sections);
    setOrders([0]); 
  }, [adData.page]);

  useEffect(() => {
    const updateOrders = () => {
      if (adData.page === 'HOME') {
        switch (adData.section) {
          case '1':
            setOrders([0, 1]);
            break;
          case '2':
            setOrders([0, 1, 2, 3]);
            break;
          case '5':
            setOrders([0, 1, 2]);
            break;
          case '7':
            setOrders([0, 1, 2]);
            break;
          case '8':
            setOrders([0, 1]);
            break;
          case '10':
            setOrders([0, 1, 2]);
            break;
          default:
            setOrders([0, 1]); 
        }
      } else {
        setOrders([0, 1]); 
      }
    };

    updateOrders();
  }, [adData.page, adData.section]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setAdData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSectionChange = (e) => {
    const { value } = e.target;
    setAdData((prev) => ({
      ...prev,
      section: value,
      order: 0, 
    }));
  };

  const handleUpload = () => {
    openUploadWidget(
      {
        cloudName: process.env.REACT_APP_CLOUD_NAME,
        uploadPreset: process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET,
        sources: ["local", "url", "camera"],
      },
      (error, result) => {
        if (!error && result.event === "success") {
          setAdData((prev) => ({
            ...prev,
            image: result.info.secure_url,
          }));
          toast.success("Image uploaded successfully!");
        } else if (error) {
          toast.error("Image upload failed.");
        }
      }
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await onAdd(adData);
      toast.success("Ad added successfully!");
      onClose();
    } catch {
      toast.error("Failed to add ad.");
    }
  };

  return (
    <>
      <Modal isOpen={isOpen} onRequestClose={onClose} style={ModalStyle}>
        <ModalHeader>
          <Title>Add New Ad</Title>
          <CloseButton onClick={onClose}>
            <FaTimes />
          </CloseButton>
        </ModalHeader>
        <FormContainer onSubmit={handleSubmit}>
          <FormRow>
            <Label>
              Page
              <FormSelect name="page" value={adData.page} onChange={handleChange}>
                <option value="HOME">Home</option>
                <option value="SEARCH_RESULT">Search Result</option>
                <option value="NEWS_POST">Newspost</option>
                <option value="CATEGORY">Category</option>
                <option value="TRENDINGTAGS">Trendingtags</option>
              </FormSelect>
            </Label>
            <Label>
              Section
              <FormSelect name="section" value={adData.section} onChange={handleSectionChange}>
                {sections.map((section) => (
                  <option key={section} value={section}>{`Section ${section}`}</option>
                ))}
              </FormSelect>
            </Label>
          </FormRow>
          <FormRow>
            <Label>
              Order
              <FormSelect name="order" value={adData.order} onChange={handleChange}>
                {orders.map((order) => (
                  <option key={order} value={order}>{order === 0 ? "Not Assigned" : order}</option>
                ))}
              </FormSelect>
            </Label>
            <Label>
              Type
              <FormSelect name="file_type" value={adData.file_type} onChange={handleChange}>
                <option value="IMAGE">Image</option>
                <option value="VIDEO">Video</option>
              </FormSelect>
            </Label>
          </FormRow>
          <FormRow>
            <Label>
              Image
              <UploadButton type="button" onClick={handleUpload}>
                Upload Image
              </UploadButton>
              {adData.image && (
                <CloudinaryContext cloudName={process.env.REACT_APP_CLOUD_NAME}>
                  <Image publicId={adData.image} width="50" crop="scale">
                    <Transformation quality="auto" fetchFormat="auto" />
                  </Image>
                </CloudinaryContext>
              )}
            </Label>
            <Label>
              Link
              <Input
                type="url"
                name="link"
                value={adData.link}
                onChange={handleChange}
              />
            </Label>
          </FormRow>
          <FormRow>
            <Label>
              Video URL
              <Input
                type="url"
                name="video"
                value={adData.video}
                onChange={handleChange}
              />
            </Label>
            <Label>
              Height
              <Input
                type="text"
                name="height"
                value={adData.height}
                onChange={handleChange}
                required
              />
            </Label>
          </FormRow>
          <FormRow>
            <Label>
              Width
              <Input
                type="text"
                name="width"
                value={adData.width}
                onChange={handleChange}
                required
              />
            </Label>
          </FormRow>
          <SaveButton type="submit">Add Ad</SaveButton>
        </FormContainer>
      </Modal>
    </>
  );
};

export default AddAdModal;
