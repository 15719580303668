import React, { useState, useEffect } from "react"
import { useSelector } from "react-redux"
import { useLocation } from "react-router-dom"
import { LuUser } from "react-icons/lu"
import styled from "styled-components"
import EditProfileModal from "./UserProfileModals/EditProfileModal"
import ViewProfileModal from "./UserProfileModals/ViewProfileModal"
import UserDropdown from "./UserProfileModals/UserDropDown"

const Nav = styled.nav`
  margin-top: 1rem;
  height: 70px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background-color: #fffcfb;
  position: relative;
`

const UserInfoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  background: #fff;
  box-shadow: 0px 4px 4px 0px #00000040;

  padding: 4px 20px 4px 32px;
  color: #fff;
  margin-right: 1.1rem;
  border-radius: 0.3rem ;
  cursor: pointer;
  position: relative;
`

const UserIcon = styled(LuUser)`
  font-size: 1.25rem;
  margin-right: 0.5rem;
  width: 1.5rem;
  height: 1.5rem;
`

const UserDetails = styled.div`
  font-size: 12px;
  color: black;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  color: #000;
  font-weight: bold;
`

const ProfileImage = styled.img`
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 50%;
  object-fit: cover;
  border: 4px solid #ddd;
  box-sizing: content-box;
`

const Navbar = () => {
  const location = useLocation()
  const user = useSelector((state) => state.user)
  const [isEditModalOpen, setIsEditModalOpen] = useState(false)
  const [isViewModalOpen, setIsViewModalOpen] = useState(false)
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const [key, setKey] = useState(0)

  useEffect(() => {
    if (location.pathname === "/overview") {
      setKey((prevKey) => prevKey + 1)
    }
  }, [location.pathname])

  const handleToggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen)
  }

  const handleOpenEditModal = () => {
    setIsEditModalOpen(true)
    setIsDropdownOpen(false)
  }

  const handleCloseEditModal = () => {
    setIsEditModalOpen(false)
  }

  const handleOpenViewModal = () => {
    setIsViewModalOpen(true)
    setIsDropdownOpen(false)
  }

  const handleCloseViewModal = () => {
    setIsViewModalOpen(false)
  }

  const hiddenPaths = ["/login", "/reset", "/newpass/"]
  if (hiddenPaths.includes(location.pathname)) {
    return null
  }

  return (
    <>
      <Nav key={key}>
        <UserInfoContainer onClick={handleToggleDropdown}>
          <UserDetails>
            <div style={{ textAlign: "end", fontWeight:"lighter" }}>
              {user ? `${user.first_name} ${user.last_name}` : "Guest"}
            </div>
            <div>{user ? user.email : ""}</div>
          </UserDetails>
          {user?.profile_picture ? (
            <ProfileImage src={user.profile_picture} alt="Profile" />
          ) : (
            <UserIcon />
          )}
          {isDropdownOpen && (
            <UserDropdown
              onViewProfile={handleOpenViewModal}
              onEditProfile={handleOpenEditModal}
            />
          )}
        </UserInfoContainer>
      </Nav>
      <EditProfileModal
        isOpen={isEditModalOpen}
        onRequestClose={handleCloseEditModal}
        user={user}
      />
      <ViewProfileModal
        isOpen={isViewModalOpen}
        onRequestClose={handleCloseViewModal}
      />
    </>
  )
}

export default Navbar
