import { Button, Modal } from 'antd';
import React from 'react';
import { FaTimes } from 'react-icons/fa';
import styled from 'styled-components';

const DeleteCategoryModal = ({ show, onClose, onSubmit, data }) => {
  return (
    <Modal
      visible={show}
      onCancel={onClose}
      footer={null}
      width={600}
      centered
      closeIcon={<FaTimes />}
      bodyStyle={{ padding: '2.5rem 3rem' }}
      style={{ borderRadius: '12px' }}
    >
      <Header>
        <Title>Delete Confirmation</Title>
      </Header>
      <Body>
        <Message>
          Are you sure you want to delete the category '{data.name}'?
        </Message>
        <ButtonContainer>
          <DeleteButton onClick={onSubmit}>Delete</DeleteButton>
          <CancelButton onClick={onClose}>Cancel</CancelButton>
        </ButtonContainer>
      </Body>
    </Modal>
  );
};

export default DeleteCategoryModal;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Title = styled.h2`
  margin: 0;
  font-size: 22px;
  color: #dc2626;
`;

const Body = styled.div`
  margin-top: 25px;
`;

const Message = styled.p`
  text-align: center;
  font-size: 18px;
  color: #333;
  margin: 2rem 0; /* Adjusted margin for better spacing */
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 15px;
`;

const DeleteButton = styled(Button)`
  flex: 1;
  background: #dc2626;
  color: #fff;
  border: none;
  font-size: 16px;
  cursor: pointer;
  transition: background 0.3s;

  &:hover {
    background: #e22e31;
  }
`;

const CancelButton = styled(Button)`
  flex: 1;
  background: #ddd;
  color: #333;
  border: none;
  font-size: 16px;
  cursor: pointer;
  transition: background 0.3s;

  &:hover {
    background: #ccc;
  }
`;
