import React, { useEffect, useState } from "react"
import { toast } from "react-hot-toast"
import {
  AiOutlineDelete,
  AiOutlineEdit,
  AiOutlineEye
} from "react-icons/ai"
import ReactPaginate from "react-paginate"
import {
  ActionButton,
  ActionContainer,
  Container,
  Header,
  IconWrapper,
  PaginationContainer,
  Table,
  Tooltip,
  TruncatedLink,
} from "./Ads.styled"
import AdImageHoverModal from "./Modals/AdImageHoverModal"
import AddAdModal from "./Modals/AddAdModal"
import DeleteAdModal from "./Modals/DeleteAdModal"
import EditAdModal from "./Modals/EditAdModal"
import ViewAdModal from "./Modals/ViewAdModal"

const ITEMS_PER_PAGE = 5

const Ads = () => {
  const [ads, setAds] = useState([])
  const [currentPage, setCurrentPage] = useState(0)
  const [isViewModalOpen, setIsViewModalOpen] = useState(false)
  const [isEditModalOpen, setIsEditModalOpen] = useState(false)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const [isImageModalOpen, setIsImageModalOpen] = useState(false)
  const [isAddModalOpen, setIsAddModalOpen] = useState(false)
  const [selectedAd, setSelectedAd] = useState(null)
  const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 })
  const [isTooltipVisible, setIsTooltipVisible] = useState(false)
  const [tooltipText, setTooltipText] = useState("")
  const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" })

  useEffect(() => {
    const fetchAds = async () => {
      try {
        const response = await fetch(
          "https://kalerpotro.pythonanywhere.com/api/ad/list/"
        )
        if (!response.ok) {
          throw new Error("Network response was not ok")
        }
        const data = await response.json()
        setAds(data)
      } catch (error) {
        console.error("Fetch error:", error)
      }
    }

    fetchAds()
  }, [])

  const handleEdit = (ad) => {
    setSelectedAd(ad)
    setIsEditModalOpen(true)
  }

  const handleAdUpdate = (updatedAd) => {
    setAds((prev) =>
      prev.map((ad) => (ad.id === updatedAd.id ? updatedAd : ad))
    )
  }

  const handleAddAd = async (newAd) => {
    const token = localStorage.getItem("accessToken")
    if (!token) {
      console.error("No auth token found")
      return
    }

    try {
      const response = await fetch(
        "https://kalerpotro.pythonanywhere.com/api/ad/",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(newAd),
        }
      )
      if (!response.ok) {
        throw new Error("Network response was not ok")
      }
      const addedAd = await response.json()
      setAds([...ads, addedAd])
    } catch (error) {
      console.error("Add ad error:", error)
    }
  }

  const handleViewAd = (ad) => {
    setSelectedAd(ad)
    setIsViewModalOpen(true)
  }

  const closeViewModal = () => {
    setIsViewModalOpen(false)
    setSelectedAd(null)
  }

  const closeEditModal = () => {
    setIsEditModalOpen(false)
    setSelectedAd(null)
  }

  const closeDeleteModal = () => {
    setIsDeleteModalOpen(false)
    setSelectedAd(null)
  }

  const closeImageModal = () => {
    setIsImageModalOpen(false)
    setSelectedAd(null)
  }

  const handleEditChange = (e) => {
    const { name, value } = e.target
    setSelectedAd((prev) => ({
      ...prev,
      [name]: value,
    }))
  }

  const handleSave = async (e) => {
    e.preventDefault()

    const token = localStorage.getItem("accessToken")
    if (!token) {
      console.error("No auth token found")
      return
    }

    try {
      const response = await fetch(
        `https://kalerpotro.pythonanywhere.com/api/ad/${selectedAd.id}/`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(selectedAd),
        }
      )
      if (!response.ok) {
        throw new Error("Network response was not ok")
      }
      const updatedAd = await response.json()

      setAds((prev) =>
        prev.map((ad) => (ad.id === updatedAd.id ? updatedAd : ad))
      )
      closeEditModal()
      return Promise.resolve(updatedAd)
    } catch (error) {
      console.error("Update error:", error)
      return Promise.reject()
    }
  }

  const handleSaveWithToast = (e) => {
    e.preventDefault()
    handleSave(e)
      .then((updatedAd) => {
        toast.success("Ad updated successfully!")
        handleAdUpdate(updatedAd)
      })
      .catch(() => toast.error("Failed to update ad."))
  }

  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected)
  }

  const handleImageHover = (event, ad) => {
    const { top, left, height } = event.target.getBoundingClientRect()
    setTooltipPosition({
      top: top + height + window.scrollY,
      left: left + window.scrollX,
    })
    setSelectedAd(ad)
    setIsImageModalOpen(true)
  }

  const handleLinkHover = (event, link) => {
    const { top, left, height } = event.target.getBoundingClientRect()
    setTooltipPosition({
      top: top + height + window.scrollY,
      left: left + window.scrollX,
    })
    setTooltipText(link)
    setIsTooltipVisible(true)
  }

  const handleLinkMouseLeave = () => {
    setIsTooltipVisible(false)
  }

  const handleDeleteAd = async () => {
    const token = localStorage.getItem("accessToken")
    if (!token) {
      console.error("No auth token found")
      return
    }

    try {
      const response = await fetch(
        `https://kalerpotro.pythonanywhere.com/api/ad/${selectedAd.id}/`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      if (!response.ok) {
        throw new Error("Network response was not ok")
      }
      setAds(ads.filter((ad) => ad.id !== selectedAd.id))
      closeDeleteModal()
    } catch (error) {
      console.error("Delete error:", error)
    }
  }

  const openDeleteModal = (ad) => {
    setSelectedAd(ad)
    setIsDeleteModalOpen(true)
  }

  const openAddModal = () => {
    setIsAddModalOpen(true)
  }

  const closeAddModal = () => {
    setIsAddModalOpen(false)
  }

  const sortedAds = React.useMemo(() => {
    let sortableAds = [...ads]
    if (sortConfig !== null) {
      sortableAds.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === "asc" ? -1 : 1
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === "asc" ? 1 : -1
        }
        return 0
      })
    }
    return sortableAds
  }, [ads, sortConfig])

  const requestSort = (key) => {
    let direction = "asc"
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === "asc"
    ) {
      direction = "desc"
    }
    setSortConfig({ key, direction })
  }

  const offset = currentPage * ITEMS_PER_PAGE
  const currentAds = sortedAds.slice(offset, offset + ITEMS_PER_PAGE)
  const pageCount = Math.ceil(ads.length / ITEMS_PER_PAGE)

  return (
    <Container>
      <Header>
        <ActionButton onClick={openAddModal}>Add Ad</ActionButton>
      </Header>
      <Table>
        <thead>
          <tr>
            <th onClick={() => requestSort("page")}>
              Page{" "}
              {sortConfig.key === "page"
                ? sortConfig.direction === "asc"
                  ? "▲"
                  : "▼"
                : ""}
            </th>
            <th>Section</th>
            <th>Order</th>
            <th>Image</th>
            <th>Link</th>
            <th>Video</th>
            <th>Size</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {currentAds.map((ad) => (
            <tr key={ad.id}>
              <td>{ad.page}</td>
              <td>{ad.section}</td>
              <td>{ad.order}</td>
              <td>
                {
                  ad?.image ? <div
                  style={{
                    width: "80px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <img
                    src={ad.image}
                    alt="Ad"
                    onMouseEnter={(event) => handleImageHover(event, ad)}
                    style={{ cursor: "pointer" }}
                  />
                </div>
                : 
                <span>❌</span>
                }
              </td>
              <td>
                {
                  ad.link ? <TruncatedLink
                  onMouseEnter={(event) => handleLinkHover(event, ad.link)}
                  onMouseLeave={handleLinkMouseLeave}
                >
                  {ad.link}
                </TruncatedLink>
                : 
                <span>❌</span>
                }
              </td>
              <td>{ad.video ? "✔️" : "❌"}</td>
              <td>{`${ad.width}x${ad.height}`}</td>
              <td>
                <ActionContainer>
                  <IconWrapper onClick={() => handleViewAd(ad)}>
                    <AiOutlineEye />
                  </IconWrapper>
                  <IconWrapper onClick={() => handleEdit(ad)}>
                    <AiOutlineEdit />
                  </IconWrapper>
                  <IconWrapper onClick={() => openDeleteModal(ad)}>
                    <AiOutlineDelete />
                  </IconWrapper>
                </ActionContainer>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <PaginationContainer>
        <ReactPaginate
          previousLabel={"Previous"}
          nextLabel={"Next"}
          breakLabel={"..."}
          pageCount={pageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={2}
          onPageChange={handlePageClick}
          containerClassName={"pagination"}
          activeClassName={"active"}
        />
      </PaginationContainer>
      <ViewAdModal
        isOpen={isViewModalOpen}
        onRequestClose={closeViewModal}
        ad={selectedAd}
      />
      <EditAdModal
        isOpen={isEditModalOpen}
        onClose={closeEditModal}
        ad={selectedAd}
        handleChange={handleEditChange}
        handleSave={handleSaveWithToast}
        handleAdUpdate={handleAdUpdate}
      />
      <DeleteAdModal
        isOpen={isDeleteModalOpen}
        onClose={closeDeleteModal}
        ad={selectedAd}
        onDelete={handleDeleteAd}
      />
      <AdImageHoverModal
        isOpen={isImageModalOpen}
        onClose={closeImageModal}
        position={tooltipPosition}
        imageSrc={selectedAd?.image}
      />
      <AddAdModal
        isOpen={isAddModalOpen}
        onClose={closeAddModal}
        onAdd={handleAddAd}
      />
      {isTooltipVisible && (
        <Tooltip style={tooltipPosition}>{tooltipText}</Tooltip>
      )}
    </Container>
  )
}

export default Ads
//original
