import React, { useCallback, useEffect, useState } from "react"
import toast from "react-hot-toast"
import { useDispatch } from "react-redux"
import { Outlet, useLocation, useNavigate } from "react-router-dom"
import styled from "styled-components"
import Navbar from "./components/Navbar"
import SidePanel from "./components/SidePanel"
import { clearUser } from "./store/authSlice"
import { persistor } from "./store/store"
import { clearUserDetails } from "./store/userSlice"

const AppContainer = styled.div`
  display: grid;
  grid-template-columns: 250px 1fr;
  grid-template-rows: 70px 1fr;
  height: 100vh;
  background-color: #fffcfb;
`

const SidePanelWrapper = styled.div`
  grid-row: 1 / span 2;
  grid-column: 1 / 2;
`

const NavbarWrapper = styled.div`
  grid-row: 1 / 2;
  grid-column: 2 / 3;
  background-color: #fffcfb;
`

const ContentWrapper = styled.div`
  grid-row: 2 / 3;
  grid-column: 2 / 3;
  padding: 16px;
`

const Layout = () => {
const navigate = useNavigate()
const location = useLocation()
const dispatch = useDispatch()
const [shouldLogout, setShouldLogout] = useState(false);

const handleLogout = useCallback(async () => {
  dispatch(clearUser());
  dispatch(clearUserDetails());
  localStorage.removeItem("accessToken");
  localStorage.removeItem("refreshToken");
  localStorage.removeItem("role");
  localStorage.removeItem("persist:auth");
  localStorage.removeItem("persist:user");
  await persistor.purge();
  navigate("/login");
  toast.success("Logged out successfully");
}, [dispatch, navigate]);

useEffect(() => {
  const intervalId = setInterval(() => {
    setShouldLogout(true);
  }, 7200000); 

  return () => clearInterval(intervalId);
}, []);

useEffect(() => {
  if (shouldLogout && localStorage.getItem("accessToken")) {
    handleLogout(); 
  }
}, [shouldLogout, handleLogout]);

if(location.pathname === '/email_verify' || location.pathname === '/login'){
    return <Outlet />
}

  return (
    <AppContainer>
      <SidePanelWrapper>
        <SidePanel />
      </SidePanelWrapper>
      <NavbarWrapper>
        <Navbar />
      </NavbarWrapper>
      <ContentWrapper>
        <Outlet />
      </ContentWrapper>
    </AppContainer>
  )
}

export default Layout
