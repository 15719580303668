import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

const DropdownMenu = styled.div`
  position: absolute;
  top: 100%;
  right: 0;
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 2rem 1.25rem;
  z-index: 10;
  width: 230px;
  animation: fadeIn 0.3s ease-in-out;

  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(-10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
`;

const DropdownItem = styled.div`
  padding: 0.75rem 0.8rem;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 500;
  color: #333;
  display: flex;
  align-items: center;
  border-radius: 14px;
  transition: background-color 0.2s, transform 0.2s;

  &:hover {
    background-color: #f7f7f7;
    transform: translateX(-5px);
  }

  &:not(:last-child) {
    margin-bottom: 0.5rem;
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: 0rem;
  right: 1.1rem;
  background: none;
  border: none;
  font-size: 2rem;
  cursor: pointer;
  color: #dc2626;

  &:hover {
    color: #666;
  }
`;

const UserDropdown = ({ onViewProfile, onEditProfile, onClose }) => {
  const navigate = useNavigate();

  const showChangePasswordModal = () => {
    navigate('/changepass');
  };

  return (
    <DropdownMenu>
      <CloseButton onClick={onClose}>&times;</CloseButton>
      <DropdownItem onClick={onViewProfile}>View Profile</DropdownItem>
      <DropdownItem onClick={onEditProfile}>Edit Profile</DropdownItem>
      <DropdownItem onClick={showChangePasswordModal}>Change Password</DropdownItem>
    </DropdownMenu>
  );
};

export default UserDropdown;
