import { UploadOutlined } from "@ant-design/icons"
import {
  Button,
  Input,
  Modal,
  Popconfirm,
  Select,
  Switch,
  Table,
  Tabs
} from "antd"
import axios from "axios"
import React, { useEffect, useState } from "react"
import toast from "react-hot-toast"
import { AiOutlineDelete, AiOutlineEdit } from "react-icons/ai"
import { openUploadWidget } from "../Banners/CloudinaryService"
const { TabPane } = Tabs
const { Option } = Select

const CompanyInfo = () => {
  const [aboutUs, setAboutUs] = useState([])
  const [contactUs, setContactUs] = useState([])
  const [loading, setLoading] = useState(true)
  const [isAboutModalVisible, setIsAboutModalVisible] = useState(false)
  const [isContactModalVisible, setIsContactModalVisible] = useState(false)
  const [isEditAboutModalVisible, setIsEditAboutModalVisible] = useState(false)
  const [isEditContactModalVisible, setIsEditContactModalVisible] =
    useState(false)
  const [isLogoModalVisible, setIsLogoModalVisible] = useState(false)
  const [isEditLogoModalVisible, setIsEditLogoModalVisible] = useState(false)
  const [logos, setLogos] = useState([])
  const [editLogo, setEditLogo] = useState({
    image: null,
    is_active: true,
    portal_type: "",
    width: "",
    height: "",
  })
  const [newLogo, setNewLogo] = useState({
    image: null,
    is_active: true,
    portal_type: "ADMIN_DASHBOARD",
    width: "",
    height: "",
  })
  const [newAbout, setNewAbout] = useState({ headline: "", details: "" })
  const [newContact, setNewContact] = useState({
    phone: "",
    email: "",
    address: "",
  })
  const [editAbout, setEditAbout] = useState(null)
  const [editContact, setEditContact] = useState(null)
  const [image, setImage] = useState(null)
  const getToken = () => localStorage.getItem("accessToken")

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [aboutUsData, contactUsData, logosData] = await Promise.all([
          axios.get(
            "https://kalerpotro.pythonanywhere.com/api/webinfo/about/list/"
          ),
          axios.get(
            "https://kalerpotro.pythonanywhere.com/api/webinfo/contact/list/"
          ),
          axios.get("https://kalerpotro.pythonanywhere.com/api/ad/logo/list/"),
        ])

        setAboutUs(aboutUsData.data)
        setContactUs(contactUsData.data)
        setLogos(logosData.data)
      } catch (error) {
        console.error(error)
      } finally {
        setLoading(false)
      }
    }

    fetchData()
  }, [])

  const handleAddAbout = async () => {
    try {
      const token = getToken()
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
      setLoading(true)
      const response = await axios.post(
        "https://kalerpotro.pythonanywhere.com/api/webinfo/about/",
        newAbout,
        config
      )
      toast.success("About Us added successfully")
      const { data } = await axios.get(
        "https://kalerpotro.pythonanywhere.com/api/webinfo/about/list/"
      )
      setAboutUs(data)
      setIsAboutModalVisible(false)
      setLoading(false)
      setNewAbout({ headline: "", details: "" })
    } catch (error) {
      console.error(error)
    }
  }

  const handleAddContact = async () => {
    try {
      const token = getToken()
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
      const response = await axios.post(
        "https://kalerpotro.pythonanywhere.com/api/webinfo/contact/",
        newContact,
        config
      )
      toast.success("Contact Us added successfully")
      const contactResponse = await axios.get(
        "https://kalerpotro.pythonanywhere.com/api/webinfo/contact/list/"
      )
      setContactUs(contactResponse.data)
      setIsContactModalVisible(false)
      setNewContact({ phone: "", email: "", address: "" })
    } catch (error) {
      console.error(error)
    }
  }

  const handleAddLogo = async (portal_type) => {
    try {
      const token = getToken()
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      }
      const formData = {
        image: image,
        is_active: newLogo.is_active,
        portal_type: portal_type,
        width: newLogo.width,
        height: newLogo.height,
      }

      console.log(formData)
      const response = await axios.post(
        "https://kalerpotro.pythonanywhere.com/api/ad/logo/",
        { ...formData },
        config
      )
      toast.success(`${portal_type} logo added successfully`)
      const { data } = await axios.get(
        "https://kalerpotro.pythonanywhere.com/api/ad/logo/list/"
      )
      setLogos(data)
      setIsLogoModalVisible(false)
      setNewLogo({
        image: null,
        is_active: true,
        portal_type: "",
        width: "",
        height: "",
      })
    } catch (error) {
      console.error(error)
    }
  }

  const handleEditAbout = async () => {
    try {
      const token = getToken()
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
      const response = await axios.patch(
        `https://kalerpotro.pythonanywhere.com/api/webinfo/about/${editAbout.id}/`,
        editAbout,
        config
      )
      toast.success("About Us updated successfully")
      setLoading(true)
      const { data } = await axios.get(
        "https://kalerpotro.pythonanywhere.com/api/webinfo/about/list/"
      )
      setAboutUs(data)
      setLoading(false)
      setIsEditAboutModalVisible(false)
      setEditAbout(null)
    } catch (error) {
      console.error(error)
    }
  }

  const handleEditContact = async () => {
    try {
      const token = getToken()
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
      const response = await axios.patch(
        `https://kalerpotro.pythonanywhere.com/api/webinfo/contact/${editContact.id}/`,
        editContact,
        config
      )
      toast.success("Contact Us updated successfully")
      const contactResponse = await axios.get(
        "https://kalerpotro.pythonanywhere.com/api/webinfo/contact/list/"
      )
      setContactUs(contactResponse.data)
      setIsEditContactModalVisible(false)
      setEditContact(null)
    } catch (error) {
      console.error(error)
    }
  }

  const handleEditLogo = async () => {
    try {
      const token = getToken()
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      }

      const formData = {
        image: editLogo.image,
        is_active: editLogo.is_active,
        portal_type: editLogo.portal_type,
        width: editLogo.width,
        height: editLogo.height,
      }
      const response = await axios.patch(
        `https://kalerpotro.pythonanywhere.com/api/ad/logo/${editLogo.id}/`,
        formData,
        config
      )
      toast.success("Logo updated successfully")
      const logosResponse = await axios.get(
        "https://kalerpotro.pythonanywhere.com/api/ad/logo/list/"
      )
      setLogos(logosResponse.data)
      setIsEditLogoModalVisible(false)
      // setEditLogo(null);
    } catch (error) {
      console.error(error)
    }
  }

  const handleDeleteAbout = async (id) => {
    try {
      const token = getToken()
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
      await axios.delete(
        `https://kalerpotro.pythonanywhere.com/api/webinfo/about/${id}/`,
        config
      )
      toast.success("About Us deleted successfully")
      const aboutResponse = await axios.get(
        "https://kalerpotro.pythonanywhere.com/api/webinfo/about/list/"
      )
      setAboutUs(aboutResponse.data)
    } catch (error) {
      console.error(error)
    }
  }

  const handleDeleteContact = async (id) => {
    try {
      const token = getToken()
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
      await axios.delete(
        `https://kalerpotro.pythonanywhere.com/api/webinfo/contact/${id}/`,
        config
      )
      toast.success("Contact Us deleted successfully")
      const contactResponse = await axios.get(
        "https://kalerpotro.pythonanywhere.com/api/webinfo/contact/list/"
      )
      setContactUs(contactResponse.data)
    } catch (error) {
      console.error(error)
    }
  }

  const handleDeleteLogo = async (id) => {
    try {
      const token = getToken()
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
      await axios.delete(
        `https://kalerpotro.pythonanywhere.com/api/ad/logo/${id}/`,
        config
      )
      toast.success("Logo deleted successfully")
      const logosResponse = await axios.get(
        "https://kalerpotro.pythonanywhere.com/api/ad/logo/list/"
      )
      setLogos(logosResponse.data)
    } catch (error) {
      console.error(error)
    }
  }

  const aboutColumns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      onHeaderCell: () => ({
        style: {
          backgroundColor: "#DC2626",
          color: "#fff",
        },
      }),
    },
    {
      title: "Headline",
      dataIndex: "headline",
      key: "headline",
      onHeaderCell: () => ({
        style: {
          backgroundColor: "#DC2626",
          color: "#fff",
        },
      }),
    },
    {
      title: "Details",
      dataIndex: "details",
      key: "details",
      render: (text) => `${text ? text.slice(0, 100) : ""}...`,
      onHeaderCell: () => ({
        style: {
          backgroundColor: "#DC2626",
          color: "#fff",
        },
      }),
    },
    {
      title: "Created",
      dataIndex: "created_at",
      key: "created_at",
      render: (text) => new Date(text).toLocaleDateString(),
      onHeaderCell: () => ({
        style: {
          backgroundColor: "#DC2626",
          color: "#fff",
        },
      }),
    },
    {
      title: "Updated",
      dataIndex: "updated_at",
      key: "updated_at",
      render: (text) => new Date(text).toLocaleDateString(),
      onHeaderCell: () => ({
        style: {
          backgroundColor: "#DC2626",
          color: "#fff",
        },
      }),
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <>
          <button
            onClick={() => {
              setEditAbout(record)
              setIsEditAboutModalVisible(true)
            }}
            className="hover:bg-[#DC2626] hover:text-white p-3 rounded-full mr-2 bg-transparent text-xl text-[#DC2626]"
          >
            <AiOutlineEdit />
          </button>
          <Popconfirm
            title="Are you sure to delete this item?"
            onConfirm={() => handleDeleteAbout(record.id)}
          >
            <button className="hover:bg-[#DC2626] hover:text-white p-3 rounded-full mr-2 bg-transparent text-xl text-[#DC2626]">
              <AiOutlineDelete />
            </button>
          </Popconfirm>
        </>
      ),
      onHeaderCell: () => ({
        style: {
          backgroundColor: "#DC2626",
          color: "#fff",
        },
      }),
    },
  ]

  const contactColumns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      onHeaderCell: () => ({
        style: {
          backgroundColor: "#DC2626",
          color: "#fff",
        },
      }),
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
      onHeaderCell: () => ({
        style: {
          backgroundColor: "#DC2626",
          color: "#fff",
        },
      }),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      onHeaderCell: () => ({
        style: {
          backgroundColor: "#DC2626",
          color: "#fff",
        },
      }),
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
      onHeaderCell: () => ({
        style: {
          backgroundColor: "#DC2626",
          color: "#fff",
        },
      }),
    },
    {
      title: "Created",
      dataIndex: "created_at",
      key: "created_at",
      render: (text) => new Date(text).toLocaleDateString(),
      onHeaderCell: () => ({
        style: {
          backgroundColor: "#DC2626",
          color: "#fff",
        },
      }),
    },
    {
      title: "Updated",
      dataIndex: "updated_at",
      key: "updated_at",
      render: (text) => new Date(text).toLocaleDateString(),
      onHeaderCell: () => ({
        style: {
          backgroundColor: "#DC2626",
          color: "#fff",
        },
      }),
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <>
          <button
            onClick={() => {
              setEditContact(record)
              setIsEditContactModalVisible(true)
            }}
            className="hover:bg-[#DC2626] hover:text-white p-3 rounded-full mr-2 bg-transparent text-xl text-[#DC2626]"
          >
            <AiOutlineEdit />
          </button>
          <Popconfirm
            title="Are you sure to delete this item?"
            onConfirm={() => handleDeleteContact(record.id)}
          >
            <button className="hover:bg-[#DC2626] hover:text-white p-3 rounded-full mr-2 bg-transparent text-xl text-[#DC2626]">
              <AiOutlineDelete />
            </button>
          </Popconfirm>
        </>
      ),
      onHeaderCell: () => ({
        style: {
          backgroundColor: "#DC2626",
          color: "#fff",
        },
      }),
    },
  ]

  const logoColumns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      onHeaderCell: () => ({
        style: {
          backgroundColor: "#DC2626",
          color: "#fff",
        },
      }),
    },
    {
      title: "Image",
      dataIndex: "image",
      key: "image",
      render: (text) => <img src={text} alt="logo" style={{ width: "50px" }} />,
      onHeaderCell: () => ({
        style: {
          backgroundColor: "#DC2626",
          color: "#fff",
        },
      }),
    },
    {
      title: "Portal Type",
      dataIndex: "portal_type",
      key: "portal_type",
      onHeaderCell: () => ({
        style: {
          backgroundColor: "#DC2626",
          color: "#fff",
        },
      }),
    },
    {
      title: "Width",
      dataIndex: "width",
      key: "width",
      onHeaderCell: () => ({
        style: {
          backgroundColor: "#DC2626",
          color: "#fff",
        },
      }),
    },
    {
      title: "Height",
      dataIndex: "height",
      key: "height",
      onHeaderCell: () => ({
        style: {
          backgroundColor: "#DC2626",
          color: "#fff",
        },
      }),
    },
    {
      title: "Active",
      dataIndex: "is_active",
      key: "is_active",
      render: (text) => (text ? "Yes" : "No"),
      onHeaderCell: () => ({
        style: {
          backgroundColor: "#DC2626",
          color: "#fff",
        },
      }),
    },
    {
      title: "Created",
      dataIndex: "created_at",
      key: "created_at",
      render: (text) => new Date(text).toLocaleString(),
      onHeaderCell: () => ({
        style: {
          backgroundColor: "#DC2626",
          color: "#fff",
        },
      }),
    },
    {
      title: "Updated",
      dataIndex: "updated_at",
      key: "updated_at",
      render: (text) => new Date(text).toLocaleString(),
      onHeaderCell: () => ({
        style: {
          backgroundColor: "#DC2626",
          color: "#fff",
        },
      }),
    },
    {
      title: "Actions",
      key: "actions",
      render: (text, record) => (
        <>
          <button
            onClick={() => {
              setEditLogo(record)
              setIsEditLogoModalVisible(true)
            }}
            className="hover:bg-[#DC2626] hover:text-white p-3 rounded-full mr-2 bg-transparent text-xl text-[#DC2626]"
          >
            <AiOutlineEdit />
          </button>
          <Popconfirm
            title="Are you sure to delete this item?"
            onConfirm={() => handleDeleteLogo(record.id)}
          >
            <button className="hover:bg-[#DC2626] hover:text-white p-3 rounded-full mr-2 bg-transparent text-xl text-[#DC2626]">
              <AiOutlineDelete />
            </button>
          </Popconfirm>
        </>
      ),
      onHeaderCell: () => ({
        style: {
          backgroundColor: "#DC2626",
          color: "#fff",
        },
      }),
    },
  ]

  const handleUpload = () => {
    openUploadWidget(
      {
        cloudName: process.env.REACT_APP_CLOUD_NAME,
        uploadPreset: process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET,
        sources: ["local", "url", "camera"],
      },
      (error, result) => {
        if (!error && result.event === "success") {
          setImage(result.info.secure_url)
          toast.success("Image uploaded successfully!")
        } else if (error) {
          toast.error("Image upload failed.")
        }
      }
    )
  }

  const items = [
    {
      key: "ADMIN_DASHBOARD",
      label: "Add Admin Dashboard Logo",
      children: (
        <>
          <Input
            placeholder="Width"
            value={newLogo.width}
            onChange={(e) => setNewLogo({ ...newLogo, width: e.target.value })}
            className="mb-2"
          />
          <Input
            placeholder="Height"
            value={newLogo.height}
            onChange={(e) => setNewLogo({ ...newLogo, height: e.target.value })}
            className="mb-2"
          />
          <div className="mb-2">
            <span>Active: </span>
            <Switch
              checked={newLogo.is_active}
              onChange={(checked) =>
                setNewLogo({ ...newLogo, is_active: checked })
              }
            />
          </div>
        </>
      ),
    },
    {
      key: "NEWSPORTAL",
      label: "Add News Portal Logo",
      children: (
        <>
          <Input
            placeholder="Width"
            value={newLogo.width}
            onChange={(e) => setNewLogo({ ...newLogo, width: e.target.value })}
            className="mb-2"
          />
          <Input
            placeholder="Height"
            value={newLogo.height}
            onChange={(e) => setNewLogo({ ...newLogo, height: e.target.value })}
            className="mb-2"
          />
          <div className="mb-2">
            <span>Active: </span>
            <Switch
              checked={newLogo.is_active}
              onChange={(checked) =>
                setNewLogo({ ...newLogo, is_active: checked })
              }
            />
          </div>
        </>
      ),
    },
  ]
  return (
    <div className="p-4">
      <div className="mb-4 flex justify-end space-x-2">
        <button
          onClick={() => setIsAboutModalVisible(true)}
          className="bg-red-600 text-white px-3 rounded-md py-2 text-sm font-medium hover:bg-transparent border hover:border-red-600 hover:text-red-600"
        >
          Add About Us
        </button>
        <button
          onClick={() => setIsContactModalVisible(true)}
          className="bg-red-600 text-white px-3 rounded-md py-2 text-sm font-medium hover:bg-transparent border hover:border-red-600 hover:text-red-600"
        >
          Add Contact Us
        </button>
        <button
          onClick={() => setIsLogoModalVisible(true)}
          className="bg-red-600 text-white px-3 rounded-md py-2 text-sm font-medium hover:bg-transparent border hover:border-red-600 hover:text-red-600"
        >
          Add Logo
        </button>
      </div>
      <h2 className="text-lg font-semibold mt-8 mb-4">About Us</h2>
      <Table
        loading={loading}
        columns={aboutColumns}
        dataSource={aboutUs}
        rowKey="id"
        pagination={{ pageSize: 5 }}
         className="shadow-lg"
      />
      <h2 className="text-lg font-semibold mt-8 mb-4">Contact Us</h2>
      <Table
        loading={loading}
        columns={contactColumns}
        dataSource={contactUs}
        rowKey="id"
        pagination={{ pageSize: 5 }}
         className="shadow-lg"
      />
      <h2 className="text-xl font-bold mb-2">Logos</h2>
      <Table
        dataSource={logos}
        columns={logoColumns}
        rowKey="id"
        loading={loading}
         className="shadow-lg"
      />
      <Modal
        title="Add About Us"
        visible={isAboutModalVisible}
        onOk={handleAddAbout}
        onCancel={() => setIsAboutModalVisible(false)}
        okText="Add"
        cancelText="Cancel"
      >
        <Input
          placeholder="Headline"
          value={newAbout.headline}
          onChange={(e) =>
            setNewAbout({ ...newAbout, headline: e.target.value })
          }
          className="mb-2"
        />
        <Input.TextArea
          placeholder="Details"
          value={newAbout.details}
          onChange={(e) =>
            setNewAbout({ ...newAbout, details: e.target.value })
          }
          rows={4}
        />
      </Modal>
      <Modal
        title="Add Contact Us"
        visible={isContactModalVisible}
        onOk={handleAddContact}
        onCancel={() => setIsContactModalVisible(false)}
        okText="Add"
        cancelText="Cancel"
      >
        <Input
          placeholder="Phone"
          value={newContact.phone}
          onChange={(e) =>
            setNewContact({ ...newContact, phone: e.target.value })
          }
          className="mb-2"
        />
        <Input
          placeholder="Email"
          value={newContact.email}
          onChange={(e) =>
            setNewContact({ ...newContact, email: e.target.value })
          }
          className="mb-2"
        />
        <Input
          placeholder="Address"
          value={newContact.address}
          onChange={(e) =>
            setNewContact({ ...newContact, address: e.target.value })
          }
        />
      </Modal>
      <Modal
        title="Add Logo"
        visible={isLogoModalVisible}
        onOk={() => handleAddLogo(newLogo.portal_type)}
        onCancel={() => {
          setIsLogoModalVisible(false)
        }}
        okText="Add"
        cancelText="Cancel"
      >
        {image ? (
          <img src={image} alt="" className={`w-[120px] h-[50px]`} />
        ) : (
          <Button icon={<UploadOutlined />} onClick={handleUpload}>
            Select Logo
          </Button>
        )}
        <Tabs
          defaultActiveKey="ADMIN_DASHBOARD"
          items={items}
          className="w-full"
          onChange={(key) => {
            setNewLogo({ ...newLogo, portal_type: key })
            console.log(key)
          }}
        />
      </Modal>
      <Modal
        title="Edit About Us"
        visible={isEditAboutModalVisible}
        onOk={handleEditAbout}
        onCancel={() => setIsEditAboutModalVisible(false)}
        okText="Save"
        cancelText="Cancel"
      >
        <Input
          placeholder="Headline"
          value={editAbout?.headline}
          onChange={(e) =>
            setEditAbout({ ...editAbout, headline: e.target.value })
          }
          className="mb-2"
        />
        <Input.TextArea
          placeholder="Details"
          value={editAbout?.details}
          onChange={(e) =>
            setEditAbout({ ...editAbout, details: e.target.value })
          }
          rows={4}
        />
      </Modal>
      <Modal
        title="Edit Contact Us"
        visible={isEditContactModalVisible}
        onOk={handleEditContact}
        onCancel={() => setIsEditContactModalVisible(false)}
        okText="Save"
        cancelText="Cancel"
      >
        <Input
          placeholder="Phone"
          value={editContact?.phone}
          onChange={(e) =>
            setEditContact({ ...editContact, phone: e.target.value })
          }
          className="mb-2"
        />
        <Input
          placeholder="Email"
          value={editContact?.email}
          onChange={(e) =>
            setEditContact({ ...editContact, email: e.target.value })
          }
          className="mb-2"
        />
        <Input
          placeholder="Address"
          value={editContact?.address}
          onChange={(e) =>
            setEditContact({ ...editContact, address: e.target.value })
          }
        />
      </Modal>
      <Modal
        title="Edit Logo"
        open={isEditLogoModalVisible}
        onOk={() => handleEditLogo()}
        onCancel={() => setIsEditLogoModalVisible(false)}
        okText="Update"
        cancelText="Cancel"
      >
        <img src={editLogo?.image} alt="" className={`w-[120px] h-[50px]`} />
        <Tabs
          defaultActiveKey="1"
          onChange={(key) => setEditLogo({ ...editLogo, portal_type: key })}
        >
          {editLogo.portal_type === "NEWSPORTAL" ? (
            <TabPane tab="News Portal Logo" key="NEWSPORTAL"></TabPane>
          ) : (
            <TabPane tab="Admin Logo" key="ADMIN_DASHBOARD"></TabPane>
          )}
        </Tabs>
        <Input
          placeholder="Width"
          value={editLogo.width}
          onChange={(e) => setEditLogo({ ...editLogo, width: e.target.value })}
          className="mb-2"
        />
        <Input
          placeholder="Height"
          value={editLogo.height}
          onChange={(e) => setEditLogo({ ...editLogo, height: e.target.value })}
          className="mb-2"
        />
        <div className="mb-2">
          <span>Active: </span>
          <Switch
            // checked={editLogo.is_active}
            defaultChecked={editLogo.is_active}
            onChange={(checked) =>
              setEditLogo({ ...editLogo, is_active: checked })
            }
          />
        </div>
      </Modal>
    </div>
  )
}

export default CompanyInfo
