import { CloudinaryContext, Image, Transformation } from "cloudinary-react"
import React, { useEffect, useState } from "react"
import { toast } from "react-hot-toast"
import { FaTimes } from "react-icons/fa"
import Modal from "react-modal"
import styled from "styled-components"
import { openUploadWidget } from "../CloudinaryService"
import { FormSelect } from "./AddAdModal.styled"

const EditAdModal = ({ isOpen, onClose, ad, handleChange, handleSave }) => {
  const [sections, setSections] = useState([])
  const [orders, setOrders] = useState([])

  const sectionOptions = {
    NEWS_POST: ["1"],
    SEARCH_RESULT: ["1"],
    CATEGORY: ["1"],
    TRENDINGTAGS: ["1"],
    HOME: ["1", "2", "5", "7", "8", "10"],
  }

  const orderOptions = {
    HOME: {
      1: ["0", "1"],
      2: ["0", "1", "2", "3"],
      5: ["0", "1", "2"],
      7: ["0", "1", "2"],
      8: ["0", "1"],
      10: ["0", "1", "2"],
    },
    DEFAULT: ["0", "1"], 
  }

  useEffect(() => {
    if (ad && ad.page) {
      setSections(sectionOptions[ad.page] || [])
      if (ad.page === "HOME") {
        setOrders(orderOptions.HOME[ad.section] || [])
      } else {
        setOrders(orderOptions.DEFAULT)
      }
    }
  }, [ad])

  const handleSaveWithToast = async (e) => {
    e.preventDefault()
    try {
      await handleSave(e)
      toast.success("Ad updated successfully!")
    } catch {
      toast.error("Failed to update ad.")
    }
  }

  const handleUpload = () => {
    openUploadWidget(
      {
        cloudName: process.env.REACT_APP_CLOUD_NAME,
        uploadPreset: process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET,
        sources: ["local", "url", "camera"],
      },
      (error, result) => {
        if (!error && result.event === "success") {
          handleChange({
            target: {
              name: "image",
              value: result.info.secure_url,
            },
          })
          toast.success("Image uploaded successfully!")
        } else if (error) {
          toast.error("Image upload failed.")
        }
      }
    )
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      style={ModalStyle}
      contentLabel="Edit Ad Details"
    >
      <ModalHeader>
        <Title>Edit Ad Details</Title>
        <CloseButton onClick={onClose}>
          <FaTimes />
        </CloseButton>
      </ModalHeader>
      {ad && (
        <form onSubmit={handleSaveWithToast}>
          <FormRow>
            <Label>
              Page
              <Select name="page" value={ad.page} onChange={handleChange}>
                <option value="HOME">Home</option>
                <option value="SEARCH_RESULT">Search Result</option>
                <option value="NEWS_POST">Newspost</option>
                <option value="CATEGORY">Category</option>
                <option value="TRENDINGTAGS">Trendingtags</option>
              </Select>
            </Label>
            <Label>
              Link
              <Input
                type="text"
                name="link"
                placeholder="Link"
                value={ad.link}
                onChange={handleChange}
              />
            </Label>
          </FormRow>
          <FormRow>
            <Label>
              Width
              <Input
                type="number"
                name="width"
                placeholder="Width"
                value={ad.width}
                onChange={handleChange}
              />
            </Label>
            <Label>
              Height
              <Input
                type="number"
                name="height"
                placeholder="Height"
                value={ad.height}
                onChange={handleChange}
              />
            </Label>
          </FormRow>
          <FormRow>
            <Label>
              Image URL
              <UploadButton type="button" onClick={handleUpload}>
                Upload Image
              </UploadButton>
              {ad.image && (
                <CloudinaryContext cloudName={process.env.REACT_APP_CLOUD_NAME}>
                  <Image publicId={ad.image} width="50" crop="scale">
                    <Transformation quality="auto" fetchFormat="auto" />
                  </Image>
                </CloudinaryContext>
              )}
            </Label>
            <Label>
              Video URL
              <Input
                type="text"
                name="video"
                placeholder="Video URL"
                value={ad.video}
                onChange={handleChange}
              />
            </Label>
          </FormRow>
          <FormRow>
            <Label>
              Section
              <Select name="section" value={ad.section} onChange={handleChange}>
                {sections.map((section) => (
                  <option key={section} value={section}>
                    {section}
                  </option>
                ))}
              </Select>
            </Label>
            <Label>
              Order
              <Select name="order" value={ad.order} onChange={handleChange}>
                {orders.map((order) => (
                  <option key={order} value={order}>
                    {order === "0" ? "Not Assigned" : order}
                  </option>
                ))}
              </Select>
            </Label>
            <Label>
              Type
              <FormSelect name="file_type" value={ad.file_type} onChange={handleChange}>
                <option value="IMAGE">Image</option>
                <option value="VIDEO">Video</option>
              </FormSelect>
            </Label>
          </FormRow>
          <SaveButton type="submit">Save</SaveButton>
        </form>
      )}
    </Modal>
  )
}

export default EditAdModal


const ModalStyle = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "60%",
    padding: "2.5rem 3rem",
    borderRadius: "12px",
    boxShadow: "0 0 20px rgba(0, 0, 0, 0.2)",
    backgroundColor: "#fff",
  },
}

Modal.setAppElement("#root")

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const Title = styled.h2`
  margin: 0;
  font-size: 20px;
  color: #dc2626;
`

const CloseButton = styled.button`
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #dc2626;
  transition: color 0.3s ease;

  &:hover {
    color: #d00000;
  }
`

const FormRow = styled.div`
  display: flex;
  gap: 15px;
  margin-bottom: 1.25rem;
`

const Label = styled.label`
  flex: 1;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  color: #333;
`

const Select = styled.select`
  margin-top: 5px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 6px;
  font-size: 16px;
  transition: border-color 0.3s;
  outline: none;

  &:focus {
    border-color: #f33823;
  }
`

const Input = styled.input`
  margin-top: 5px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 6px;
  font-size: 16px;
  transition: border-color 0.3s;
  outline: none;

  &:focus {
    border-color: #f33823;
  }
`

const UploadButton = styled.button`
  padding: 10px 20px;
  background-color: #dc2626;
  color: #fff;
  border: none;
  border-radius: 6px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #dc262685;
  }
`

const SaveButton = styled.button`
  display: block;
  width: 100%;
  padding: 12px;
  background-color: #dc2626;
  color: #fff;
  border: none;
  border-radius: 6px;
  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #dc262685;
  }
`
