import { PlusOutlined } from "@ant-design/icons"
import { Button, Col, Form, Input, Modal, Row, Select, message } from "antd"
import JoditEditor from "jodit-react"
import React, { useEffect, useMemo, useState } from "react"
import { useSelector } from "react-redux"
import { districts, divisions, upazilas } from "../../../Utils/locationData"
import { openUploadWidget } from "../CloudinaryService"

const AddNewsPostModal = ({ isOpen, onRequestClose, setNewsPosts }) => {
  const [headline, setHeadline] = useState("")
  const [content, setContent] = useState("")
  const [category, setCategory] = useState("")
  const [categories, setCategories] = useState([])
  const [locationType, setLocationType] = useState("")
  const [division, setDivision] = useState("")
  const [district, setDistrict] = useState("")
  const [upozilla, setUpozilla] = useState("")
  const [country, setCountry] = useState("")
  const [newsAddress, setNewsAddress] = useState("")
  const [image, setImage] = useState("")
  const [video, setVideo] = useState("")
  const [photoEditor, setPhotoEditor] = useState("")
  const [tags, setTags] = useState([])
  const [selectedTags, setSelectedTags] = useState([])
  const [newTag, setNewTag] = useState("")
  const config = useMemo(() => ({ height: 500 }), [])

  const editor = useSelector((state) => state.auth.user?.username)
  const editorId = useSelector((state) => state.auth.user?.user_id)

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await fetch(
          "https://kalerpotro.pythonanywhere.com/api/news/category/list/"
        )
        const data = await response.json()
        setCategories(data)
      } catch (error) {
        console.error("Failed to fetch categories", error)
      }
    }

    const fetchTags = async () => {
      try {
        const response = await fetch(
          "https://kalerpotro.pythonanywhere.com/api/ad/trendingtags/list/"
        )
        const data = await response.json()
        const sortedTags = data.sort(
          (a, b) => new Date(b.updated_at) - new Date(a.updated_at)
        )
        setTags(sortedTags)
      } catch (error) {
        console.error("Failed to fetch tags", error)
      }
    }

    fetchCategories()
    fetchTags()
  }, [])

  const handleUpload = () => {
    openUploadWidget(
      {
        cloudName: process.env.REACT_APP_CLOUD_NAME,
        uploadPreset: process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET,
        sources: ["local", "url", "camera"],
      },
      (error, result) => {
        if (!error && result.event === "success") {
          setImage(result.info.secure_url)
          message.success("Image uploaded successfully!")
        } else if (error) {
          message.error("Image upload failed.")
        }
      }
    )
  }

  const handleSave = async () => {
    const newLocation = {
      location_type: locationType,
      division: division || "-1",
      district: district || "-1",
      upazila: upozilla || "-1",
      country: locationType === 'NATIONAL' ? 'বাংলাদেশ' : country,
      custom_location: newsAddress || "-1",
    }

    try {
      const accessToken = localStorage.getItem("accessToken")

      const locationResponse = await fetch(
        "https://kalerpotro.pythonanywhere.com/api/news/location/",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
          body: JSON.stringify(newLocation),
        }
      )

      if (!locationResponse.ok) {
        throw new Error("Failed to add location")
      }

      const locationsResponse = await fetch(
        "https://kalerpotro.pythonanywhere.com/api/news/location/list/",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      )

      if (!locationsResponse.ok) {
        throw new Error("Failed to fetch locations")
      }

      const locations = await locationsResponse.json()

      const addedLocation = locations.find(
        (loc) =>
          loc.location_type === newLocation.location_type &&
          (loc.division === newLocation.division ||
            newLocation.division === "-1") &&
          (loc.district === newLocation.district ||
            newLocation.district === "-1") &&
          (loc.upazila === newLocation.upazila ||
            newLocation.upazila === "-1") &&
          (loc.custom_location === newLocation.custom_location ||
            newLocation.custom_location === "-1") && (loc.country === newLocation.country ||
              newLocation.country === "-1")
      )

      if (!addedLocation) {
        throw new Error("Newly added location not found")
      }

      const userRole = localStorage.getItem("role")

      const newNewsPost = {
        headline,
        content,
        category,
        editor: editorId,
        location: addedLocation.id,
        image,
        video,
        photo_editor: photoEditor,
        trending_tags: selectedTags,
        is_active: userRole === "SUPER_ADMIN" || userRole === "ADMIN",
      }

      const newsResponse = await fetch(
        "https://kalerpotro.pythonanywhere.com/api/news/",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
          body: JSON.stringify(newNewsPost),
        }
      )

      if (!newsResponse.ok) {
        throw new Error("Failed to add news post")
      }

      const savedNewsPost = await newsResponse.json()
      message.success("News post added successfully!")
      const res = await fetch("https://kalerpotro.pythonanywhere.com/api/news/list/", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      });
      
      if (!res.ok) {
        throw new Error("Failed to fetch news list");
      }
      
      const updatedNews = await res.json(); // Await the json response
      // console.log(updatedNews)
      // Update the state with the new list of news posts
      setNewsPosts(updatedNews);
      onRequestClose(false)
    } catch (error) {
      message.error("Failed to add news post.")
      console.error(error)
    }
  }

  const handleCreateTag = async () => {
    const accessToken = localStorage.getItem("accessToken")

    try {
      const response = await fetch(
        "https://kalerpotro.pythonanywhere.com/api/ad/trendingtags/",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
          body: JSON.stringify({ tag: newTag }),
        }
      )

      if (!response.ok) {
        throw new Error("Failed to create tag")
      }

      const newTagData = await response.json()

      const fetchUpdatedTags = async () => {
        try {
          const updatedTagsResponse = await fetch(
            "https://kalerpotro.pythonanywhere.com/api/ad/trendingtags/list/"
          )
          const updatedTagsData = await updatedTagsResponse.json()
          const sortedTags = updatedTagsData.sort(
            (a, b) => new Date(b.updated_at) - new Date(a.updated_at)
          )
          setTags(sortedTags)
          console.log(sortedTags);
          
          console.log(newTag);
          const matchedTag = sortedTags.find(tag => tag.tag === newTag);

          setSelectedTags((prevSelected) => [...prevSelected, matchedTag.id])
        } catch (error) {
          console.error("Failed to fetch updated tags", error)
        }
      }

      await fetchUpdatedTags()
      setNewTag("")
      message.success("Tag created successfully!")
    } catch (error) {
      message.error("Failed to create tag.")
      console.error(error)
    }
  }

  return (
    <Modal
      title="Add New News Post"
      open={isOpen}
      onCancel={onRequestClose}
      footer={null}
      width="60%"
      centered
    >
      <Form layout="vertical" onFinish={handleSave}>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label="Headline" required>
              <Input
                value={headline}
                onChange={(e) => setHeadline(e.target.value)}
              />
            </Form.Item>
            <Form.Item label="Category" required>
              <Select
                value={category}
                onChange={(value) => setCategory(value)}
                placeholder="Select a category"
              >
                {categories.map((cat) => (
                  <Select.Option key={cat.id} value={cat.id}>
                    {cat.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label="Editor">
              <Input value={editor} disabled />
            </Form.Item>
            <Form.Item label="Location Type" required>
              <Select
                value={locationType}
                onChange={(value) => setLocationType(value)}
                placeholder="Select location type"
              >
                <Select.Option value="NATIONAL">National</Select.Option>
                <Select.Option value="INTERNATIONAL">
                  International
                </Select.Option>
              </Select>
            </Form.Item>
            {locationType === "NATIONAL" && (
              <>
                <Form.Item label="Division">
                  <Select
                    value={division}
                    onChange={(value) => {
                      setDivision(value)
                      setDistrict("")
                      setUpozilla("")
                    }}
                    placeholder="Select a division"
                  >
                    {divisions.map((div) => (
                      <Select.Option key={div} value={div}>
                        {div}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                {division && (
                  <Form.Item label="District">
                    <Select
                      value={district}
                      onChange={(value) => {
                        setDistrict(value)
                        setUpozilla("")
                      }}
                      placeholder="Select a district"
                    >
                      {districts[division]?.map((dist) => (
                        <Select.Option key={dist} value={dist}>
                          {dist}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                )}
                {district && (
                  <Form.Item label="Upazila">
                    <Select
                      value={upozilla}
                      onChange={(value) => setUpozilla(value)}
                      placeholder="Select an upazila"
                    >
                      {upazilas[district]?.map((upa) => (
                        <Select.Option key={upa} value={upa}>
                          {upa}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                )}
              </>
            )}
            {locationType === "INTERNATIONAL" && (
              <>
              <Form.Item label="Country" required>
                <Input
                  value={country}
                  onChange={(e) => setCountry(e.target.value)}
                />
              </Form.Item>
              <Form.Item label="News Address" required>
                <Input
                  value={newsAddress}
                  onChange={(e) => setNewsAddress(e.target.value)}
                />
              </Form.Item>
              </>
            )}
          </Col>
          <Col span={12}>
            <Form.Item label="Image" required>
              <Button
                icon={<PlusOutlined />}
                onClick={handleUpload}
                style={{ backgroundColor: "#dc2626", color: "#fff" }}
              >
                Upload Image
              </Button>
              {image && (
                <img
                  src={image}
                  alt="Uploaded"
                  style={{
                    width: "100px",
                    height: "100px",
                    objectFit: "cover",
                    marginTop: "10px",
                  }}
                />
              )}
            </Form.Item>
            <Form.Item label="Video">
              <Input value={video} onChange={(e) => setVideo(e.target.value)} />
            </Form.Item>
            <Form.Item label="Photo Text">
              <Input
                value={photoEditor}
                onChange={(e) => setPhotoEditor(e.target.value)}
              />
            </Form.Item>
            <Form.Item label="Trending Tags">
              <Select
                mode="multiple"
                value={selectedTags}
                onChange={(value) => setSelectedTags(value)}
                placeholder="Select tags"
              >
                {tags.map((tag) => (
                  <Select.Option key={tag.id} value={tag.id}>
                    {tag.tag}
                  </Select.Option>
                ))}
              </Select>

              <Input
                placeholder="Create new tag"
                value={newTag}
                onChange={(e) => setNewTag(e.target.value)}
                style={{ marginTop: "10px" }}
              />
              <Button
                onClick={handleCreateTag}
                type="primary"
                style={{
                  marginTop: "10px",
                  backgroundColor: "#dc2626",
                  borderColor: "#dc2626",
                }}
              >
                Create Tag
              </Button>
            </Form.Item>
          </Col>
        </Row>
        <Form.Item label="Content" required>
          <JoditEditor
            value={content}
            onChange={(newContent) => setContent(newContent)}
            config={config}
          />
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            style={{
              backgroundColor: "#dc2626",
              borderColor: "#dc2626",
              width: "100%",
            }}
          >
            Save
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default AddNewsPostModal
