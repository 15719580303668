import React from "react";
import { FaTimes } from "react-icons/fa";
import Modal from "react-modal";
import styled from "styled-components";

const ViewAdModal = ({ isOpen, onRequestClose, ad }) => {
  if (!ad) return null;

  return (
    <StyledModal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="View Ad Details"
    >
      <ModalHeader>
        <Title>Ad Details</Title>
        <CloseButton onClick={onRequestClose}>
          <FaTimes />
        </CloseButton>
      </ModalHeader>
      <ModalContent>
        <Section>
          <DetailsTable>
            <tbody>
              {renderDetailRow("Page", ad.page)}
              {renderDetailRow("Section", ad.section)}
              {renderDetailRow("Order", ad.order)}
              {renderDetailRow("Image", ad.image ? <img src={ad.image} alt="Ad" style={{ width: '100%' }} /> : <span>No</span>)}
              {renderDetailRow("Link", <a href={ad.link} target="_blank" rel="noopener noreferrer">{ad.link}</a>)}
              {renderDetailRow("Video", ad.video ? "Yes" : "No")}
              {renderDetailRow("Size", `${ad.width}x${ad.height}`)}
            </tbody>
          </DetailsTable>
        </Section>
      </ModalContent>
    </StyledModal>
  );
};

const renderDetailRow = (label, value) => (
  <DetailRow key={label}>
    <DetailLabel>{label}:</DetailLabel>
    <DetailValue>{value}</DetailValue>
  </DetailRow>
);

const StyledModal = styled(Modal)`
  &.ReactModal__Content {
    top: 50%;
    left: 50%;
    right: 50%;
    bottom: 50%;
    margin-right: -50%;
    transform: translate(40%, 10%);
    width: 60%;
    max-width: 800px;
    padding: 2.5rem 3rem;
    border-radius: 12px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    background-color: #fff;
    color: #343a40;
    transition: all 0.3s ease;
    max-height: 80vh;
    overflow-y: auto;

    @media (max-width: 768px) {
      width: 90%;
      transform: translate(7%, 10%);
      padding: 20px;
    }

    @media (max-width: 480px) {
      width: 95%;
      transform: translate(3%, 10%);
      padding: 15px;
    }
  }
`;

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 15px;
`;

const Title = styled.h2`
  margin: 0;
  font-size: 20px;
  color: #dc2626;

  @media (max-width: 480px) {
    font-size: 20px;
  }
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #e63946;
  transition: color 0.3s ease;

  &:hover {
    color: #d00000;
  }

  @media (max-width: 480px) {
    font-size: 20px;
  }
`;

const ModalContent = styled.div`
  margin-top: 20px;

  @media (max-width: 480px) {
    margin-top: 15px;
  }
`;

const Section = styled.div`
  margin-bottom: 30px;

  @media (max-width: 480px) {
    margin-bottom: 20px;
  }
`;

const DetailsTable = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const DetailRow = styled.tr`
  &:nth-child(even) {
    background-color: #dc262608;
  }
`;

const DetailLabel = styled.td`
  font-weight: 600;
  padding: 12px 15px;
  width: 35%;
  color: #495057;

  @media (max-width: 480px) {
    padding: 10px 12px;
    font-size: 14px;
  }
`;

const DetailValue = styled.td`
  padding: 12px 15px;
  color: #495057;

  @media (max-width: 480px) {
    padding: 10px 12px;
    font-size: 14px;
  }
`;

export default ViewAdModal;
