import { TreeSelect } from "antd"
import JoditEditor from "jodit-react"
import React, { useEffect, useMemo, useState } from "react"
import { toast } from "react-hot-toast"
import { FaTimes } from "react-icons/fa"
import Modal from "react-modal"
import { districts, divisions, upazilas } from "../../../Utils/locationData"
import { openUploadWidget } from "../CloudinaryService"
import {
  ActionButton,
  CloseButton,
  FormContainer,
  FormRow,
  Header,
  Input,
  Label,
  ModalHeader,
  SaveButton,
  Select,
  Title,
} from "./EditNewsPostModal.styled"
const EditNewsPostModal = ({
  isOpen,
  onRequestClose,
  selectedPost,
  handleEditChange,
  handleSave,
  setNewsPosts,
  onCategoryChange,
}) => {
  const [tags, setTags] = useState([])
  const [selectedTags, setSelectedTags] = useState(selectedPost?.tags || [])

  const [categories, setCategories] = useState([])
  const [selectedDivision, setSelectedDivision] = useState(
    selectedPost?.division || ""
  )
  const [selectedDistrict, setSelectedDistrict] = useState(
    selectedPost?.district || ""
  )
  const [selectedUpazila, setSelectedUpazila] = useState(
    selectedPost?.upazila || ""
  )
  const [selectedCategory, setSelectedCategory] = useState(
    selectedPost?.category || ""
  )
  const [errors, setErrors] = useState({})
  const [editorContent, setEditorContent] = useState(
    selectedPost?.content || ""
  )
  const [customLocation, setCustomLocation] = useState(
    selectedPost?.custom_location || ""
  )

  const [originalCustomeLocation, setOriginalCustomeLocation] = useState(
    selectedPost?.custom_location
  )

  const [country, setCountry] = useState(
    selectedPost?.country
  )

  const config = useMemo(() => ({ height: 500 }), [])

  const handleCustomLocationChange = (e) => {
    const newCustomLocation = e.target.value
    setCustomLocation(newCustomLocation)
    handleEditChange(e)
  }

  const handleCategoryChange = (e) => {
    const selectedCategoryId = e.target.value
    const selectedCategory = categories.find(
      (category) => category.id === parseInt(selectedCategoryId)
    )

    if (selectedCategory) {
      setSelectedCategory(selectedCategory.id)
      handleEditChange({
        target: {
          name: "category",
          value: selectedCategory.id,
        },
      })
    } else {
      console.error("Selected category not found")
    }
  }

  useEffect(() => {
    const fetchTags = async () => {
      try {
        const response = await fetch(
          "https://kalerpotro.pythonanywhere.com/api/ad/trendingtags/list/"
        )
        const data = await response.json()
        setTags(data)
      } catch (error) {
        console.error("Failed to fetch tags", error)
      }
    }

    const fetchCategories = async () => {
      try {
        const response = await fetch(
          "https://kalerpotro.pythonanywhere.com/api/news/category/list/"
        )
        const data = await response.json()
        setCategories(data)
      } catch (error) {
        console.error("Failed to fetch categories", error)
      }
    }

    fetchTags()

    fetchCategories()
  }, [])

  useEffect(() => {
    if (selectedPost) {
      setSelectedTags(selectedPost.trending_tags || [])
      setSelectedDivision(selectedPost.division || "")
      setSelectedDistrict(selectedPost.district || "")
      setSelectedUpazila(selectedPost.upazila || "")
      setSelectedCategory(selectedPost.category || "")
      setEditorContent(selectedPost.content || "")
      setCustomLocation(selectedPost.custom_location || "")
      setCountry(selectedPost.country || "")
    }
  }, [selectedPost])

  const handleSaveWithToast = async (e) => {
    e.preventDefault();
     let hasErrors = false;
    const newErrors = {};
     if (selectedTags.length === 0) {
      hasErrors = true;
      newErrors.tags = "Tags are required.";
    }
     if (hasErrors) {
      setErrors(newErrors);
      toast.error("Please fill in all required fields.");
      return;
    }
     let locationType = "";
    let locationData = {
      location_type: "INTERNATIONAL",
      division: "-1",
      district: "-1",
      upazila: "-1",
      custom_location: "-1",
      country: "-1"
    };
    // console.log(selectedDivision,customLocation)
     if (selectedDivision !== "-1" || selectedDistrict !== "-1" || selectedUpazila !== "-1") {
      locationType = "NATIONAL";
      locationData = {
        ...locationData,
        location_type: locationType,
        division: selectedDivision,
        district: selectedDistrict ? selectedDistrict : "-1",
        upazila: selectedUpazila ? selectedUpazila : "-1",
        custom_location: "-1",
        country: "বাংলাদেশ"
      };
    } else if(customLocation !== "-1"){
      locationType = "INTERNATIONAL";
      locationData = {
        ...locationData,
        location_type: locationType,
        custom_location: customLocation,
        country: country
      };
    }
     const accessToken = localStorage.getItem("accessToken");
     try {
      const locationListResponse = await fetch(
        "https://kalerpotro.pythonanywhere.com/api/news/location/list/",
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
       if (!locationListResponse.ok) {
        throw new Error("Failed to fetch location list");
      }
       const locationList = await locationListResponse.json();
      let locationId = null;
       // Check if there's a matching location in the list
      if (locationType === "NATIONAL") {
        const matchedLocation = locationList.find(
          (loc) =>
            loc.upazila === locationData.upazila &&
            loc.district === locationData.district &&
            loc.division === locationData.division &&
            loc.country === locationData.country
        );
        locationId = matchedLocation?.id;
      } else if (locationType === "INTERNATIONAL") {
        const matchedLocation = locationList.find(
          (loc) =>
            loc.custom_location === customLocation &&
            loc.country === country
        );
        locationId = matchedLocation?.id;
      }
       // If no matching location, create a new one
      if (!locationId) {
        const locationResponse = await fetch(
          "https://kalerpotro.pythonanywhere.com/api/news/location/",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${accessToken}`,
            },
            body: JSON.stringify(locationData),
          }
        );
         if (!locationResponse.ok) {
          throw new Error("Failed to create location");
        }
         // Since the response only returns a success message, fetch the location list again
        const updatedLocationListResponse = await fetch(
          "https://kalerpotro.pythonanywhere.com/api/news/location/list/",
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
         if (!updatedLocationListResponse.ok) {
          throw new Error("Failed to fetch updated location list");
        }
         const updatedLocationList = await updatedLocationListResponse.json();
         if (locationType === "NATIONAL") {
          const newMatchedLocation = updatedLocationList.find(
            (loc) =>
              loc.upazila === locationData.upazila &&
              loc.district === locationData.district &&
              loc.division === locationData.division &&
              loc.country === locationData.country
          );
          locationId = newMatchedLocation?.id;
        }  if (locationType === "INTERNATIONAL") {
          const newMatchedLocation = updatedLocationList.find(
            (loc) =>
              loc.custom_location === customLocation &&
              loc.country === country
          );
          locationId = newMatchedLocation?.id;
        }
      }
       const postData = {
        headline: selectedPost.headline,
        content: editorContent,
        category: selectedCategory,
        editor: selectedPost.editor,
        custom_location: locationData.custom_location,
        location: locationId, // Ensure the correct location ID is used here
        trending_tags: selectedTags.map(Number),
        image: selectedPost.image,
        video: selectedPost.video,
        photo_editor: selectedPost.photo_editor,
        country: locationData.country,
      };
    //  return console.log(postData,locationType,selectedPost.custom_location,selectedDivision)
       const response = await fetch(
        `https://kalerpotro.pythonanywhere.com/api/news/${selectedPost.id}/`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
          body: JSON.stringify(postData),
        }
      );
       if (!response.ok) {
        throw new Error("Failed to update post");
      }
       const updatedPost = await response.json();
      toast.success("Post updated successfully!");
      onRequestClose();
      const res = await fetch("https://kalerpotro.pythonanywhere.com/api/news/list/", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      });
      
      if (!res.ok) {
        throw new Error("Failed to fetch news list");
      }
      
      const updatedNews = await res.json(); // Await the json response
      // console.log(updatedNews)
      // Update the state with the new list of news posts
      setNewsPosts(updatedNews);
      
    } catch (error) {
      console.error("Failed to update post", error);
      toast.error("Failed to update post.");
    }
  };
 
 

  const handleUpload = () => {
    openUploadWidget(
      {
        cloudName: process.env.REACT_APP_CLOUD_NAME,
        uploadPreset: process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET,
        sources: ["local", "url", "camera"],
      },
      (error, result) => {
        if (!error && result.event === "success") {
          handleEditChange({
            target: { name: "image", value: result.info.secure_url },
          })
          toast.success("Image uploaded successfully!")
        } else if (error) {
          toast.error("Image upload failed.")
        }
      }
    )
  }

  const getDisplayValue = (value) => (value === -1 ? "N/A" : value)

  const handleDivisionChange = (e) => {
    const newDivision = e.target.value
    setSelectedDivision(newDivision)
    setSelectedDistrict("")
    setSelectedUpazila("")
    handleEditChange(e)
  }

  const handleDistrictChange = (e) => {
    const newDistrict = e.target.value
    setSelectedDistrict(newDistrict)
    setSelectedUpazila("")
    handleEditChange(e)
  }

  const handleUpazilaChange = (e) => {
    const newUpazila = e.target.value
    setSelectedUpazila(newUpazila)
    handleEditChange(e)
  }

  const handleTagChange = (value) => {
    setSelectedTags(value)
  }

const handleCountryChange = (e) => {
  setCountry(e.target.value)
}

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={ModalStyle}
      contentLabel="Edit News Post Details"
    >
      <ModalHeader>
        <Title>Edit News Post Details</Title>
        <CloseButton onClick={onRequestClose}>
          <FaTimes />
        </CloseButton>
      </ModalHeader>
      {selectedPost && (
        <FormContainer>
          <form onSubmit={handleSaveWithToast}>
            <FormRow>
              <Label>
                <Header>Headline</Header>
                <Input
                  type="text"
                  name="headline"
                  placeholder="Headline"
                  value={selectedPost.headline}
                  onChange={handleEditChange}
                />
              </Label>
              <Label>
                <Header> Category</Header>
                <Select
                  name="category"
                  value={selectedCategory}
                  onChange={handleCategoryChange}
                >
                  <option value="">Select Category</option>
                  {categories.map((category) => (
                    <option key={category.id} value={category.id}>
                      {category.name}
                    </option>
                  ))}
                </Select>
              </Label>
            </FormRow>
            <FormRow>
              <Label>
                <Header>Editor Name</Header>
                <Input
                  type="text"
                  name="editor_name"
                  placeholder="Editor Name"
                  value={selectedPost.editor_name}
                  onChange={handleEditChange}
                  disabled
                />
              </Label>
              <Label>
                <Header>Video URL</Header>
                <Input
                  type="text"
                  name="video"
                  placeholder="Video URL"
                  value={selectedPost.video}
                  onChange={handleEditChange}
                />
              </Label>
            </FormRow>
            <FormRow>
              <Label>
                <Header>Image</Header>
                <Input
                  type="text"
                  name="image"
                  placeholder="Image URL"
                  value={selectedPost.image}
                  onChange={handleEditChange}
                />
                <ActionButton type="button" onClick={handleUpload}>
                  <Header> Upload Image</Header>
                </ActionButton>
                {selectedPost.image && (
                  <img
                    src={selectedPost.image}
                    alt="Uploaded"
                    style={{ marginTop: "1rem", maxWidth: "100%" }}
                  />
                )}
              </Label>
            </FormRow>
            {selectedPost.location_type === "NATIONAL" && (
              <FormRow>
                <Label>
                  <Header> Division</Header>
                  <Select
                    name="division"
                    value={selectedDivision}
                    onChange={handleDivisionChange}
                  >
                    <option value="">Select Division</option>
                    {divisions.map((division) => (
                      <option key={division} value={division}>
                        {division}
                      </option>
                    ))}
                  </Select>
                </Label>
                <Label>
                  <Header> District</Header>
                  <Select
                    name="district"
                    value={selectedDistrict}
                    onChange={handleDistrictChange}
                    disabled={!selectedDivision}
                  >
                    <option value="">Select District</option>
                    {selectedDivision &&
                      districts[selectedDivision]?.map((district) => (
                        <option key={district} value={district}>
                          {district}
                        </option>
                      ))}
                  </Select>
                </Label>
              </FormRow>
            )}
            <FormRow>
              {selectedPost.location_type === "NATIONAL" && (
                <Label>
                  <Header>Upazila</Header>
                  <Select
                    name="upazila"
                    value={selectedUpazila}
                    onChange={handleUpazilaChange}
                    disabled={!selectedDistrict}
                  >
                    <option value="">Select Upazila</option>
                    {selectedDistrict &&
                      upazilas[selectedDistrict]?.map((upazila) => (
                        <option key={upazila} value={upazila}>
                          {upazila}
                        </option>
                      ))}
                  </Select>
                </Label>
              )}
              {selectedPost.location_type === "INTERNATIONAL" && (
               <>
                <Label>
                  <Header> International Address</Header>
                  <Input
                    type="text"
                    name="custom_location"
                    placeholder="Custom Location"
                    value={customLocation}
                    onChange={handleCustomLocationChange}
                  />
                </Label>
                <Label>
                  <Header>Country</Header>
                  <Input
                    type="text"
                    name="country"
                    placeholder="Country"
                    value={country}
                    onChange={handleCountryChange}
                  />
                </Label>
               </>
              )}
            </FormRow>

            <FormRow>
              <Label>
                <Header>Photo Text</Header>
                <Input
                  type="text"
                  name="photo_editor"
                  placeholder="Photo Text"
                  value={selectedPost.photo_editor}
                  onChange={handleEditChange}
                />
              </Label>
            </FormRow>
            <FormRow>
              <Label>
                <span>
                  <Header>Tags</Header> <span style={{ color: "red" }}>*</span>
                </span>
                <TreeSelect
                  showSearch
                  style={{ width: "100%" }}
                  value={selectedTags}
                  dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                  placeholder="Please select"
                  allowClear
                  multiple
                  treeDefaultExpandAll
                  onChange={handleTagChange}
                >
                  {tags.map((tag) => (
                    <TreeSelect.TreeNode
                      key={tag.id}
                      value={tag.id}
                      title={tag.tag}
                    />
                  ))}
                </TreeSelect>
                {errors.tags && <p style={{ color: "red" }}>{errors.tags}</p>}
              </Label>
            </FormRow>
            <FormRow>
              <Label>
                <Header> Content</Header>
                <JoditEditor
                  value={editorContent}
                  onChange={(newContent) => setEditorContent(newContent)}
                  config={config}
                />
              </Label>
            </FormRow>
            <SaveButton type="submit">Save</SaveButton>
          </form>
        </FormContainer>
      )}
    </Modal>
  )
}

export default EditNewsPostModal

const ModalStyle = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "60%",
    maxHeight: "80vh",
    padding: "2.5rem 3rem",
    borderRadius: "12px",
    boxShadow: "0 0 20px rgba(0, 0, 0, 0.2)",
    backgroundColor: "#fff",
    overflowY: "auto",
  },
}

Modal.setAppElement("#root")
// original
