import { Table, Tooltip } from "antd";
import { format, parseISO } from "date-fns";
import React, { useEffect, useState } from "react";
import { AiOutlineDelete, AiOutlineEdit } from "react-icons/ai";
import ReactPaginate from "react-paginate";
import styled from "styled-components";
import { PaginationContainer } from "../NewsPostUpdated/NewsPostUpdated.styled";
import EditVideoModal from "./Modals/EditVideoModal";

const StyledTable = styled(Table)`
  .ant-table {
    border: none;
    margin-top: 1.4rem;
  }
  .ant-table-content {
    border: none;
  }
  .ant-table-thead > tr > th {
    background: #dc2626;
    border: none;
    color: #fff;
  }
  .ant-table-tbody > tr > td {
    border: none;
  }
`;

const ActionButton = styled.button`
  background-color: transparent;
  border: none;
  color: #dc2626;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 4px;

  &:hover {
    opacity: 0.8;
  }

  svg {
    font-size: 18px;
  }
`;

const WatchVideoButton = styled.a`
  background-color: #dc2626;
  color: #fff;
  font-weight: bold;
  padding: 8px 12px;
  border-radius: 4px;
  text-align: center;
  display: inline-block;
  text-decoration: none;

  &:hover {
    opacity: 0.8;
  }
`;

const HeadlineCell = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const VideoGallery = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [currentRecord, setCurrentRecord] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [categoriesPerPage] = useState(5);

  useEffect(() => {
    fetchNews();
  }, []);

  const fetchNews = async () => {
    try {
      const response = await fetch(
        "https://kalerpotro.pythonanywhere.com/api/news/list/"
      );
      const result = await response.json();
      const filteredData = result.filter((news) => news.video);

      const sortedData = filteredData.sort(
        (a, b) => new Date(b.updated_at) - new Date(a.updated_at)
      );
      const updatedData = sortedData.map((item, index) => ({
        ...item,
        showedOnVideoGallery: index < 7 ? "Yes" : "No",
      }));

      setData(updatedData);
    } catch (error) {
      console.error("Failed to fetch news:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleEdit = (record) => {
    setCurrentRecord(record);
    setEditModalVisible(true);
  };

  const handleSave = async (updatedRecord) => {
    const token = localStorage.getItem("accessToken");
    try {
      const response = await fetch(
        `https://kalerpotro.pythonanywhere.com/api/news/${updatedRecord.id}/`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            headline: updatedRecord.headline,
            video: updatedRecord.video,
          }),
        }
      );
      if (response.ok) {
        setData((prevData) =>
          prevData.map((item) =>
            item.id === updatedRecord.id ? updatedRecord : item
          )
        );
        setEditModalVisible(false);
        setCurrentRecord(null);
      } else {
        console.error("Failed to update news");
      }
    } catch (error) {
      console.error("Failed to update news:", error);
    }
  };

  const handleDelete = async (record) => {
    const token = localStorage.getItem("accessToken");
    try {
      const response = await fetch(
        `https://kalerpotro.pythonanywhere.com/api/news/${record.id}/`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            video: "",
          }),
        }
      );
      if (response.ok) {
        setData((prevData) => prevData.filter((item) => item.id !== record.id));
      } else {
        console.error("Failed to delete news");
      }
    } catch (error) {
      console.error("Failed to delete news:", error);
    }
  };

  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected + 1); 
  };

  const columns = [
    {
      title: "Headline",
      dataIndex: "headline",
      key: "headline",
      sorter: (a, b) => a.headline.localeCompare(b.headline),
      render: (text) => (
        <Tooltip title={text}>
          <HeadlineCell>{text}</HeadlineCell>
        </Tooltip>
      ),
    },
    {
      title: "Video",
      dataIndex: "video",
      key: "video",
      render: (text) => (
        <WatchVideoButton href={text} target="_blank" rel="noopener noreferrer">
          Watch
        </WatchVideoButton>
      ),
    },
    {
      title: "Date",
      dataIndex: "created_at",
      key: "created_at",
      render: (text) => format(parseISO(text), "dd MMM yy"),
      sorter: (a, b) => new Date(a.created_at) - new Date(b.created_at),
    },
    {
      title: "Updated",
      dataIndex: "updated_at",
      key: "updated_at",
      render: (text) => format(parseISO(text), "dd MMM yy"),
      sorter: (a, b) => new Date(a.updated_at) - new Date(b.updated_at),
      defaultSortOrder: "descend",
    },
    {
      title: "Updated at",
      dataIndex: "updated_at",
      key: "last_update_time",
      render: (text) => format(parseISO(text), "HH:mm:ss"),
      onHeaderCell: () => ({
        style: {
          backgroundColor: "#DC2626",
          color: "#fff",
        },
      }),
    },
    {
      title: "On Video Gallery",
      dataIndex: "showedOnVideoGallery",
      key: "showedOnVideoGallery",
      render: (text) => text,
    },
    {
      title: "Actions",
      key: "actions",
      render: (_, record) => (
        <div style={{ display: "flex", gap: "8px" }}>
          <button className="hover:bg-[#DC2626] hover:text-white p-3 rounded-full mr-2 text-[#DC2626] text-xl" onClick={() => handleEdit(record)}>
            <AiOutlineEdit />
          </button>
          <button className="hover:bg-[#DC2626] hover:text-white p-3 rounded-full mr-2 text-[#DC2626] text-xl" onClick={() => handleDelete(record)}>
            <AiOutlineDelete />
          </button>
        </div>
      ),
    },
  ];

  const indexOfLastCategory = currentPage * categoriesPerPage;
  const indexOfFirstCategory = indexOfLastCategory - categoriesPerPage;
  const currentCategories = data.slice(indexOfFirstCategory, indexOfLastCategory);

  return (
    <>
      <StyledTable
        columns={columns}
        dataSource={currentCategories}
        loading={loading}
        rowKey="id"
        pagination={false}
         className="shadow-lg"
      />
      <PaginationContainer>
        <ReactPaginate
          previousLabel={"Previous"}
          nextLabel={"Next"}
          pageCount={Math.ceil(data.length / categoriesPerPage)}
          onPageChange={handlePageChange}
          containerClassName={"pagination"}
          activeClassName={"active"}
          previousClassName={"previous"}
          nextClassName={"next"}
        />
      </PaginationContainer>
      {editModalVisible && (
        <EditVideoModal
          visible={editModalVisible}
          onClose={() => setEditModalVisible(false)}
          onSave={handleSave}
          record={currentRecord}
        />
      )}
    </>
  );
};

export default VideoGallery;
