import React, { useState } from "react"
import { Form, Input, Button, message } from "antd"
import { useNavigate, useLocation } from "react-router-dom"
import styled from "styled-components"
import axios from "axios"

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(4, 1fr);
  height: 100vh;
`

const Container = styled.div`
  display: flex;
  justify-content: start;
  align-items: start;
  grid-column: 2 / 5;
  grid-row: 2 / 3;
`

const FormWrapper = styled.div`
  width: 100%;
  max-width: 400px;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
`

const Title = styled.h2`
  text-align: center;
  color: #dc2626;
`

const StyledForm = styled(Form)`
  .ant-form-item-label > label {
    color: #dc2626;
  }
`

const StyledInput = styled(Input)`
  border-color: #dc2626;

  &:focus {
    border-color: #dc2626;
    box-shadow: 0 0 0 2px rgba(220, 38, 38, 0.2);
  }
`

const StyledButton = styled(Button)`
  background-color: #dc2626;
  border-color: #dc2626;
  width: 100%;

  &:hover,
  &:focus {
    background-color: #b71c1c;
    border-color: #b71c1c;
  }

  &:disabled {
    background-color: #f5c6c6;
    border-color: #f5c6c6;
  }
`

const BackButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  grid-column: 1 / 2;
  grid-row: 1 / 2;
  padding: 20px;
`

const BackButton = styled(Button)`
  background-color: #dc2626;
  border-color: #dc2626;
  color: white;

  &:hover,
  &:focus {
    background-color: #b71c1c;
    border-color: #b71c1c;
  }
`

const passwordRules = [
  { required: true, message: "Please input your password!" },
  { min: 8, message: "Password must be at least 8 characters long!" },
  {
    pattern: /[A-Z]/,
    message: "Password must contain at least one uppercase letter!",
  },
  {
    pattern: /[a-z]/,
    message: "Password must contain at least one lowercase letter!",
  },
  {
    pattern: /[0-9]/,
    message: "Password must contain at least one number!",
  },
  {
    pattern: /[^A-Za-z0-9]/,
    message: "Password must contain at least one special character!",
  },
]

function NewPassword() {
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  const query = new URLSearchParams(useLocation().search)
  const uid = query.get("uid")
  const token = query.get("token")

  const handleSubmit = async (values) => {
    setLoading(true)
    try {
      const response = await axios.post(
        "https://kalerpotro.pythonanywhere.com/api/auth_user/password/reset/confirm/",
        {
          new_password: values.newPassword,
          confirm_password: values.confirmPassword,
          uid: uid,
          token: token,
        }
      )

      if (response.status === 200) {
        message.success("Password reset successfully")
        navigate("/login")
      } else {
        message.error("An error occurred. Please try again.")
      }
    } catch (error) {
      message.error("An error occurred. Please try again.")
    } finally {
      setLoading(false)
    }
  }

  return (
    <GridContainer>
      <BackButtonContainer>
        <BackButton onClick={() => navigate("/login")}>Back to Home</BackButton>
      </BackButtonContainer>
      <Container>
        <FormWrapper>
          <Title>Reset Password</Title>
          <StyledForm
            name="new_password"
            onFinish={handleSubmit}
            layout="vertical"
          >
            <Form.Item
              label="New Password"
              name="newPassword"
              rules={passwordRules}
            >
              <StyledInput type="password" />
            </Form.Item>

            <Form.Item
              label="Confirm Password"
              name="confirmPassword"
              dependencies={["newPassword"]}
              rules={[
                ...passwordRules,
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("newPassword") === value) {
                      return Promise.resolve()
                    }
                    return Promise.reject(
                      new Error(
                        "The two passwords that you entered do not match!"
                      )
                    )
                  },
                }),
              ]}
            >
              <StyledInput type="password" />
            </Form.Item>

            <Form.Item>
              <StyledButton type="primary" htmlType="submit" loading={loading}>
                Reset Password
              </StyledButton>
            </Form.Item>
          </StyledForm>
        </FormWrapper>
      </Container>
    </GridContainer>
  )
}

export default NewPassword
