import React, { useEffect, useState } from "react"
import { Tooltip } from "react-tooltip"
import { useSelector } from "react-redux"
import {
  Card,
  CardRow,
  Container,
  EllipsisText,
  Table,
  PaginationContainer,
  PaginationButton,
  ActiveStatusText,
  FilterButton,
  SearchBar,
  CardRowFilter,
} from "./Overview.styled"

const Overview = () => {
  const [stats, setStats] = useState({
    todayPosts: 0,
    totalPosts: 0,
    totalShares: 0,
    totalEditors: 0,
    inactivePosts: 0,
  })
  const [userStats, setUserStats] = useState({
    todayPosts: 0,
    totalPosts: 0,
    totalShares: 0,
    inactivePosts: 0,
  })
  const [newsPosts, setNewsPosts] = useState([])
  const [categories, setCategories] = useState([])
  const [filteredNewsPosts, setFilteredNewsPosts] = useState([])
  const [sortConfig, setSortConfig] = useState({
    key: "date",
    direction: "desc",
  })
  const [currentPage, setCurrentPage] = useState(1)
  const [activeFilter, setActiveFilter] = useState(null)
  const [searchQuery, setSearchQuery] = useState("")
  const postsPerPage = 6

  const user = useSelector((state) => state.auth.user)
  const role = localStorage.getItem("role")

  useEffect(() => {
    const fetchData = async () => {
      try {
        const newsResponse = await fetch(
          "https://kalerpotro.pythonanywhere.com/api/news/list/"
        )
        const newsData = await newsResponse.json()

        const categoriesResponse = await fetch(
          "https://kalerpotro.pythonanywhere.com/api/news/category/list/"
        )
        const categoriesData = await categoriesResponse.json()

        setNewsPosts(newsData)
        setCategories(categoriesData)

        const today = new Date()
        const todayPosts = newsData.filter((post) => {
          const postDate = new Date(post.created_at)
          return (
            postDate.getDate() === today.getDate() &&
            postDate.getMonth() === today.getMonth() &&
            postDate.getFullYear() === today.getFullYear()
          )
        }).length

        const totalShares = newsData.reduce(
          (acc, post) => acc + post.share_count,
          0
        )

        const uniqueEditors = new Set(newsData.map((post) => post.editor)).size

        const inactivePosts = newsData.filter((post) => !post.is_active).length

        setStats({
          todayPosts,
          totalPosts: newsData.length,
          totalShares,
          totalEditors: uniqueEditors,
          inactivePosts,
        })

        if (role === "EDITOR" && user && user.user_id) {
          const userPosts = newsData.filter(
            (post) => post.editor === user.user_id
          )

          const userTodayPosts = userPosts.filter((post) => {
            const postDate = new Date(post.created_at)
            return (
              postDate.getDate() === today.getDate() &&
              postDate.getMonth() === today.getMonth() &&
              postDate.getFullYear() === today.getFullYear()
            )
          }).length

          const userTotalShares = userPosts.reduce(
            (acc, post) => acc + post.share_count,
            0
          )

          const userInactivePosts = userPosts.filter(
            (post) => !post.is_active
          ).length

          setUserStats({
            todayPosts: userTodayPosts,
            totalPosts: userPosts.length,
            totalShares: userTotalShares,
            inactivePosts: userInactivePosts,
          })
        }
      } catch (error) {
        console.error("Error fetching data:", error)
      }
    }

    fetchData()
  }, [role, user])

  useEffect(() => {
    let filteredPosts = newsPosts
    if (user && newsPosts.length > 0) {
      if (role === "EDITOR" && user.user_id) {
        filteredPosts = newsPosts.filter((post) => post.editor === user.user_id)
      }
    }

    if (activeFilter === true) {
      filteredPosts = filteredPosts.filter((post) => post.is_active)
    } else if (activeFilter === false) {
      filteredPosts = filteredPosts.filter((post) => !post.is_active)
    }

    if (searchQuery) {
      const lowercasedQuery = searchQuery.toLowerCase()
      filteredPosts = filteredPosts.filter(
        (post) =>
          post.headline.toLowerCase().includes(lowercasedQuery) ||
          post.content.toLowerCase().includes(lowercasedQuery) ||
          post.editor_name.toLowerCase().includes(lowercasedQuery) ||
          categories
            .find((cat) => cat.id === post.category)
            ?.name.toLowerCase()
            .includes(lowercasedQuery)
      )
    }

    setFilteredNewsPosts(filteredPosts)
  }, [user, newsPosts, role, activeFilter, searchQuery])

  const sortedNewsPosts = [...filteredNewsPosts].sort((a, b) => {
    let aValue = a[sortConfig.key]
    let bValue = b[sortConfig.key]

    if (sortConfig.key === "date" || sortConfig.key === "time") {
      aValue = a.created_at ? new Date(a.created_at) : new Date(0)
      bValue = b.created_at ? new Date(b.created_at) : new Date(0)
    } else if (typeof aValue === "string" && typeof bValue === "string") {
      return sortConfig.direction === "asc"
        ? aValue.localeCompare(bValue)
        : bValue.localeCompare(aValue)
    }

    if (aValue < bValue) return sortConfig.direction === "asc" ? -1 : 1
    if (aValue > bValue) return sortConfig.direction === "asc" ? 1 : -1
    return 0
  })

  const requestSort = (key) => {
    let direction = "asc"
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc"
    } else if (sortConfig.key === key && sortConfig.direction === "desc") {
      direction = "asc"
    } else {
      direction = "asc"
    }
    setSortConfig({ key, direction })
  }

  const getDirectionIcon = (key) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === "asc" ? "↑" : "↓"
    }
    return null
  }

  const indexOfLastPost = currentPage * postsPerPage
  const indexOfFirstPost = indexOfLastPost - postsPerPage
  const currentPosts = sortedNewsPosts.slice(indexOfFirstPost, indexOfLastPost)

  const paginate = (pageNumber) => setCurrentPage(pageNumber)

  const toggleActiveStatus = async (postId, currentStatus) => {
    const token = localStorage.getItem("accessToken")
    console.log("Toggling status for post ID:", postId)
    try {
      const response = await fetch(
        `https://kalerpotro.pythonanywhere.com/api/news/${postId}/`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ is_active: !currentStatus }),
        }
      )
      if (response.ok) {
        setNewsPosts((prevPosts) =>
          prevPosts.map((post) =>
            post.id === postId ? { ...post, is_active: !currentStatus } : post
          )
        )
      } else {
        console.error("Failed to update status")
      }
    } catch (error) {
      console.error("Error updating status:", error)
    }
  }

  const renderLocation = (location) => (location === "-1" ? "N/A" : location)

  return (
    <Container>
      <CardRow>
        {role === "EDITOR" ? (
          <>
            <Card>
              <h3>My Today's Posts</h3>
              <p>{userStats.todayPosts}</p>
            </Card>
            <Card>
              <h3>My Total Posts</h3>
              <p>{userStats.totalPosts}</p>
            </Card>
            <Card>
              <h3>My Total Shares</h3>
              <p>{userStats.totalShares}</p>
            </Card>
            <Card>
              <h3>My Pending Posts</h3>
              <p>{userStats.inactivePosts}</p>
            </Card>
          </>
        ) : (
          <>
            <Card>
              <h3>Today's Posts</h3>
              <p>{stats.todayPosts}</p>
            </Card>
            <Card>
              <h3>Total Posts</h3>
              <p>{stats.totalPosts}</p>
            </Card>
            <Card>
              <h3>Total Shares</h3>
              <p>{stats.totalShares}</p>
            </Card>
            <Card>
              <h3>Total Editors</h3>
              <p>{stats.totalEditors}</p>
            </Card>
            <Card>
              <h3>Pending Posts</h3>
              <p>{stats.inactivePosts}</p>
            </Card>
          </>
        )}
      </CardRow>

      <CardRowFilter>
        <SearchBar
          type="text"
          placeholder="Search by headline, content, editor, or category"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        <CardRow>
          {" "}
          <FilterButton
            active={activeFilter === true}
            onClick={() => setActiveFilter(true)}
          >
            Show Active
          </FilterButton>
          <FilterButton
            active={activeFilter === false}
            onClick={() => setActiveFilter(false)}
          >
            Show Pending
          </FilterButton>
          <FilterButton
            active={activeFilter === null}
            onClick={() => setActiveFilter(null)}
          >
            Reset Filter
          </FilterButton>
        </CardRow>
      </CardRowFilter>

      <Table>
        <thead>
          <tr>
            <th onClick={() => requestSort("headline")}>
              {getDirectionIcon("headline")} Headline
            </th>
            <th onClick={() => requestSort("photo_editor")}>
              {getDirectionIcon("photo_editor")}Editor
            </th>
            <th onClick={() => requestSort("date")}>
              {getDirectionIcon("date")}Date
            </th>
            <th onClick={() => requestSort("time")}>
              {getDirectionIcon("time")}Time
            </th>
            <th onClick={() => requestSort("category")}>
              {getDirectionIcon("category")}Category
            </th>
            <th onClick={() => requestSort("active")}>
              {getDirectionIcon("active")}Active
            </th>
            <th>Image</th>
            <th>Video</th>
            <th onClick={() => requestSort("share_count")}>
              {getDirectionIcon("share_count")}Shares
            </th>
            <th onClick={() => requestSort("division")}>
              {getDirectionIcon("division")}Division
            </th>
            <th onClick={() => requestSort("district")}>
              {getDirectionIcon("district")}District
            </th>
            <th onClick={() => requestSort("upazila")}>
              {getDirectionIcon("upazila")}Upazila
            </th>
            <th onClick={() => requestSort("custom_location")}>
              {getDirectionIcon("custom_location")}Country
            </th>
          </tr>
        </thead>
        <tbody>
          {currentPosts.map((post, index) => (
            <tr key={index}>
              <td
                data-tooltip-id={`tooltip-${index}`}
                data-tooltip-content={post.headline}
              >
                <EllipsisText>{post.headline}</EllipsisText>
                <Tooltip
                  id={`tooltip-${index}`}
                  place="right"
                  effect="solid"
                  opacity={100}
                  border={"1px solid black"}
                  float
                  style={{
                    backgroundColor: "#fff",
                    color: "#000",
                    borderRadius: "5px",
                    padding: "20px",
                    fontSize: "16px",
                  }}
                />
              </td>
              <td>{post.editor_name}</td>
              <td>
                {post.created_at
                  ? new Date(post.created_at).toLocaleDateString()
                  : "N/A"}
              </td>
              <td>
                {post.created_at
                  ? new Date(post.created_at).toLocaleTimeString()
                  : "N/A"}
              </td>
              <td>
                {categories.find((cat) => cat.id === post.category)?.name ||
                  "Unknown"}
              </td>
              <td
                onClick={
                  role === "ADMIN" || role === "SUPER_ADMIN"
                    ? () => toggleActiveStatus(post.id, post.is_active)
                    : null
                }
              >
                <ActiveStatusText isActive={post.is_active}>
                  {post.is_active ? "active" : "pending"}
                </ActiveStatusText>
              </td>
              <td>{post.image ? "✔️" : "❌"}</td>
              <td>{post.video ? "✔️" : "❌"}</td>
              <td>{post.share_count}</td>
              <td>{renderLocation(post.division)}</td>
              <td>{renderLocation(post.district)}</td>
              <td>{renderLocation(post.upazila)}</td>
              <td>{renderLocation(post.custom_location)}</td>
            </tr>
          ))}
        </tbody>
      </Table>

      <PaginationContainer>
        <PaginationButton
          onClick={() => paginate(currentPage - 1)}
          disabled={currentPage === 1}
        >
          Previous
        </PaginationButton>
        {[
          ...Array(Math.ceil(sortedNewsPosts.length / postsPerPage)).keys(),
        ].map((number) => (
          <PaginationButton
            key={number + 1}
            onClick={() => paginate(number + 1)}
            active={currentPage === number + 1}
          >
            {number + 1}
          </PaginationButton>
        ))}
        <PaginationButton
          onClick={() => paginate(currentPage + 1)}
          disabled={
            currentPage === Math.ceil(sortedNewsPosts.length / postsPerPage)
          }
        >
          Next
        </PaginationButton>
      </PaginationContainer>
    </Container>
  )
}

export default Overview
