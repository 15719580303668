import axios from "axios"
import React, { useEffect, useState } from "react"
import { FaTimes } from "react-icons/fa"
import Modal from "react-modal"
import styled from "styled-components"

const ViewNewsPostModal = ({ isOpen, onRequestClose, newsPost }) => {
  const [trendingTags, setTrendingTags] = useState([])

  useEffect(() => {
    const fetchTrendingTags = async () => {
      try {
        const response = await axios.get(
          "https://kalerpotro.pythonanywhere.com/api/ad/trendingtags/list/"
        )
        setTrendingTags(response.data)
      } catch (error) {
        console.error("Error fetching trending tags", error)
      }
    }

    fetchTrendingTags()
  }, [])

  if (!newsPost) return null

  const renderLocationDetails = () => {
    if (newsPost.location_type === "INTERNATIONAL") {
      return (
        <>
        {
          renderDetailRow(
            "Custom Location",
            newsPost.custom_location !== "-1" ? newsPost.custom_location : "N/A"
          )
        }
        {renderDetailRow(
            "Country",
            newsPost.country !== "" ? newsPost.country : "N/A"
          )}
        </>
      )
    } else if (newsPost.location_type === "NATIONAL") {
      return (
        <>
          {renderDetailRow(
            "Division",
            newsPost.division !== "-1" ? newsPost.division : "N/A"
          )}
          {renderDetailRow(
            "District",
            newsPost.district !== "-1" ? newsPost.district : "N/A"
          )}
          {renderDetailRow(
            "Upazila",
            newsPost.upazila !== "-1" ? newsPost.upazila : "N/A"
          )}
          {renderDetailRow(
            "Country",
            newsPost.country !== "" ? newsPost.country : "N/A"
          )}
        </>
      )
    }
    return null
  }

  const getTagNameById = (id) => {
    const tag = trendingTags.find((tag) => tag.id === id)
    return tag ? tag.tag : "Unknown Tag"
  }

  return (
    <StyledModal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="View News Post Details"
    >
      <ModalHeader>
        <Title>News Post Details</Title>
        <CloseButton onClick={onRequestClose}>
          <FaTimes />
        </CloseButton>
      </ModalHeader>
      <ModalContent>
        <Section>
          <DetailsTable>
            <tbody>
              {renderDetailRow("ID", newsPost.id)}
              {renderDetailRow("Headline", newsPost.headline)}
              {renderDetailRow(
                "Content",
                <ContentDiv
                  dangerouslySetInnerHTML={{ __html: newsPost.content }}
                />
              )}
              {renderDetailRow("Category", newsPost.category_name)}
              {renderDetailRow("Editor", newsPost.editor_name)}
              {renderDetailRow("Active", newsPost.is_active ? "Yes" : "No")}
              {renderDetailRow("Location Type", newsPost.location_type)}
              {renderLocationDetails()}
              {newsPost.image &&
                renderDetailRow(
                  "Image",
                  <img
                    src={newsPost.image}
                    alt="News Post"
                    style={{ width: "100%" }}
                  />
                )}
              {newsPost.video &&
                renderDetailRow(
                  "Video",
                  <VideoLink
                    href={newsPost.video}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {newsPost.video}
                  </VideoLink>
                )}
              {renderDetailRow("Share Count", newsPost.share_count)}
              {renderDetailRow("Photo Text", newsPost.photo_editor)}
              {renderDetailRow(
                "Created At",
                new Date(newsPost.created_at).toLocaleString()
              )}
              {renderDetailRow(
                "Updated At",
                new Date(newsPost.updated_at).toLocaleString()
              )}
              {renderDetailRow(
                "Trending Tags",
                newsPost.trending_tags
                  .map((id) => getTagNameById(id))
                  .join(", ")
              )}
            </tbody>
          </DetailsTable>
        </Section>
      </ModalContent>
    </StyledModal>
  )
}

const renderDetailRow = (label, value) => (
  <DetailRow key={label}>
    <DetailLabel>{label}:</DetailLabel>
    <DetailValue>{value}</DetailValue>
  </DetailRow>
)

const StyledModal = styled(Modal)`
  &.ReactModal__Content {
    top: 50%;
    left: 50%;
    right: 50%;
    bottom: 50%;
    margin-right: -50%;
    transform: translate(40%, 10%);
    width: 60%;
    max-width: 800px;
    padding: 2.5rem 3rem;
    border-radius: 12px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    background-color: #fff;
    color: #343a40;
    transition: all 0.3s ease;
    max-height: 80vh;
    overflow-y: auto;

    @media (max-width: 768px) {
      width: 90%;
      transform: translate(7%, 10%);
      padding: 20px;
    }

    @media (max-width: 480px) {
      width: 95%;
      transform: translate(3%, 10%);
      padding: 15px;
    }
  }
`

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 15px;
`

const Title = styled.h2`
  margin: 0;
  font-size: 20px;
  color: #dc2626;

  @media (max-width: 480px) {
    font-size: 20px;
  }
`

const CloseButton = styled.button`
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #e63946;
  transition: color 0.3s ease;

  &:hover {
    color: #d00000;
  }

  @media (max-width: 480px) {
    font-size: 20px;
  }
`

const ModalContent = styled.div`
  margin-top: 20px;

  @media (max-width: 480px) {
    margin-top: 15px;
  }
`

const Section = styled.div`
  margin-bottom: 30px;

  @media (max-width: 480px) {
    margin-bottom: 20px;
  }
`

const DetailsTable = styled.table`
  width: 100%;
  border-collapse: collapse;
`

const DetailRow = styled.tr`
  &:nth-child(even) {
    background-color: #dc262608;
  }
`

const DetailLabel = styled.td`
  font-weight: 600;
  padding: 12px 15px;
  width: 35%;
  color: #495057;

  @media (max-width: 480px) {
    padding: 10px 12px;
    font-size: 14px;
  }
`

const DetailValue = styled.td`
  padding: 12px 15px;
  color: #495057;

  @media (max-width: 480px) {
    padding: 10px 12px;
    font-size: 14px;
  }
`

const VideoLink = styled.a`
  color: #007bff;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`

const ContentDiv = styled.div`
  color: #495057;
  line-height: 1.5;

  p {
    margin: 0;
  }
`

export default ViewNewsPostModal
