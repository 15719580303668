import React from "react";
import { toast } from "react-hot-toast";
import { FaTimes } from "react-icons/fa";
import Modal from "react-modal";
import styled from "styled-components";
import { Select } from "../../NewsPostUpdated/Modals/EditNewsPostModal.styled";

const EditUserModal = ({
  isEditModalOpen,
  closeEditModal,
  selectedUser,
  handleEditChange,
  handleSave,
  handleUserUpdate,
}) => {
  const handleSaveWithToast = async (e) => {
    e.preventDefault();
    try {
      await handleSave(e);
      handleUserUpdate(selectedUser);
    } catch (error) {
      toast.error("Failed to update user.");
    }
  };

  return (
    <Modal
      isOpen={isEditModalOpen}
      onRequestClose={closeEditModal}
      style={ModalStyle}
      contentLabel="Edit User Details"
    >
      <ModalHeader>
        <Title>Edit User Details</Title>
        <CloseButton onClick={closeEditModal}>
          <FaTimes />
        </CloseButton>
      </ModalHeader>
      {selectedUser && (
        <form onSubmit={handleSaveWithToast}>
          <FormRow>
            <Label>
              Username
              <Input
                type="text"
                name="username"
                placeholder="Username"
                value={selectedUser.username}
                onChange={handleEditChange}
                required
                readOnly
              />
            </Label>
            <Label>
              First Name
              <Input
                type="text"
                name="first_name"
                placeholder="First Name"
                value={selectedUser.first_name}
                onChange={handleEditChange}
                required
              />
            </Label>
          </FormRow>
          <FormRow>
            <Label>
              Last Name
              <Input
                type="text"
                name="last_name"
                placeholder="Last Name"
                value={selectedUser.last_name}
                onChange={handleEditChange}
                required
              />
            </Label>
            <Label>
              Email
              <Input
                type="email"
                name="email"
                placeholder="Email"
                value={selectedUser.email}
                onChange={handleEditChange}
                required
                readOnly
              />
            </Label>
          </FormRow>
          <FormRow>
            <Label>
              Phone
              <Input
                type="text"
                name="phone"
                placeholder="Phone"
                value={selectedUser.phone}
                onChange={handleEditChange}
              />
            </Label>
            <Label>
              Address
              <Input
                type="text"
                name="address"
                placeholder="Address"
                value={selectedUser.address}
                onChange={handleEditChange}
              />
            </Label>
          </FormRow>
          <FormRow>
          <Label>
            Role
            <Select
              defaultValue={selectedUser.role}
              onChange={handleEditChange}
              required
              name="role"
            >
              <option value="ADMIN">Admin</option>
              <option value="EDITOR">Editor</option>
            </Select>
          </Label>
            <Label>
              Bio
              <Input
                type="text"
                name="bio"
                placeholder="Bio"
                value={selectedUser.bio}
                onChange={handleEditChange}
              />
            </Label>
          </FormRow>
          <SaveButton type="submit">Save</SaveButton>
        </form>
      )}
    </Modal>
  );
};

export default EditUserModal;

const ModalStyle = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "60%",
    padding: "2.5rem 3rem",
    borderRadius: "12px",
    boxShadow: "0 0 20px rgba(0, 0, 0, 0.2)",
    backgroundColor: "#fff",
  },
};

Modal.setAppElement("#root");

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Title = styled.h2`
  margin: 0;
  font-size: 20px;
  color: #dc2626;
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #dc2626;
  transition: color 0.3s ease;

  &:hover {
    color: #d00000;
  }
`;

const FormRow = styled.div`
  display: flex;
  gap: 15px;
  margin-bottom: 1.25rem;
`;

const Label = styled.label`
  flex: 1;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  color: #333;
`;

const Input = styled.input`
  margin-top: 5px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 6px;
  font-size: 16px;
  transition: border-color 0.3s;
  outline: none;

  &:focus {
    border-color: #f33823;
  }

  &:read-only {
    background-color: #f5f5f5;
  }
`;

const SaveButton = styled.button`
  display: block;
  width: 100%;
  padding: 12px;
  background-color: #dc2626;
  color: #fff;
  border: none;
  border-radius: 6px;
  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #dc262685;
  }
`;
