export const divisions = ["বরিশাল", "চট্টগ্রাম", "ঢাকা", "খুলনা", "ময়মনসিংহ", "রাজশাহী", "রংপুর", "সিলেট"];

export const districts = {
 "বরিশাল": ["বরগুনা", "বরিশাল", "ভোলা", "ঝালকাঠি", "পটুয়াখালী", "পিরোজপুর"],
 "চট্টগ্রাম": ["বান্দরবান", "চাঁদপুর", "চট্টগ্রাম", "কুমিল্লা", "কক্সবাজার", "ফেনী", "খাগড়াছড়ি", "নোয়াখালী", "রাঙ্গামাটি", "লক্ষ্মীপুর"],
 "ঢাকা": ["ঢাকা", "ফরিদপুর", "গাজীপুর", "গোপালগঞ্জ", "কিশোরগঞ্জ", "মাদারীপুর", "মানিকগঞ্জ", "মুন্সীগঞ্জ", "নারায়ণগঞ্জ", "নরসিংদী", "রাজবাড়ী", "শরীয়তপুর", "টাঙ্গাইল"],
 "খুলনা": ["বাগেরহাট", "চুয়াডাঙ্গা", "যশোর", "ঝিনাইদহ", "খুলনা", "কুষ্টিয়া", "মাগুরা", "মেহেরপুর", "নড়াইল", "সাতক্ষীরা"],
 "ময়মনসিংহ": ["জামালপুর", "ময়মনসিংহ", "নেত্রকোনা", "শেরপুর"],
 "রাজশাহী": ["বগুড়া", "চাঁপাইনবাবগঞ্জ", "জয়পুরহাট", "নওগাঁ", "নাটোর", "পাবনা", "রাজশাহী", "সিরাজগঞ্জ"],
 "রংপুর": ["দিনাজপুর", "গাইবান্ধা", "লালমনিরহাট", "নীলফামারী", "পঞ্চগড়", "রংপুর", "ঠাকুরগাঁও"],
 "সিলেট": ["হবিগঞ্জ", "মৌলভীবাজার", "সুনামগঞ্জ","সিলেট"]
};

export const upazilas = {
   "ঢাকা": ["ধানমন্ডি", "গুলশান", "মিরপুর", "উত্তরা", "মোহাম্মদপুর", "তেজগাঁও", "বাড্ডা"],
   "ফরিদপুর": ["ফরিদপুর সদর", "আলফাডাঙ্গা", "ভাঙ্গা", "বোয়ালমারী", "চরভদ্রাসন", "মধুখালি", "নগরকান্দা", "সদরপুর"],
   "গাজীপুর": ["গাজীপুর সদর", "কালীগঞ্জ", "কালীগঞ্জ", "কালীগঞ্জ", "কাপাসিয়া", "শ্রীপুর"],
   "গোপালগঞ্জ": ["গোপালগঞ্জ সদর", "কাশিয়ানি", "কোটালিপাড়া", "মুকসুদপুর", "টুঙ্গিপাড়া"],
   "কিশোরগঞ্জ": ["অষ্টগ্রাম", "বাজিতপুর", "ভৈরব", "হোসেনপুর", "ইটনা", "করিমগঞ্জ", "কটিয়াদী", "কিশোরগঞ্জ সদর", "কুলিয়ারচর", "মিঠামাইন", "নিকলি", "পাকুন্দিয়া", "তাড়াইল"],
   "মাদারীপুর": ["মাদারীপুর সদর", "কালকিনি", "রাজৈর", "শিবচর"],
   "মানিকগঞ্জ": ["মানিকগঞ্জ সদর", "ঘিওর", "হরিরামপুর", "সাটুরিয়া", "শিবালয়", "সিঙ্গাইর"],
   "মুন্সীগঞ্জ": ["মুন্সীগঞ্জ সদর", "গজারিয়া", "লোহাজং", "সিরাজদিখান", "শ্রীনগর", "টঙ্গীবাড়ি"],
   "নারায়ণগঞ্জ": ["নারায়ণগঞ্জ সদর", "আড়াইহাজার", "বন্দর", "রূপগঞ্জ", "সোনারগাঁও"],
   "নরসিংদী": ["নরসিংদী সদর", "বেলাবো", "মনোহরদী", "পলাশ", "রায়পুরা", "শিবপুর"],
   "রাজবাড়ী": ["রাজবাড়ী সদর", "বালিয়াকান্দি", "গোয়ালন্দঘাট", "পাংশা"],
   "শরীয়তপুর": ["শরীয়তপুর সদর", "ডামুড্যা", "গোসাইরহাট", "নড়িয়া", "ভেদরগঞ্জ", "জাজিরা"],
   "টাঙ্গাইল": ["টাঙ্গাইল সদর", "বাসাইল", "ভূঞাপুর", "দেলদুয়ার", "ধনবাড়ী", "গোপালপুর", "ঘাটাইল", "কালিহাতি", "মধুপুর", "মির্জাপুর", "নাগরপুর", "সখীপুর"],
   "বগুড়া": ["আদমদীঘি", "বগুড়া সদর", "ধুনট", "দুপচাচিঁয়া", "গাবতলী", "কাহালু", "নন্দিগ্রাম", "সারিয়াকান্দি", "শাহজাহানপুর", "শেরপুর", "শিবগঞ্জ", "সোনাতলা"],
   "চাঁপাইনবাবগঞ্জ": ["ভোলাহাট", "গোমস্তাপুর", "নাচোল", "নবাবগঞ্জ সদর", "শিবগঞ্জ"],
   "জয়পুরহাট": ["আক্কেলপুর", "জয়পুরহাট সদর", "কালাই", "ক্ষেতলাল", "পাঁচবিবি"],
   "নওগাঁ": ["আত্রাই", "বদলগাছি", "ধামইরহাট", "মান্দা", "মহাদেবপুর", "নওগাঁ সদর", "নিয়ামতপুর", "পত্নীতলা", "পোরশা", "রাণীনগর", "সাপাহার"],
   "নাটোর": ["বাগাতিপাড়া", "বড়াইগ্রাম", "গুরুদাসপুর", "লালপুর", "নাটোর সদর", "সিংড়া"],
   "পাবনা": ["আটঘরিয়া", "বেড়া", "ভাঙ্গুড়া", "চাটমোহর", "ফরিদপুর", "ঈশ্বরদী", "পাবনা সদর", "সাঁথিয়া", "সুজানগর"],
   "রাজশাহী": ["বাঘা", "বাগমারা", "চারঘাট", "দুর্গাপুর", "গোদাগাড়ি", "মোহনপুর", "পবা", "পুঠিয়া", "তানোর"],
   "সিরাজগঞ্জ": ["বেলকুচি", "চৌহালী", "কামারখন্দ", "কাজীপুর", "রায়গঞ্জ", "শাহজাদপুর", "সিরাজগঞ্জ সদর", "তাড়াশ", "উল্লাপাড়া"],
   "বান্দরবান": ["বান্দরবান সদর", "রুমা", "থানচি", "লামা", "রোয়াংছড়ি", "নাইক্ষ্যংছড়ি", "আলীকদম"],
   "ব্রাহ্মণবাড়িয়া": ["ব্রাহ্মণবাড়িয়া সদর", "আশুগঞ্জ", "নবীনগর", "বাঞ্ছারামপুর", "কসবা", "নাসিরনগর", "আখাউড়া", "সরাইল", "বিজয়নগর"],
   "চাঁদপুর": ["চাঁদপুর সদর", "ফরিদগঞ্জ", "হাইমচর", "হাজীগঞ্জ", "কচুয়া", "মতলব দক্ষিণ", "মতলব উত্তর", "শাহরাস্তি"],
   "চট্টগ্রাম": ["চট্টগ্রাম সদর", "মীরসরাই", "সীতাকুণ্ড", "সন্দ্বীপ", "রাঙ্গুনিয়া", "রাউজান", "ফটিকছড়ি", "হাটহাজারী", "বোয়ালখালী", "আনোয়ারা", "চন্দনাইশ", "পটিয়া", "সাতকানিয়া", "লোহাগাড়া", "বাঁশখালী", "চকোরিয়া", "কক্সবাজার", "কুতুবদিয়া", "মহেশখালী", "রামু", "টেকনাফ", "উখিয়া", "পেকুয়া"],
   "কুমিল্লা": ["কুমিল্লা সদর", "লাকসাম", "চৌদ্দগ্রাম", "দেবিদ্বার", "মুরাদনগর", "দাউদকান্দি", "তিতাস", "হোমনা", "মেঘনা", "বরুড়া", "ব্রাহ্মণপাড়া", "নাঙ্গলকোট"],
   "কক্সবাজার": ["কক্সবাজার সদর", "চকরিয়া", "কুতুবদিয়া", "মহেশখালী", "রামু", "টেকনাফ", "উখিয়া", "পেকুয়া"],
   "ফেনী": ["ফেনী সদর", "ছাগলনাইয়া", "দাগনভূঁঞা", "পরশুরাম", "ফুলগাজী", "সোনাগাজী"],
   "খাগড়াছড়ি": ["খাগড়াছড়ি সদর", "দীঘিনালা", "লক্ষ্মীছড়ি", "মহালছড়ি", "মানিকছড়ি", "মাটিরাঙ্গা", "পানছড়ি", "রামগড়"],
   "লক্ষ্মীপুর": ["লক্ষ্মীপুর সদর", "রায়পুর", "রামগঞ্জ", "রামগতি", "কমলনগর"],
   "নোয়াখালী": ["নোয়াখালী সদর", "বেগমগঞ্জ", "চাটখিল", "কোম্পানীগঞ্জ", "হাতিয়া", "সেনবাগ", "সোনাইমুড়ি", "সুবর্ণচর"],
   "রাঙ্গামাটি": ["রাঙ্গামাটি সদর", "বেলাইছড়ি", "বাঘাইছড়ি", "বরকল", "কাপ্তাই", "জুরাইছড়ি", "রাজস্থলী", "লংগদু", "নানিয়ারচর"],
   হবিগঞ্জ: ["হবিগঞ্জ সদর", "বাহুবল", "চুনারুঘাট", "লাখাই", "মাধবপুর", "নবীগঞ্জ", "শায়েস্তাগঞ্জ"],
   মৌলভীবাজার: ["মৌলভীবাজার সদর", "বড়লেখা", "জুড়ী", "কুলাউড়া", "কমলগঞ্জ", "রাজনগর", "শ্রীমঙ্গল"],
   সুনামগঞ্জ: ["সুনামগঞ্জ সদর", "বিশ্বম্ভরপুর", "দক্ষিণ সুনামগঞ্জ", "ছাতক", "দোয়ারাবাজার", "জগন্নাথপুর", "জামালগঞ্জ", "ধর্মপাশা", "তাহিরপুর", "শাল্লা", "দিরাই"],
   সিলেট: ["সিলেট সদর", "বালাগঞ্জ", "বিয়ানীবাজার", "বিশ্বনাথ", "ফেঞ্চুগঞ্জ", "গোলাপগঞ্জ", "গোয়াইনঘাট", "জৈন্তাপুর", "কানাইঘাট", "কompanyganj", "জকিগঞ্জ"],
   বরগুনা: ["বরগুনা সদর", "আমতলী", "বামনা", "বেতাগী", "পাথরঘাটা", "তালতলী"],
   বরিশাল: ["বরিশাল সদর", "আগৈলঝাড়া", "বাবুগঞ্জ", "বাকেরগঞ্জ", "বানারীপাড়া", "গৌরনদী", "হিজলা", "মেহেন্দিগঞ্জ", "মুলাদী", "উজিরপুর"],
   ভোলা: ["ভোলা সদর", "বোরহানউদ্দিন", "চরফ্যাশন", "দৌলতখান", "লালমোহন", "মনপুরা", "তজুমদ্দিন"],
   ঝালকাঠি: ["ঝালকাঠি সদর", "কাঠালিয়া", "নলছিটি", "রাজাপুর"],
   পটুয়াখালী: ["পটুয়াখালী সদর", "বাউফল", "দুমকি", "দৌলতখান", "গলাচিপা", "কলাপাড়া", "মির্জাগঞ্জ", "রাঙ্গাবালী"],
   পিরোজপুর: ["পিরোজপুর সদর", "ভান্ডারিয়া", "কাউখালী", "মঠবাড়িয়া", "নাজিরপুর", "নেছারাবাদ", "জিয়ানগর"],
   দিনাজপুর: ["দিনাজপুর সদর", "বোচাগঞ্জ", "বিরল", "বিরামপুর", "বীরগঞ্জ", "বিরামপুর", "ঘোড়াঘাট", "হাকিমপুর", "খানসামা", "চিরিরবন্দর", "নবাবগঞ্জ", "পার্বতীপুর"],
   গাইবান্ধা: ["গাইবান্ধা সদর", "ফুলছড়ি", "গোবিন্দগঞ্জ", "পলাশবাড়ী", "সাঘাটা", "সাদুল্যাপুর", "সুন্দরগঞ্জ"],
   লালমনিরহাট: ["লালমনিরহাট সদর", "আদিতমারী", "আহমদপুর", "কালীগঞ্জ", "হাতিবান্ধা", "পাটগ্রাম"],
   নীলফামারী: ["নীলফামারী সদর", "ডিমলা", "ডোমার", "জলঢাকা", "কিশোরগঞ্জ", "সৈয়দপুর"],
   পঞ্চগড়: ["পঞ্চগড় সদর", "আটোয়ারী", "বোদা", "দেবীগঞ্জ", "তেতুলিয়া"],
   রংপুর: ["রংপুর সদর", "বদরগঞ্জ", "গঙ্গাচড়া", "তারাগঞ্জ", "কাউনিয়া", "মিঠাপুকুর", "পীরগঞ্জ", "পীরগাছা"],
   ঠাকুরগাঁও: ["ঠাকুরগাঁও সদর", "বালিয়াডাঙ্গী", "হরিপুর", "পীরগঞ্জ", "রাণীশংকৈল"],
   জামালপুর: ["জামালপুর সদর", "বকশীগঞ্জ", "দেওয়ানগঞ্জ", "ইসলামপুর", "মাদারগঞ্জ", "মেলান্দহ", "সরিষাবাড়ী"],
   ময়মনসিংহ: ["ময়মনসিংহ সদর", "গৌরীপুর", "গফরগাঁও", "হালুয়াঘাট", "ঈশ্বরগঞ্জ", "নান্দাইল", "ফুলপুর", "ফুলবাড়ীয়া", "ত্রিশাল", "ধোবাউড়া", "মুক্তাগাছা", "ভালুকা"],
   নেত্রকোনা: ["নেত্রকোনা সদর", "আটপাড়া", "বারহাট্টা", "দুর্গাপুর", "কলমাকান্দা", "খালিয়াজুরী", "কেন্দুয়া", "মদন", "মোহনগঞ্জ", "পূর্বধলা"],
   শেরপুর: ["শেরপুর সদর", "ঝিনাইগাতী", "নালিতাবাড়ী", "নকলা", "শ্রীবরদী"],
   বাগেরহাট: ["বাগেরহাট সদর", "চিতলমারী", "ফকিরহাট", "কচুয়া", "মোল্লাহাট", "মোড়েলগঞ্জ", "রামপাল", "শরণখোলা"],
   চুয়াডাঙ্গা: ["চুয়াডাঙ্গা সদর", "আলমডাঙ্গা", "দামুড়হুদা", "জীবননগর"],
   যশোর: ["যশোর সদর", "অভয়নগর", "বাঘারপাড়া", "চৌগাছা", "ঝিকরগাছা", "কেশবপুর", "মণিরামপুর", "শার্শা"],
   ঝিনাইদহ: ["ঝিনাইদহ সদর", "কোটচাঁদপুর", "কালীগঞ্জ", "মহেশপুর", "শৈলকুপা", "হরিণাকুন্ডু"],
   খুলনা: ["খুলনা সদর", "বটিয়াঘাটা", "দিঘলিয়া", "দাকোপ", "ফুলতলা", "ডুমুরিয়া", "কয়রা", "পাইকগাছা", "রূপসা", "তেরখাদা"],
   কুষ্টিয়া: ["কুষ্টিয়া সদর", "কুমারখালী", "দৌলতপুর", "খোকসা", "মিরপুর", "ভেড়ামারা"],
   মাগুরা: ["মাগুরা সদর", "শালিখা", "শ্রীপুর", "মহম্মদপুর"],
   মেহেরপুর: ["মেহেরপুর সদর", "গাংনী", "মুজিবনগর"],
   নড়াইল: ["নড়াইল সদর", "লোহাগড়া", "কালিয়া"],
   সাতক্ষীরা: ["সাতক্ষীরা সদর", "আশাশুনি", "দেবহাটা", "কলারোয়া", "শ্যামনগর", "তালা", "কালিগঞ্জ"]
 };
