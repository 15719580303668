import React, { useEffect } from "react";
import styled from "styled-components";

const AdImageHoverModal = ({ isOpen, imageSrc, onClose, position }) => {
  useEffect(() => {
    if (isOpen) {
      const timer = setTimeout(() => {
        onClose();
      }, 2000); 

      return () => clearTimeout(timer);
    }
  }, [isOpen, onClose]);

  if (!isOpen) return null;

  return (
    <TooltipOverlay
      style={{ top: position.top, left: position.left }}
      onMouseLeave={onClose}
    >
      <TooltipContent>
        <ImageContainer>
          <FullImage src={imageSrc} alt="Full-size ad image" />
        </ImageContainer>
      </TooltipContent>
    </TooltipOverlay>
  );
};

export default AdImageHoverModal;

const TooltipOverlay = styled.div`
  position: absolute;
  width: 300px; /* Adjust width as needed */
  background: rgba(0, 0, 0, 0.8);
  border-radius: 8px;
  z-index: 1000;
`;

const TooltipContent = styled.div`
  position: relative;
  background: white;
  border-radius: 8px;
  overflow: auto;
`;

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const FullImage = styled.img`
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
`;
