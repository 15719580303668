import { Button, Input, Modal, Popconfirm, Select, Table } from "antd"
import axios from "axios"
import React, { useEffect, useState } from "react"
import toast from "react-hot-toast"
import { AiOutlineDelete, AiOutlineEdit } from "react-icons/ai"
import ReactPaginate from "react-paginate"
import { PaginationContainer } from "../NewsPostUpdated/NewsPostUpdated.styled"

const { Option } = Select

function NavbarLinks() {

  const [navbarLinks, setNavbarLinks] = useState([]);
  const [categories, setCategories] = useState([]);
  const [editableRow, setEditableRow] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isCustomModalVisible, setIsCustomModalVisible] = useState(false);
  const [newLink, setNewLink] = useState({ name: 'বাংলাদেশ', order: 1, url: 'https://kalerpotro.com', navbar_type: 'CATEGORY' });
  const [customLink, setCustomLink] = useState({ name: '', order: 1, url: '', navbar_type: 'CUSTOM' });
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [categoriesPerPage] = useState(5);

  const getToken = () => localStorage.getItem("accessToken")

  useEffect(() => {
    const getCategories = async () => {
      try {
        const { data } = await axios.get(
          "https://kalerpotro.pythonanywhere.com/api/news/category/list/"
        )
        setCategories(data)
        setLoading(false)
      } catch (error) {
        console.log(error.message)
      }
    }

    const getNavlinks = async () => {
      try {
        const { data } = await axios.get(
          "https://kalerpotro.pythonanywhere.com/api/ad/navbarlink/list/"
        )
        setNavbarLinks(data)
        setLoading(false)
      } catch (error) {
        console.log(error.message)
      }
    }

    getCategories()
    getNavlinks()
  }, [])

  const handleEditClick = (id) => {
    setEditableRow(id)
  }

  const handleCancelClick = () => {
    setEditableRow(null)
  }

  const handleSaveClick = async (id, updatedData) => {
    try {
      const token = getToken()
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
      const { data } = await axios.patch(
        `https://kalerpotro.pythonanywhere.com/api/ad/navbarlinks/${id}/`,
        updatedData,
        config
      )
      toast.success(data?.message)
      setEditableRow(null)
      setLoading(true)
      const { data: updatedDataList } = await axios.get(
        "https://kalerpotro.pythonanywhere.com/api/ad/navbarlink/list/"
      )
      setNavbarLinks(updatedDataList)
      setLoading(false)
    } catch (error) {
      console.log(error.message)
    }
  }
  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected + 1); 
  };

  const handleChange = (id, field, value) => {
    setNavbarLinks((prevState) =>
      prevState.map((link) =>
        link.id === id ? { ...link, [field]: value } : link
      )
    )
  }

  const handleAddNavLink = async () => {
    if (navbarLinks.length >= 11) {
      toast.error("Cannot add more than 11 navlinks.")
      return
    }
    try {
      const token = getToken()
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
      const { data } = await axios.post(
        "https://kalerpotro.pythonanywhere.com/api/ad/navbarlinks/",
        newLink,
        config
      )
      toast.success(data?.message)
      setIsModalVisible(false)
      setNewLink({ name: "", order: 1, url: "https://kalerpotro.com" })
      setLoading(true)
      const { data: updatedDataList } = await axios.get(
        "https://kalerpotro.pythonanywhere.com/api/ad/navbarlink/list/"
      )
      setNavbarLinks(updatedDataList)
      setLoading(false)
    } catch (error) {
      console.log(error.message)
    }
  }

  const handleAddCustomNavLink = async () => {
    if (navbarLinks.length >= 11) {
      toast.error("Cannot add more than 11 navlinks.")
      return
    }
    try {
      const token = getToken()
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
      const { data } = await axios.post(
        "https://kalerpotro.pythonanywhere.com/api/ad/navbarlinks/",
        customLink,
        config
      )
      toast.success(data?.message)
      setIsCustomModalVisible(false)
      setCustomLink({ name: "", order: 1, url: "" })
      setLoading(true)
      const { data: updatedDataList } = await axios.get(
        "https://kalerpotro.pythonanywhere.com/api/ad/navbarlink/list/"
      )
      setNavbarLinks(updatedDataList)
      setLoading(false)
    } catch (error) {
      console.log(error.message)
    }
  }

  const handleModalCancel = () => {
    setIsModalVisible(false)
  }

  const handleModalOk = () => {
    handleAddNavLink()
  }

  const handleCustomModalCancel = () => {
    setIsCustomModalVisible(false)
  }

  const handleCustomModalOk = () => {
    handleAddCustomNavLink()
  }

  const handleDeleteClick = async (id) => {
    try {
      const token = getToken()
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
      await axios.delete(
        `https://kalerpotro.pythonanywhere.com/api/ad/navbarlinks/${id}/`,
        config
      )
      toast.success("NavLink deleted successfully!")
      setLoading(true)
      const { data } = await axios.get(
        "https://kalerpotro.pythonanywhere.com/api/ad/navbarlink/list/"
      )
      setNavbarLinks(data)
      setLoading(false)
    } catch (error) {
      console.log(error.message)
    }
  }

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text, record) =>
        editableRow === record.id ? (

          record.navbar_type === 'CUSTOM' ? (

            <Input
              value={record.name}
              onChange={(e) => handleChange(record.id, "name", e.target.value)}
              className="w-full"
            />
          ) : (
            <Select
              value={record.name}
              onChange={(value) => handleChange(record.id, "name", value)}
              className="w-full"
            >
              {categories.map((category) => (
                <Option key={category.id} value={category.name}>
                  {category.name}
                </Option>
              ))}
            </Select>
          )
        ) : (
          record.name
        ),
      onHeaderCell: () => ({
        style: {
          backgroundColor: "#DC2626",
          color: "#fff",
        },
      }),
    },
    {
      title: "Order",
      dataIndex: "order",
      key: "order",
      render: (text, record) =>
        editableRow === record.id ? (
          <Select
            value={record.order}
            onChange={(value) => handleChange(record.id, "order", value)}
            className="w-full"
          >
            {[...Array(11).keys()].map((num) => (
              <Option key={num + 1} value={num + 1}>
                {num + 1}
              </Option>
            ))}
          </Select>
        ) : (
          record.order
        ),
      onHeaderCell: () => ({
        style: {
          backgroundColor: "#DC2626",
          color: "#fff",
        },
      }),
    },
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      onHeaderCell: () => ({
        style: {
          backgroundColor: "#DC2626",
          color: "#fff",
        },
      }),
    },
    {
      title: 'Type',
      dataIndex: 'navbar_type',
      key: 'navbar_type',

      onHeaderCell: () => ({
        style: {
          backgroundColor: "#DC2626",
          color: "#fff",
        },
      }),
    },
    {
      title: "URL",
      dataIndex: "url",
      key: "url",
      render: (text, record) =>
        editableRow === record.id ? (
          <Input
            value={record.url}
            onChange={(e) => handleChange(record.id, "url", e.target.value)}
          />
        ) : (
          record.url
        ),
      onHeaderCell: () => ({
        style: {
          backgroundColor: "#DC2626",
          color: "#fff",
        },
      }),
    },
    {
      title: "Actions",
      key: "actions",
      render: (text, record) =>
        editableRow === record.id ? (
          <>
            <Button
              onClick={() =>
                handleSaveClick(record.id, {
                  name: record.name,
                  order: record.order,
                  url: record.url,
                })
              }
              className="bg-blue-500 text-white px-4 py-2 rounded mr-2"
            >
              Save
            </Button>

            <Button
              onClick={handleCancelClick}
              className="bg-red-500 text-white px-4 py-2 rounded"
            >
              Cancel
            </Button>
          </>
        ) : (
          <>
            <button
              onClick={() => handleEditClick(record.id)}
              className="hover:bg-[#DC2626] hover:text-white p-3 rounded-full mr-2 text-[#DC2626] text-xl"
            >
              <AiOutlineEdit />
            </button>
            <Popconfirm
              title="Are you sure you want to delete this nav link?"
              onConfirm={() => handleDeleteClick(record.id)}
              okText="Yes"
              cancelText="No"
            >
              <button className="hover:bg-[#DC2626] hover:text-white p-3 rounded-full text-[#DC2626] text-xl">
                <AiOutlineDelete />
              </button>
            </Popconfirm>
          </>
        ),
      onHeaderCell: () => ({
        style: {
          backgroundColor: "#DC2626",
          color: "#fff",
        },
      }),
    },
  ]

  const indexOfLastCategory = currentPage * categoriesPerPage;
  const indexOfFirstCategory = indexOfLastCategory - categoriesPerPage;
  const currentCategories = navbarLinks.slice(indexOfFirstCategory, indexOfLastCategory);

  return (
    <div className="p-4">
      <h1 className="text-xl font-semibold mb-4">Navbar Links</h1>
      <div className="flex justify-end mb-4">
        <button
          onClick={() => setIsModalVisible(true)}
          className="bg-red-600 text-white px-4 py-2 rounded mr-2"
        >
          Add Navbar Link
        </button>
        <button
          onClick={() => setIsCustomModalVisible(true)}
          className="bg-red-600 text-white px-4 py-2 rounded"
        >
          Add Custom Navbar Link
        </button>
      </div>

      <Table
        dataSource={currentCategories}
        columns={columns}
        rowKey="id"
        loading={loading}
        pagination={false}
         className="shadow-lg"
      />
        <PaginationContainer>
        <ReactPaginate
          previousLabel={"Previous"}
          nextLabel={"Next"}
          pageCount={Math.ceil(navbarLinks.length / categoriesPerPage)}
          onPageChange={handlePageChange}
          containerClassName={"pagination"}
          activeClassName={"active"}
          previousClassName={"previous"}
          nextClassName={"next"}
        />
      </PaginationContainer>
      <Modal
        title="Add Navbar Link"
        visible={isModalVisible}
        onOk={handleModalOk}
        onCancel={handleModalCancel}
      >
        <Select
          value={newLink.name}
          onChange={(value) => setNewLink({ ...newLink, name: value })}
          placeholder="Select a category"
          className="w-full mb-4"
        >
          {categories.map((category) => (
            <Option key={category.id} value={category.name}>
              {category.name}
            </Option>
          ))}
        </Select>
        <Select
          value={newLink.order}
          onChange={(value) => setNewLink({ ...newLink, order: value })}
          className="w-full mb-4"
        >
          {[...Array(11).keys()].map((num) => (
            <Option key={num + 1} value={num + 1}>
              {num + 1}
            </Option>
          ))}
        </Select>
        {/* <Input
          value={newLink.url}
          onChange={(e) => setNewLink({ ...newLink, url: e.target.value })}
          placeholder="Enter URL"
          className="w-full mb-4"
        /> */}
      </Modal>

      <Modal
        title="Add Custom Navbar Link"
        visible={isCustomModalVisible}
        onOk={handleCustomModalOk}
        onCancel={handleCustomModalCancel}
      >
        <Input
          value={customLink.name}
          onChange={(e) =>
            setCustomLink({ ...customLink, name: e.target.value })
          }
          placeholder="Enter name"
          className="w-full mb-4"
        />
        <Select
          value={customLink.order}
          onChange={(value) => setCustomLink({ ...customLink, order: value })}
          className="w-full mb-4"
        >
          {[...Array(11).keys()].map((num) => (
            <Option key={num + 1} value={num + 1}>
              {num + 1}
            </Option>
          ))}
        </Select>
        <Input
          value={customLink.url}
          onChange={(e) =>
            setCustomLink({ ...customLink, url: e.target.value })
          }
          placeholder="Enter URL"
          className="w-full mb-4"
        />
      </Modal>
    </div>
  )
}

export default NavbarLinks
