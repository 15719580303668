import React, { useEffect, useState } from 'react';
import { FiAlertCircle, FiCheckCircle } from 'react-icons/fi';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const EmailVerify = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [verificationStatus, setVerificationStatus] = useState(null);
  useEffect(() => {

    const success = location.search.split("=")
    if (success[1] === "true") {
      setVerificationStatus('success');
    } else {
      setVerificationStatus('failure');
    }
  }, [location.search]);

  const handleGoBack = () => {
    navigate('/');
  };

  const handleOpenMailbox = () => {
    window.open('https://mail.google.com/', '_blank');
  };

  return (
    <Container>
      <Card>
        <IconWrapper>
          {verificationStatus === 'success' ? (
            <SuccessIcon className="text-green-500 text-6xl" />
          ) : (
            <ErrorIcon className="text-red-500 text-6xl" />
          )}
        </IconWrapper>
        <Title>
          {verificationStatus === 'success'
            ? 'Email Verified Successfully'
            : 'Verification Failed'}
        </Title>
        <Message>
          {verificationStatus === 'success'
            ? 'Your email has been verified successfully. You can now proceed to log in.'
            : 'Oops! It looks like your email verification has failed. Please check your inbox for the verification email and try again.'}
        </Message>
        {verificationStatus === 'failure' && (
          <Button onClick={handleOpenMailbox}>
            Open Mailbox
          </Button>
        )}
        <BackButton onClick={handleGoBack}>
          Go Back to Home
        </BackButton>
      </Card>
    </Container>
  );
};

export default EmailVerify;

// Styled Components

const Container = styled.div`
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(135deg, #667eea, #764ba2);
  padding: 0 1rem;
`;

const Card = styled.div`
  background: #fff;
  border-radius: 12px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  width: 100%;
  padding: 2rem;
  text-align: center;
  display: flex;
  align-items: center;
flex-direction: column
`;

const IconWrapper = styled.div`
  margin-bottom: 1.5rem;
`;

const SuccessIcon = styled(FiCheckCircle)`
  color: #4caf50;
`;

const ErrorIcon = styled(FiAlertCircle)`
  color: #f44336;
`;

const Title = styled.h1`
  font-size: 24px;
  font-weight: bold;
  margin: 0 0 1rem;
  color: #333;
`;

const Message = styled.p`
  font-size: 16px;
  color: #666;
  margin-bottom: 1.5rem;
`;

const Button = styled.button`
  background: #4caf50;
  color: #fff;
  border: none;
  border-radius: 8px;
  padding: 12px 24px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  transition: background 0.3s, transform 0.3s;
  margin-bottom: 1rem;

  &:hover {
    background: #45a049;
    transform: scale(1.05);
  }
`;

const BackButton = styled.button`
  background: #f0f0f0;
  color: #333;
  border: none;
  border-radius: 8px;
  padding: 12px 24px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  transition: background 0.3s, transform 0.3s;

  &:hover {
    background: #e0e0e0;
    transform: scale(1.05);
  }
`;
