import React, { useEffect, useState } from "react"
import { Modal, Form, Input, Button, DatePicker, Row, Col, Select } from "antd"
import {
  divisions,
  districts,
  upazilas,
} from "../../../Utils/locationDataNonFiltered"

const { RangePicker } = DatePicker
const { Option } = Select

const FilterNewsPostModal = ({ isOpen, onRequestClose, handleFilter }) => {
  const [form] = Form.useForm()
  const [categories, setCategories] = useState([])
  const [editors, setEditors] = useState([])

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await fetch(
          "https://kalerpotro.pythonanywhere.com/api/news/category/list/"
        )
        const data = await response.json()
        setCategories(data)
      } catch (error) {
        console.error("Failed to fetch categories:", error)
      }
    }

    const fetchEditors = async () => {
      const role = localStorage.getItem("role")
      const accessToken = localStorage.getItem("accessToken")
      let url = ""

      if (role === "ADMIN" || role === "SUPER_ADMIN") {
        url =
          "https://kalerpotro.pythonanywhere.com/api/auth_user/admin/alluser/"
      } else if (role === "EDITOR") {
        url = "https://kalerpotro.pythonanywhere.com/api/auth_user/me/"
      }

      if (url) {
        try {
          const response = await fetch(url, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          })
          const data = await response.json()
          if (role === "EDITOR") {
            setEditors([data])
          } else {
            setEditors(data)
          }
        } catch (error) {
          console.error("Failed to fetch editors:", error)
        }
      }
    }

    fetchCategories()
    fetchEditors()
  }, [])

  const handleSubmit = (values) => {
    handleFilter(values)
    onRequestClose()
  }

  return (
    <Modal
      title="Filter News Posts"
      visible={isOpen}
      onCancel={onRequestClose}
      footer={null}
      bodyStyle={{ padding: "16px" }}
      style={{ top: 20 }}
      width="90%"
    >
      <Form form={form} onFinish={handleSubmit} layout="vertical">
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item name="headline" label="Headline">
              <Input placeholder="Enter headline" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="category_name" label="Category">
              <Select placeholder="Select category">
                {categories.map((category) => (
                  <Option key={category.id} value={category.name}>
                    {category.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item name="custom_location" label="Country">
              <Input placeholder="Enter country" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="division" label="Division">
              <Select placeholder="Select division">
                {divisions.map((division, index) => (
                  <Option key={index} value={division}>
                    {division}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item name="district" label="District">
              <Select placeholder="Select district">
                {districts.map((district, index) => (
                  <Option key={index} value={district}>
                    {district}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="upazila" label="Upazila">
              <Select placeholder="Select upazila">
                {upazilas.map((upazila, index) => (
                  <Option key={index} value={upazila}>
                    {upazila}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item name="editor_name" label="Editor">
              <Select placeholder="Select editor">
                {editors.map((editor) => (
                  <Option key={editor.id} value={editor.username}>
                    {editor.first_name} {editor.last_name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="date_range" label="Date Range">
              <RangePicker style={{ width: "100%" }} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item name="is_active" label="Status">
              <Select placeholder="Select status">
                <Option value="all">All</Option>
                <Option value="active">Active</Option>
                <Option value="pending">Pending</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            style={{
              width: "100%",
              backgroundColor: "#dc2626",
              borderColor: "#fff",
            }}
          >
            Apply Filters
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default FilterNewsPostModal
