import React, { useState, useEffect } from "react"
import { useDispatch } from "react-redux"
import Modal from "react-modal"
import { FaTimes } from "react-icons/fa"
import styled from "styled-components"
import { toast } from "react-hot-toast"
import { setUserDetails } from "../../store/userSlice"

const EditProfileModal = ({ isOpen, onRequestClose, user, setUser }) => {
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    address: "",
    bio: "",
  })

  const [initialFormData, setInitialFormData] = useState({})
  const [profilePicture, setProfilePicture] = useState(null)
  const dispatch = useDispatch()

  useEffect(() => {
    const fetchUserData = async () => {
      const token = localStorage.getItem("accessToken")

      try {
        const response = await fetch(
          "https://kalerpotro.pythonanywhere.com/api/auth_user/me/",
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )

        if (response.ok) {
          const userData = await response.json()
          setFormData({
            first_name: userData.first_name || "",
            last_name: userData.last_name || "",
            email: userData.email || "",
            phone: userData.phone || "",
            address: userData.address || "",
            bio: userData.bio || "",
          })
          setInitialFormData({
            first_name: userData.first_name || "",
            last_name: userData.last_name || "",
            email: userData.email || "",
            phone: userData.phone || "",
            address: userData.address || "",
            bio: userData.bio || "",
          })
        } else {
          throw new Error("Failed to fetch user data.")
        }
      } catch (error) {
        console.error("Failed to fetch user data", error)
      }
    }

    if (isOpen) {
      fetchUserData()
    }
  }, [isOpen])

  const handleChange = (e) => {
    const { name, value } = e.target
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }))
  }

  const handleFileChange = (e) => {
    setProfilePicture(e.target.files[0])
  }

  const handleSave = async (e) => {
    e.preventDefault()
    const token = localStorage.getItem("accessToken")

    const updatedData = {}
    Object.keys(formData).forEach((key) => {
      if (formData[key] !== initialFormData[key]) {
        updatedData[key] = formData[key]
      }
    })

    const data = new FormData()
    for (const [key, value] of Object.entries(updatedData)) {
      data.append(key, value)
    }

    if (profilePicture) {
      data.append("profile_picture", profilePicture)
    }

    try {
      const response = await fetch(
        "https://kalerpotro.pythonanywhere.com/api/auth_user/me/update/",
        {
          method: "PATCH",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: data,
        }
      )

      if (response.ok) {
        const updatedUser = await response.json()
        dispatch(setUserDetails(updatedUser))
        toast.success("Profile updated successfully!")
        onRequestClose()
      } else {
        throw new Error("Failed to update profile.")
      }
    } catch (error) {
      console.error("Failed to update profile", error)
      toast.error("Failed to update profile.")
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={ModalStyle}
      contentLabel="Edit Profile"
    >
      <ModalHeader>
        <Title>Edit Profile</Title>
        <CloseButton onClick={onRequestClose}>
          <FaTimes />
        </CloseButton>
      </ModalHeader>
      <form onSubmit={handleSave}>
        <FormRow>
          <Label>
            First Name
            <Input
              type="text"
              name="first_name"
              value={formData.first_name}
              onChange={handleChange}
            />
          </Label>
          <Label>
            Last Name
            <Input
              type="text"
              name="last_name"
              value={formData.last_name}
              onChange={handleChange}
            />
          </Label>
        </FormRow>
        <FormRow>
          <Label>
            Email
            <Input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              disabled
            />
          </Label>
          <Label>
            Phone
            <Input
              type="text"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
            />
          </Label>
        </FormRow>
        <FormRow>
          <Label>
            Bio
            <Input
              type="text"
              name="bio"
              value={formData.bio}
              onChange={handleChange}
            />
          </Label>
        </FormRow>
        <FormRow>
          <Label>
            Profile Picture
            <Input
              type="file"
              name="profile_picture"
              onChange={handleFileChange}
            />
          </Label>
        </FormRow>
        <SaveButton type="submit">Save</SaveButton>
      </form>
    </Modal>
  )
}

export default EditProfileModal

const ModalStyle = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "60%",
    padding: "2.5rem 3rem",
    borderRadius: "12px",
    boxShadow: "0 0 20px rgba(0, 0, 0, 0.2)",
    backgroundColor: "#fff",
  },
}

Modal.setAppElement("#root")

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const Title = styled.h2`
  margin: 0;
  font-size: 20px;
  color: #dc2626;
`

const CloseButton = styled.button`
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #dc2626;
  transition: color 0.3s ease;

  &:hover {
    color: #d00000;
  }
`

const FormRow = styled.div`
  display: flex;
  gap: 15px;
  margin-bottom: 1.25rem;
`

const Label = styled.label`
  flex: 1;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  color: #333;
`

const Input = styled.input`
  margin-top: 5px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 6px;
  font-size: 16px;
  transition: border-color 0.3s;
  outline: none;

  &:focus {
    border-color: #f33823;
  }
`

const SaveButton = styled.button`
  display: block;
  width: 100%;
  padding: 12px;
  background-color: #dc2626;
  color: #fff;
  border: none;
  border-radius: 6px;
  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #dc262685;
  }
`
