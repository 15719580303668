import React, { useState } from "react"
import { Form, Input, Button } from "antd"
import styled from "styled-components"
import { toast } from "react-hot-toast"
import { useNavigate } from "react-router-dom"

const ChangePasswordComponent = () => {
  const [formData, setFormData] = useState({
    old_password: "",
    new_password: "",
    confirm_password: "",
  })

  const navigate = useNavigate()

  const handleChange = (e) => {
    const { name, value } = e.target
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }))
  }

  const handleSave = async () => {
    const token = localStorage.getItem("accessToken")

    if (formData.new_password !== formData.confirm_password) {
      toast.error("The two passwords that you entered do not match!")
      return
    }

    try {
      const response = await fetch(
        "https://kalerpotro.pythonanywhere.com/api/auth_user/password/change/",
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            old_password: formData.old_password,
            new_password: formData.new_password,
            confirm_password: formData.confirm_password,
          }),
        }
      )

      if (response.ok) {
        toast.success("Password changed successfully")
        setFormData({
          old_password: "",
          new_password: "",
          confirm_password: "",
        })
        navigate("/overview")
      } else {
        const result = await response.json()
        toast.error(result.message || "Failed to change password")
      }
    } catch (error) {
      console.error(error)
      toast.error("Failed to change password")
    }
  }

  const passwordRules = [
    { required: true, message: "Please input your password!" },
    { min: 8, message: "Password must be at least 8 characters long!" },
    {
      pattern: /[A-Z]/,
      message: "Password must contain at least one uppercase letter!",
    },
    {
      pattern: /[a-z]/,
      message: "Password must contain at least one lowercase letter!",
    },
    {
      pattern: /[0-9]/,
      message: "Password must contain at least one number!",
    },
    {
      pattern: /[^A-Za-z0-9]/,
      message: "Password must contain at least one special character!",
    },
  ]

  return (
    <FormContainer>
      <Title>Change Password</Title>
      <Form onFinish={handleSave} layout="vertical">
        <Form.Item
          label="Old Password"
          name="old_password"
          rules={[
            { required: true, message: "Please input your old password!" },
          ]}
        >
          <StyledInput.Password
            name="old_password"
            value={formData.old_password}
            onChange={handleChange}
          />
        </Form.Item>
        <Form.Item
          label="New Password"
          name="new_password"
          rules={passwordRules}
        >
          <StyledInput.Password
            name="new_password"
            value={formData.new_password}
            onChange={handleChange}
          />
        </Form.Item>
        <Form.Item
          label="Confirm Password"
          name="confirm_password"
          dependencies={["new_password"]}
          rules={[
            ...passwordRules,
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("new_password") === value) {
                  return Promise.resolve()
                }
                return Promise.reject(
                  new Error("The two passwords that you entered do not match!")
                )
              },
            }),
          ]}
        >
          <StyledInput.Password
            name="confirm_password"
            value={formData.confirm_password}
            onChange={handleChange}
          />
        </Form.Item>
        <StyledButton type="primary" htmlType="submit">
          Change Password
        </StyledButton>
      </Form>
    </FormContainer>
  )
}

export default ChangePasswordComponent

const FormContainer = styled.div`
  background: #fff;
  padding: 2.5rem 3rem;
  border-radius: 12px;
  width: 40%;
  margin: 10% auto;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
`

const Title = styled.h2`
  margin: 0 0 1.5rem 0;
  font-size: 24px;
  color: #dc2626;
  text-align: center;
`

const StyledInput = styled(Input)`
  &.ant-input-password {
    .ant-input {
      border-radius: 6px;
    }
  }
`

const StyledButton = styled(Button)`
  width: 100%;
  background-color: #dc2626;
  border: none;
  border-radius: 6px;
  color: #fff;
  font-size: 16px;
  padding: 12px;
  cursor: pointer;

  &:hover,
  &:focus {
    background-color: #b22222;
  }
`
