import { Button, Input, Modal, Popconfirm, Select, Table } from "antd"
import axios from "axios"
import React, { useEffect, useState } from "react"
import toast from "react-hot-toast"
import { AiOutlineDelete, AiOutlineEdit } from "react-icons/ai"

const { Option } = Select

function FooterLinks() {
  const [footerLinks, setFooterLinks] = useState([])
  const [editableRow, setEditableRow] = useState(null)
  const [isFooterModalVisible, setIsFooterModalVisible] = useState(false)
  const [isSocialModalVisible, setIsSocialModalVisible] = useState(false)
  const [newLink, setNewLink] = useState({
    name: "",
    column: 1,
    url: "",
    order: 1,
  })
  const [newSocialLink, setNewSocialLink] = useState({
    name: "Facebook",
    column: 3,
    url: "",
    order: 1,
  })
  const [loading, setLoading] = useState(true)
  const socialMediaNames = ["Facebook", "Twitter", "Instagram", "YouTube"]
  const getToken = () => localStorage.getItem("accessToken")

  useEffect(() => {
    const getFooterLinks = async () => {
      try {
        const { data } = await axios.get(
          "https://kalerpotro.pythonanywhere.com/api/ad/footerlinks/list/"
        )
        setFooterLinks(data)
      } catch (error) {
        console.log(error.message)
      } finally {
        setLoading(false)
      }
    }
    getFooterLinks()
  }, [])

  const handleEditClick = (id) => {
    setEditableRow(id)
  }

  const handleCancelClick = () => {
    setEditableRow(null)
  }

  const handleSaveClick = async (id, updatedData) => {
    try {
      const token = getToken()
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
      const { data } = await axios.patch(
        `https://kalerpotro.pythonanywhere.com/api/ad/footerlinks/${id}/`,
        updatedData,
        config
      )
      toast.success(data?.message)
      setEditableRow(null)
      setLoading(true)
      const { data: updatedDataList } = await axios.get(
        "https://kalerpotro.pythonanywhere.com/api/ad/footerlinks/list/"
      )
      setFooterLinks(updatedDataList)
      setLoading(false)
    } catch (error) {
      console.log(error.message)
    }
  }

  const handleChange = (id, field, value) => {
    setFooterLinks((prevState) =>
      prevState.map((link) =>
        link.id === id ? { ...link, [field]: value } : link
      )
    )
  }

  const handleAddFooterLink = async () => {
    const totalLinks = footerLinks.length
    const columnLinks = footerLinks.filter(
      (link) => link.column === newLink.column
    )
    if (columnLinks.length >= 4) {
      toast.error("Cannot add more than 4 footer links in a single column.")
      return
    }
    if (totalLinks >= 12) {
      toast.error("Cannot add more than 12 footer links in total.")
      return
    }
    try {
      const token = getToken()
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
      const { data } = await axios.post(
        "https://kalerpotro.pythonanywhere.com/api/ad/footerlinks/",
        newLink,
        config
      )
      toast.success(data?.message)
      setIsFooterModalVisible(false)
      setNewLink({ name: "", column: 1, url: "", order: 1 })
      setLoading(true)
      const { data: updatedDataList } = await axios.get(
        "https://kalerpotro.pythonanywhere.com/api/ad/footerlinks/list/"
      )
      setFooterLinks(updatedDataList)
      setLoading(false)
    } catch (error) {
      console.log(error.message)
    }
  }

  const handleAddSocialLink = async () => {
    const columnLinks = footerLinks.filter((link) => link.column === 3)
    if (columnLinks.length >= 4) {
      toast.error("Cannot add more than 4 social links.")
      return
    }
    try {
      const token = getToken()
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
      const { data } = await axios.post(
        "https://kalerpotro.pythonanywhere.com/api/ad/footerlinks/",
        newSocialLink,
        config
      )
      toast.success(data?.message)
      setIsSocialModalVisible(false)
      setNewSocialLink({ name: "Facebook", column: 3, url: "", order: 1 })
      setLoading(true)
      const { data: updatedDataList } = await axios.get(
        "https://kalerpotro.pythonanywhere.com/api/ad/footerlinks/list/"
      )
      setFooterLinks(updatedDataList)
      setLoading(false)
    } catch (error) {
      console.log(error.message)
    }
  }

  const handleModalCancel = () => {
    setIsFooterModalVisible(false)
  }

  const handleSocialModalCancel = () => {
    setIsSocialModalVisible(false)
  }

  const handleFooterModalOk = () => {
    handleAddFooterLink()
  }

  const handleSocialModalOk = () => {
    handleAddSocialLink()
  }

  const handleDeleteClick = async (id) => {
    try {
      const token = getToken()
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
      await axios.delete(
        `https://kalerpotro.pythonanywhere.com/api/ad/footerlinks/${id}/`,
        config
      )
      toast.success("Footer Link deleted successfully!")
      setLoading(true)
      const { data } = await axios.get(
        "https://kalerpotro.pythonanywhere.com/api/ad/footerlinks/list/"
      )
      setFooterLinks(data)
      setLoading(false)
    } catch (error) {
      console.log(error.message)
    }
  }

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text, record) =>
        editableRow === record.id ? (
          record.column === 3 ? (
            <Select
              value={text}
              onChange={(value) => handleChange(record.id, "name", value)}
            >
              {socialMediaNames.map((name) => (
                <Option key={name} value={name}>
                  {name}
                </Option>
              ))}
            </Select>
          ) : (
            <Input
              value={text}
              onChange={(e) => handleChange(record.id, "name", e.target.value)}
            />
          )
        ) : (
          text
        ),
      onHeaderCell: () => ({
        style: {
          backgroundColor: "#DC2626",
          color: "#fff",
        },
      }),
    },
    {
      title: "Column",
      dataIndex: "column",
      key: "column",
      render: (text, record) =>
        editableRow === record.id ? (
          record.column === 3 ? (
            <span>3</span>
          ) : (
            <Select
              value={text}
              onChange={(value) => handleChange(record.id, "column", value)}
            >
              <Option value={1}>1</Option>
              <Option value={2}>2</Option>
            </Select>
          )
        ) : (
          text
        ),
      onHeaderCell: () => ({
        style: {
          backgroundColor: "#DC2626",
          color: "#fff",
        },
      }),
    },
    {
      title: "URL",
      dataIndex: "url",
      key: "url",
      render: (text, record) =>
        editableRow === record.id ? (
          <Input
            value={text}
            onChange={(e) => handleChange(record.id, "url", e.target.value)}
          />
        ) : (
          text
        ),
      onHeaderCell: () => ({
        style: {
          backgroundColor: "#DC2626",
          color: "#fff",
        },
      }),
    },
    {
      title: "Order",
      dataIndex: "order",
      key: "order",
      render: (text, record) =>
        editableRow === record.id ? (
          <Select
            value={text}
            onChange={(value) => handleChange(record.id, "order", value)}
          >
            {[...Array(4).keys()].map((num) => (
              <Option key={num + 1} value={num + 1}>
                {num + 1}
              </Option>
            ))}
          </Select>
        ) : (
          text
        ),
      onHeaderCell: () => ({
        style: {
          backgroundColor: "#DC2626",
          color: "#fff",
        },
      }),
    },
    {
      title: "Actions",
      key: "actions",
      render: (text, record) =>
        editableRow === record.id ? (
          <>
            <Button
              onClick={() =>
                handleSaveClick(record.id, {
                  name: record.name,
                  column: record.column,
                  url: record.url,
                  order: record.order,
                })
              }
              className="bg-blue-500 text-white px-4 py-2 rounded mr-2"
            >
              Save
            </Button>
            <Button
              onClick={handleCancelClick}
              className="bg-gray-500 text-white px-4 py-2 rounded"
            >
              Cancel
            </Button>
          </>
        ) : (
          <>
            <button
              onClick={() => handleEditClick(record.id)}
              className="hover:bg-[#DC2626] hover:text-white p-3 rounded-full mr-2 bg-transparent text-xl text-[#DC2626]"
            >
              <AiOutlineEdit />
            </button>
            <Popconfirm
              title="Are you sure to delete this item?"
              onConfirm={() => handleDeleteClick(record.id)}
            >
              <button className="hover:bg-[#DC2626] hover:text-white p-3 rounded-full mr-2 bg-transparent text-xl text-[#DC2626]">
                <AiOutlineDelete />
              </button>
            </Popconfirm>
          </>
        ),
      onHeaderCell: () => ({
        style: {
          backgroundColor: "#DC2626",
          color: "#fff",
        },
      }),
    },
  ]

  if (loading) {
    return (
      <div className="flex items-center justify-center space-x-2 w-full min-h-screen">
        <div className="w-4 h-4 rounded-full animate-pulse bg-red-600"></div>
        <div className="w-4 h-4 rounded-full animate-pulse bg-red-600"></div>
        <div className="w-4 h-4 rounded-full animate-pulse bg-red-600"></div>
      </div>
    )
  }

  return (
    <div className="p-4">
      <div className="mb-4 flex justify-end space-x-4">
        <Button
          onClick={() => setIsFooterModalVisible(true)}
          className="bg-blue-500 text-white px-4 py-2 rounded"
        >
          Add Footer Link
        </Button>
        <Button
          onClick={() => setIsSocialModalVisible(true)}
          className="bg-red-600 text-white px-4 py-2 rounded"
        >
          Add Social Link
        </Button>
      </div>

      <h2 className="text-lg font-semibold mb-4">Footer Links</h2>
      <Table
        dataSource={footerLinks.filter((link) => link.column !== 3)}
        columns={columns}
        rowKey="id"
        className="shadow-lg"
      />

      <h2 className="text-lg font-semibold mt-8 mb-4">Social Links</h2>
      <Table
        dataSource={footerLinks.filter((link) => link.column === 3)}
        columns={columns}
        rowKey="id"
        className="shadow-lg"
      />

      <Modal
        title="Add Footer Link"
        visible={isFooterModalVisible}
        onOk={handleFooterModalOk}
        onCancel={handleModalCancel}
      >
        <Input
          placeholder="Name"
          value={newLink.name}
          onChange={(e) => setNewLink({ ...newLink, name: e.target.value })}
          className="mb-2"
        />
        <Select
          value={newLink.column}
          onChange={(value) => setNewLink({ ...newLink, column: value })}
          className="w-full mb-2"
        >
          <Option value={1}>Column 1</Option>
          <Option value={2}>Column 2</Option>
        </Select>
        <Input
          placeholder="URL"
          value={newLink.url}
          onChange={(e) => setNewLink({ ...newLink, url: e.target.value })}
          className="mb-2"
        />
        <Select
          value={newLink.order}
          onChange={(value) => setNewLink({ ...newLink, order: value })}
          className="w-full"
        >
          {[...Array(4).keys()].map((num) => (
            <Option key={num + 1} value={num + 1}>
              {num + 1}
            </Option>
          ))}
        </Select>
      </Modal>

      <Modal
        title="Add Social Link"
        visible={isSocialModalVisible}
        onOk={handleSocialModalOk}
        onCancel={handleSocialModalCancel}
      >
        <Select
          value={newSocialLink.name}
          onChange={(value) =>
            setNewSocialLink({ ...newSocialLink, name: value })
          }
          className="w-full mb-2"
        >
          <Option value="Facebook">Facebook</Option>
          <Option value="Instagram">Instagram</Option>
          <Option value="Twitter">Twitter</Option>
          <Option value="YouTube">YouTube</Option>
        </Select>
        <Input
          placeholder="URL"
          value={newSocialLink.url}
          onChange={(e) =>
            setNewSocialLink({ ...newSocialLink, url: e.target.value })
          }
          className="mb-2"
        />
        <Select
          value={newSocialLink.order}
          onChange={(value) =>
            setNewSocialLink({ ...newSocialLink, order: value })
          }
          className="w-full"
        >
          {[...Array(4).keys()].map((num) => (
            <Option key={num + 1} value={num + 1}>
              {num + 1}
            </Option>
          ))}
        </Select>
      </Modal>
    </div>
  )
}

export default FooterLinks
