import React, { useState } from "react"
import { Form, Input, Button, message } from "antd"
import { useNavigate } from "react-router-dom"
import styled from "styled-components"

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(4, 1fr);
  height: 100vh;
`

const Container = styled.div`
  display: flex;
  justify-content: start;
  align-items: start;
  grid-column: 2 / 5; /* This spans the container across 3 columns */
  grid-row: 2 / 3;
`

const FormWrapper = styled.div`
  width: 100%;
  max-width: 400px;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
`

const Title = styled.h2`
  text-align: center;
  color: #dc2626;
`

const StyledForm = styled(Form)`
  .ant-form-item-label > label {
    color: #dc2626;
  }
`

const StyledInput = styled(Input)`
  border-color: #dc2626;

  &:focus {
    border-color: #dc2626;
    box-shadow: 0 0 0 2px rgba(220, 38, 38, 0.2);
  }
`

const StyledButton = styled(Button)`
  background-color: #dc2626;
  border-color: #dc2626;
  width: 100%;

  &:hover,
  &:focus {
    background-color: #b71c1c;
    border-color: #b71c1c;
  }

  &:disabled {
    background-color: #f5c6c6;
    border-color: #f5c6c6;
  }
`

const BackButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  grid-column: 1 / 2;
  grid-row: 1 / 2;
  padding: 20px;
`

const BackButton = styled(Button)`
  background-color: #dc2626;
  border-color: #dc2626;
  color: white;

  &:hover,
  &:focus {
    background-color: #b71c1c;
    border-color: #b71c1c;
  }
`

function ResetPassword() {
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()

  const handleSubmit = async (values) => {
    setLoading(true)
    try {
      const response = await fetch(
        "https://kalerpotro.pythonanywhere.com/api/auth_user/password/reset/",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email: values.email }),
        }
      )

      if (response.ok) {
        message.success(
          "If the email is registered, you will receive a password reset link."
        )
      } else {
        message.error("There was an error with your request. Please try again.")
      }
    } catch (error) {
      message.error("There was an error with your request. Please try again.")
    } finally {
      setLoading(false)
    }
  }

  return (
    <GridContainer>
      <BackButtonContainer>
        <BackButton onClick={() => navigate("/login")}>Back to Home</BackButton>
      </BackButtonContainer>
      <Container>
        <FormWrapper>
          <Title>Reset Password</Title>
          <StyledForm
            name="reset_password"
            onFinish={handleSubmit}
            layout="vertical"
          >
            <Form.Item
              label="Email"
              name="email"
              rules={[
                {
                  type: "email",
                  message: "The input is not valid E-mail!",
                },
                {
                  required: true,
                  message: "Please input your E-mail!",
                },
              ]}
            >
              <StyledInput />
            </Form.Item>

            <Form.Item>
              <StyledButton type="primary" htmlType="submit" loading={loading}>
                Reset Password
              </StyledButton>
            </Form.Item>
          </StyledForm>
        </FormWrapper>
      </Container>
    </GridContainer>
  )
}

export default ResetPassword
