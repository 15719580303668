import React, { useState, useEffect } from "react"
import styled from "styled-components"
import axios from "axios"

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  transition: opacity 0.3s ease-in-out;
`

const ModalContainer = styled.div`
  background: #f7f7f7;
  border-radius: 20px;
  width: 34rem;
  max-width: 90%;
  max-height: 90vh; /* Set a maximum height */
  padding: 40px;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1);
  animation: fadeIn 0.5s ease-in-out;
  transform: scale(1);
  transition: transform 0.3s ease-in-out;
  overflow-y: auto; /* Enable vertical scrolling */
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: scale(0.8);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }
`

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`

const ModalTitle = styled.h2`
  margin: 0;
  font-size: 1.5rem;
  font-weight: 700;
  color: #1f2937;
  letter-spacing: -0.02em;
`

const CloseButton = styled.button`
  background: none;
  border: none;
  font-size: 2.4rem;
  cursor: pointer;
  color: #dc2626;
  font-weight: bold;
  &:hover {
    color: #1f2937;
  }
  transition: color 0.2s ease-in-out;
`

const UserProfile = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`

const ProfileField = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;
  border-bottom: 1px solid #e5e7eb;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
`

const ProfileLabel = styled.span`
  font-weight: 500;
  color: #1f2937;
`

const ProfileData = styled.span`
  color: #6b7280;
`

const ProfilePicture = styled.img`
  width: 160px;
  height: 160px;
  border-radius: 50%;
  margin-bottom: 20px;
  object-fit: cover;
  align-self: center;
  border: 8px solid #fff;
  box-sizing: content-box;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1);
`

const LoadingMessage = styled.p`
  text-align: center;
  color: #6b7280;
`

const ViewProfileModal = ({ isOpen, onRequestClose }) => {
  const [user, setUser] = useState(null)

  useEffect(() => {
    if (isOpen) {
      const fetchUserData = async () => {
        const token = localStorage.getItem("accessToken")
        if (!token) return

        try {
          const response = await axios.get(
            "https://kalerpotro.pythonanywhere.com/api/auth_user/me/",
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          setUser(response.data)
        } catch (error) {
          console.error("Failed to fetch user data", error)
        }
      }

      fetchUserData()
    }
  }, [isOpen])

  if (!isOpen) return null

  return (
    <ModalOverlay>
      <ModalContainer>
        <ModalHeader>
          <ModalTitle>User</ModalTitle>
          <CloseButton onClick={onRequestClose}>&times;</CloseButton>
        </ModalHeader>
        {user ? (
          <UserProfile>
            {user.profile_picture && (
              <ProfilePicture
                src={user.profile_picture}
                alt={`${user.username}'s profile`}
              />
            )}
            <ProfileField>
              <ProfileLabel>Username:</ProfileLabel>
              <ProfileData>{user.username}</ProfileData>
            </ProfileField>
            <ProfileField>
              <ProfileLabel>Full Name:</ProfileLabel>
              <ProfileData>{`${user.first_name} ${user.last_name}`}</ProfileData>
            </ProfileField>
            <ProfileField>
              <ProfileLabel>Email:</ProfileLabel>
              <ProfileData>{user.email}</ProfileData>
            </ProfileField>
            <ProfileField>
              <ProfileLabel>Phone:</ProfileLabel>
              <ProfileData>{user.phone || "N/A"}</ProfileData>
            </ProfileField>
            <ProfileField>
              <ProfileLabel>Bio:</ProfileLabel>
              <ProfileData>{user.bio || "N/A"}</ProfileData>
            </ProfileField>
          </UserProfile>
        ) : (
          <LoadingMessage>Loading...</LoadingMessage>
        )}
      </ModalContainer>
    </ModalOverlay>
  )
}

export default ViewProfileModal
