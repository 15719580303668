import React, { useEffect, useState } from "react"
import { AiOutlineDelete, AiOutlineEdit, AiOutlineEye } from "react-icons/ai"
import { BsCheck, BsX } from "react-icons/bs"
import ReactPaginate from "react-paginate"
import { useSelector } from "react-redux"
import AddNewsPostModal from "./Modals/AddNewsPostModal"
import DeleteNewsPostModal from "./Modals/DeleteNewsPostModal"
import EditNewsPostModal from "./Modals/EditNewsPostModal"
import FilterNewsPostModal from "./Modals/FilterNewsPostModal"
import ViewNewsPostModal from "./Modals/ViewNewsPostModal"
import {
  ActionButton,
  ActionContainer,
  Container,
  Header,
  HeadlineCell,
  IconWrapper,
  PaginationContainer,
  Table,
  Tooltip,
} from "./NewsPostUpdated.styled"

const ITEMS_PER_PAGE = 8
const BASE_URL = "https://kalerpotro.pythonanywhere.com"

const NewsPostUpdated = () => {
  const [newsPosts, setNewsPosts] = useState([])
  const [filteredNewsPosts, setFilteredNewsPosts] = useState([])
  const [currentPage, setCurrentPage] = useState(0)
  const [isViewModalOpen, setIsViewModalOpen] = useState(false)
  const [isEditModalOpen, setIsEditModalOpen] = useState(false)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const [selectedPost, setSelectedPost] = useState(null)
  const [isTooltipVisible, setIsTooltipVisible] = useState(false)
  const [tooltipText, setTooltipText] = useState("")
  const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 })
  const [sortConfig, setSortConfig] = useState({
    key: "created_at",
    direction: "desc",
  })
  const [isAddModalOpen, setIsAddModalOpen] = useState(false)
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false)
  const [filters, setFilters] = useState({})
  const [searchQuery, setSearchQuery] = useState("")

  const user = useSelector((state) => state.auth.user)

  useEffect(() => {
    const fetchNewsPosts = async () => {
      try {
        const response = await fetch(`${BASE_URL}/api/news/list/`)
        if (!response.ok) {
          throw new Error("Network response was not ok")
        }
        const data = await response.json()
        if (Array.isArray(data)) {
          setNewsPosts(data)
        } else {
          console.error("Data fetched is not an array:", data)
          setNewsPosts([])
        }
      } catch (error) {
        console.error("Fetch error:", error)
      }
    }

    fetchNewsPosts()
  }, [])

  useEffect(() => {
    if (user && newsPosts.length > 0) {
      if (localStorage.getItem("role") === "EDITOR" && user.user_id) {
        setFilteredNewsPosts(
          newsPosts.filter((post) => post.editor === user.user_id)
        )
      } else {
        setFilteredNewsPosts(newsPosts)
      }
    }
  }, [user, newsPosts])

  const handleEdit = (post) => {
    setSelectedPost(post)
    setIsEditModalOpen(true)
  }

  const handleViewPost = (post) => {
    setSelectedPost(post)
    setIsViewModalOpen(true)
  }

  const closeViewModal = () => {
    setIsViewModalOpen(false)
    setSelectedPost(null)
  }

  const closeEditModal = () => {
    setIsEditModalOpen(false)
    setSelectedPost(null)
  }

  const closeDeleteModal = () => {
    setIsDeleteModalOpen(false)
    setSelectedPost(null)
  }

  const handleEditChange = (e) => {
    const { name, value } = e.target
    setSelectedPost((prev) => ({
      ...prev,
      [name]: value,
    }))
  }

  const handleSave = async (e) => {
    e.preventDefault()
    console.log(JSON.stringify(selectedPost))
    try {
      const token = localStorage.getItem("accessToken")
      const response = await fetch(`${BASE_URL}/api/news/${selectedPost.id}/`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(selectedPost),
      })
      if (!response.ok) {
        throw new Error("Network response was not ok")
      }
      const updatedPost = await response.json()

      setNewsPosts((prev) =>
        prev.map((post) => (post.id === updatedPost.id ? updatedPost : post))
      )
      closeEditModal()
      return updatedPost
    } catch (error) {
      console.error("Update error:", error)
      throw error
    }
  }

  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected)
  }

  const handleLinkHover = (event, link) => {
    const { top, left, height } = event.target.getBoundingClientRect()
    setTooltipPosition({
      top: top + height + window.scrollY,
      left: left + window.scrollX,
    })
    setTooltipText(link)
    setIsTooltipVisible(true)
  }

  const handleLinkMouseLeave = () => {
    setIsTooltipVisible(false)
  }

  const handleDeletePost = async () => {
    try {
      const response = await fetch(`${BASE_URL}/api/news/${selectedPost.id}/`, {
        method: "DELETE",
      })
      if (!response.ok) {
        throw new Error("Network response was not ok")
      }
      setNewsPosts(newsPosts.filter((post) => post.id !== selectedPost.id))
      closeDeleteModal()
    } catch (error) {
      console.error("Delete error:", error)
    }
  }

  const openDeleteModal = (post) => {
    setSelectedPost(post)
    setIsDeleteModalOpen(true)
  }

  const handleAddNewsPost = (newPost) => {
    setNewsPosts(newPost)
  }

  const sortedNewsPosts = React.useMemo(() => {
    let sortableNewsPosts = [...filteredNewsPosts]
    if (sortConfig !== null) {
      sortableNewsPosts.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === "asc" ? -1 : 1
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === "asc" ? 1 : -1
        }
        return 0
      })
    }
    return sortableNewsPosts
  }, [filteredNewsPosts, sortConfig])

  const requestSort = (key) => {
    let direction = "asc"
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === "asc"
    ) {
      direction = "desc"
    }
    setSortConfig({ key, direction })
  }

  const offset = currentPage * ITEMS_PER_PAGE
  const currentNewsPosts = sortedNewsPosts.slice(
    offset,
    offset + ITEMS_PER_PAGE
  )
  const pageCount = Math.ceil(filteredNewsPosts.length / ITEMS_PER_PAGE)

  const renderCellValue = (value) => {
    return value === "-1" ? "N/A" : value
  }

  const handleFilter = (filterCriteria) => {
    setFilters(filterCriteria)
  }

  useEffect(() => {
    let filtered = newsPosts

    if (filters.headline) {
      filtered = filtered.filter((post) =>
        post.headline.toLowerCase().includes(filters.headline.toLowerCase())
      )
    }
    if (filters.category_name) {
      filtered = filtered.filter((post) =>
        post.category_name
          .toLowerCase()
          .includes(filters.category_name.toLowerCase())
      )
    }
    if (filters.custom_location) {
      filtered = filtered.filter((post) =>
        post.custom_location
          .toLowerCase()
          .includes(filters.custom_location.toLowerCase())
      )
    }
    if (filters.division) {
      filtered = filtered.filter((post) =>
        post.division.toLowerCase().includes(filters.division.toLowerCase())
      )
    }
    if (filters.district) {
      filtered = filtered.filter((post) =>
        post.district.toLowerCase().includes(filters.district.toLowerCase())
      )
    }
    if (filters.upazila) {
      filtered = filtered.filter((post) =>
        post.upazila.toLowerCase().includes(filters.upazila.toLowerCase())
      )
    }
    if (filters.country) {
      filtered = filtered.filter((post) =>
        post.country.toLowerCase().includes(filters.country.toLowerCase())
      )
    }
    if (filters.editor_name) {
      filtered = filtered.filter((post) =>
        post.editor_name
          .toLowerCase()
          .includes(filters.editor_name.toLowerCase())
      )
    }
    if (filters.date_range) {
      const [startDate, endDate] = filters.date_range
      filtered = filtered.filter((post) => {
        const postDate = new Date(post.created_at)
        return postDate >= startDate && postDate <= endDate
      })
    }
    if (filters.is_active) {
      if (filters.is_active === "active") {
        filtered = filtered.filter((post) => post.is_active === true)
      } else if (filters.is_active === "pending") {
        filtered = filtered.filter((post) => post.is_active === false)
      }
    }

    setFilteredNewsPosts(filtered)
  }, [filters, newsPosts])

  useEffect(() => {
    if (searchQuery) {
      setFilteredNewsPosts(
        newsPosts.filter(
          (post) =>
            post.headline.toLowerCase().includes(searchQuery.toLowerCase()) ||
            post.content.toLowerCase().includes(searchQuery.toLowerCase()) ||
            post.category_name
              .toLowerCase()
              .includes(searchQuery.toLowerCase()) ||
            post.editor_name
              .toLowerCase()
              .includes(searchQuery.toLowerCase()) ||
            post.division.toLowerCase().includes(searchQuery.toLowerCase()) ||
            post.district.toLowerCase().includes(searchQuery.toLowerCase()) ||
            post.upazila.toLowerCase().includes(searchQuery.toLowerCase()) ||
            post.country.toLowerCase().includes(searchQuery.toLowerCase()) ||
            post.location_type
              .toLowerCase()
              .includes(searchQuery.toLowerCase()) ||
            post.photo_editor.toLowerCase().includes(searchQuery.toLowerCase())
        )
      )
    } else {
      setFilteredNewsPosts(newsPosts)
    }
  }, [searchQuery, newsPosts])

  return (
    <Container>
      <Header>
        <input
          type="text"
          placeholder="Search by headline, content, category, editor, etc. eg. Type International to view International news"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          style={{
            border: "2px solid #bbb",
            borderRadius: "5px",
            padding: "10px",
            width: "63%",
            justifySelf: "flex-end",
            marginRight: "auto",
          }}
        />
        <ActionButton onClick={() => setIsAddModalOpen(true)}>
          Add News Post
        </ActionButton>
        <ActionButton onClick={() => setIsFilterModalOpen(true)}>
          Filter News Posts
        </ActionButton>
      </Header>
      <Table>
        <thead>
          <tr>
            <th onClick={() => requestSort("headline")}>
              {sortConfig.key === "headline"
                ? sortConfig.direction === "asc"
                  ? "↑"
                  : "↓"
                : ""}
              Headline{" "}
            </th>
            <th onClick={() => requestSort("category_name")}>
              {sortConfig.key === "category_name"
                ? sortConfig.direction === "asc"
                  ? "↑"
                  : "↓"
                : ""}
              Category{" "}
            </th>
            <th onClick={() => requestSort("country")}>
              {sortConfig.key === "country"
                ? sortConfig.direction === "asc"
                  ? "↑"
                  : "↓"
                : ""}
              Country
            </th>
            <th onClick={() => requestSort("division")}>
              {sortConfig.key === "division"
                ? sortConfig.direction === "asc"
                  ? "↑"
                  : "↓"
                : ""}
              Division{" "}
            </th>
            <th onClick={() => requestSort("district")}>
              {sortConfig.key === "district"
                ? sortConfig.direction === "asc"
                  ? "↑"
                  : "↓"
                : ""}
              District{" "}
            </th>
            <th onClick={() => requestSort("upazila")}>
              {sortConfig.key === "upazila"
                ? sortConfig.direction === "asc"
                  ? "↑"
                  : "↓"
                : ""}
              Upazila{" "}
            </th>
            <th onClick={() => requestSort("share_count")}>
              {sortConfig.key === "share_count"
                ? sortConfig.direction === "asc"
                  ? "↑"
                  : "↓"
                : ""}
              Shares{" "}
            </th>
            <th onClick={() => requestSort("editor_name")}>
              {sortConfig.key === "editor_name"
                ? sortConfig.direction === "asc"
                  ? "↑"
                  : "↓"
                : ""}
              Editor{" "}
            </th>
            <th onClick={() => requestSort("image")}>
              {sortConfig.key === "image"
                ? sortConfig.direction === "asc"
                  ? "↑"
                  : "↓"
                : ""}
              Image{" "}
            </th>
            <th onClick={() => requestSort("video")}>
              {sortConfig.key === "video"
                ? sortConfig.direction === "asc"
                  ? "↑"
                  : "↓"
                : ""}
              Video{" "}
            </th>
            <th onClick={() => requestSort("created_at")}>
              {sortConfig.key === "created_at"
                ? sortConfig.direction === "asc"
                  ? "↑"
                  : "↓"
                : ""}
              Date{" "}
            </th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {currentNewsPosts.map((post) => (
            <tr key={post.id}>
              <HeadlineCell>{post.headline}</HeadlineCell>
              <td>{post.category_name}</td>
              <td>{renderCellValue(post.country ? post.country : 'N/A' )}</td>
              <td>{renderCellValue(post.division)}</td>
              <td>{renderCellValue(post.district)}</td>
              <td>{renderCellValue(post.upazila)}</td>
              <td>{post.share_count}</td>
              <td>{post.editor_name}</td>
              <td>
                {post.image ? (
                  <BsCheck fontSize={30} style={{ color: "purple" }} />
                ) : (
                  <BsX fontSize={30} style={{ color: "red" }} />
                )}
              </td>
              <td>
                {post.video ? (
                  <BsCheck fontSize={30} style={{ color: "purple" }} />
                ) : (
                  <BsX fontSize={30} style={{ color: "red" }} />
                )}
              </td>
              <td>{new Date(post.created_at).toLocaleDateString()}</td>
              <td>
                <ActionContainer>
                  <IconWrapper onClick={() => handleViewPost(post)}>
                    <AiOutlineEye />
                  </IconWrapper>
                  <IconWrapper onClick={() => handleEdit(post)}>
                    <AiOutlineEdit />
                  </IconWrapper>
                  <IconWrapper onClick={() => openDeleteModal(post)}>
                    <AiOutlineDelete />
                  </IconWrapper>
                </ActionContainer>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      {isTooltipVisible && (
        <Tooltip
          style={{ top: tooltipPosition.top, left: tooltipPosition.left }}
        >
          {tooltipText}
        </Tooltip>
      )}
      <PaginationContainer>
        <ReactPaginate
          previousLabel={"Previous"}
          nextLabel={"Next"}
          pageCount={pageCount}
          onPageChange={handlePageClick}
          containerClassName={"pagination"}
          activeClassName={"active"}
        />
      </PaginationContainer>

      {isAddModalOpen && (
        <AddNewsPostModal
          isOpen={isAddModalOpen}
          onRequestClose={setIsAddModalOpen}
          setNewsPosts={setNewsPosts}
        />
      )}
      {isViewModalOpen && (
        <ViewNewsPostModal
          isOpen={isViewModalOpen}
          onRequestClose={closeViewModal}
          newsPost={selectedPost}
        />
      )}
      {isEditModalOpen && (
        <EditNewsPostModal
          isOpen={isEditModalOpen}
          onRequestClose={closeEditModal}
          selectedPost={selectedPost}
          handleEditChange={handleEditChange}
          handleSave={handleSave}
          setNewsPosts={setNewsPosts}
        />
      )}
      {isDeleteModalOpen && (
        <DeleteNewsPostModal
          isOpen={isDeleteModalOpen}
          onClose={closeDeleteModal}
          onDelete={handleDeletePost}
        />
      )}
      {isFilterModalOpen && (
        <FilterNewsPostModal
          isOpen={isFilterModalOpen}
          onRequestClose={() => setIsFilterModalOpen(false)}
          handleFilter={handleFilter}
        />
      )}
    </Container>
  )
}

export default NewsPostUpdated
// original
