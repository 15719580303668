import { Select, Table } from "antd"
import "antd/dist/reset.css"
import axios from "axios"
import React, { useEffect, useState } from "react"
import ReactPaginate from "react-paginate"
import { PaginationContainer } from "../NewsPostUpdated/NewsPostUpdated.styled"

const { Option } = Select

function NewsLetter() {
  const [newsletter, setNewsletter] = useState([])
  const [loading, setLoading] = useState(true)
  const [currentPage, setCurrentPage] = useState(1);
  const [categoriesPerPage] = useState(5);

  useEffect(() => {
    const getNewsletter = async () => {
      setLoading(true)
      try {
        const { data } = await axios.get(
          `https://kalerpotro.pythonanywhere.com/api/webinfo/newsletter/list/`
        )
        setNewsletter(data)
      } catch (error) {
        console.error(error.message)
      } finally {
        setLoading(false)
      }
    }
    getNewsletter()
  }, [])

  // const showModal = () => {
  //   setIsModalVisible(true);
  // };

  // const handleCancel = () => {
  //   setIsModalVisible(false);
  // };

  // const handleSendEmail = async (values) => {
  //   const { recipients, subject, body } = values;

  //   const templateParams = {
  //     to_email: recipients.join(', '),
  //     subject,
  //     message: body,
  //   };

  //   emailjs
  //     .send(serviceID, templateID, templateParams, { publicKey })
  //     .then(
  //       (response) => {
  //         console.log('SUCCESS!', response.status, response.text);
  //         message.success('Emails sent successfully!');
  //         setIsModalVisible(false);
  //       },
  //       (err) => {
  //         console.log('FAILED...', err);
  //         message.error('Failed to send email!');
  //       }
  //     );
  // };

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      className: "text-left",
      onHeaderCell: () => ({
        style: {
          backgroundColor: "#DC2626",
          color: "#fff",
        },
      }),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      className: "text-left",
      onHeaderCell: () => ({
        style: {
          backgroundColor: "#DC2626",
          color: "#fff",
        },
      }),
    },
    {
      title: "Created At",
      dataIndex: "created_at",
      key: "created_at",
      className: "text-left",
      render: (text) => new Date(text).toLocaleDateString(),
      onHeaderCell: () => ({
        style: {
          backgroundColor: "#DC2626",
          color: "#fff",
        },
      }),
    },
    {
      title: "Updated At",
      dataIndex: "updated_at",
      key: "updated_at",
      className: "text-left",
      render: (text) => new Date(text).toLocaleDateString(),
      onHeaderCell: () => ({
        style: {
          backgroundColor: "#DC2626",
          color: "#fff",
        },
      }),
    },
  ]

  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected + 1); 
  };

  const indexOfLastCategory = currentPage * categoriesPerPage;
  const indexOfFirstCategory = indexOfLastCategory - categoriesPerPage;
  const currentCategories = newsletter.slice(indexOfFirstCategory, indexOfLastCategory);

  return (
    <div className="p-4">
      <div className="flex justify-between mb-4">
        <h1 className="text-2xl font-semibold">Newsletter Subscribers</h1>
        {/* <Button type="primary" onClick={showModal}>
          Send Email
        </Button> */}
      </div>

      <Table
        columns={columns}
        dataSource={currentCategories}
        rowKey="id"
        className="rounded-lg shadow-md"
        loading={loading}
        pagination={false}
      />
 <PaginationContainer>
        <ReactPaginate
          previousLabel={"Previous"}
          nextLabel={"Next"}
          pageCount={Math.ceil(newsletter.length / categoriesPerPage)}
          onPageChange={handlePageChange}
          containerClassName={"pagination"}
          activeClassName={"active"}
          previousClassName={"previous"}
          nextClassName={"next"}
        />
      </PaginationContainer>
      {/* <Modal
        title="Send Email"
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
      >
        <Form layout="vertical" onFinish={handleSendEmail}>
          <Form.Item
            label="Recipients"
            name="recipients"
            rules={[{ required: true, message: 'Please select at least one recipient!' }]}
          >
            <Select
              mode="multiple"
              placeholder="Select recipients"
              optionFilterProp="children"
              showSearch
              filterOption={(input, option) =>
                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {newsletter.map((subscriber) => (
                <Option key={subscriber.id} value={subscriber.email}>
                  {subscriber.email}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="Subject"
            name="subject"
            rules={[{ required: true, message: 'Please enter the subject!' }]}
          >
            <Input placeholder="Enter subject" />
          </Form.Item>
          <Form.Item
            label="Body"
            name="body"
            rules={[{ required: true, message: 'Please enter the email body!' }]}
          >
            <Input.TextArea rows={4} placeholder="Enter email body" />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Send Email
            </Button>
          </Form.Item>
        </Form>
      </Modal> */}
    </div>
  )
}

export default NewsLetter
